import Icon from '@ant-design/icons'
import { Button, Flex, Input, Upload } from 'antd'
import cx from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { spacingSM } from '@signifyd/ant'
import { colorCerulean } from '@signifyd/colors'
import {
  Attach,
  AttachFileButton,
  AttachFilesForUploadHook,
  Expand,
  MaxInputDisplay,
  Send,
  Space,
  TextThirdGen,
} from '@signifyd/components'
import { ALLOWED_UPLOAD_FILE_TYPES_NOTE_ATTACHMENT } from 'core/constants'
import styles from './NoteInput.less'

interface Props {
  value: string
  isSubmitDisabled: boolean
  fileAttacher: AttachFilesForUploadHook
  maxNoteLength: number
  onChange: (value: string) => void
  onSubmitNote: () => void
  onShowModal: () => void
  className?: string | false
}

const { TextArea } = Input

export const NoteInput: FC<Props> = ({
  value,
  isSubmitDisabled,
  className,
  onChange,
  onSubmitNote,
  onShowModal,
  fileAttacher,
  maxNoteLength,
}) => {
  const { t } = useTranslation()

  const { onAttachFiles, onRemoveFile, files } = fileAttacher

  const noteTooLong = value.length > maxNoteLength

  return (
    <div
      className={cx([
        className,
        styles.noteCard,
        noteTooLong && styles.exceeded,
      ])}
      data-test-id="noteInput"
    >
      <TextArea
        className={styles.noteInputArea}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={t('caseActivity.addNotesPlaceholder')}
        rows={3}
        style={{ resize: 'none' }}
        data-test-id="noteInputArea"
      />
      <MaxInputDisplay length={value.length} maxInputLength={maxNoteLength} />

      <Space size="xs" />
      <div>
        <Upload
          onRemove={onRemoveFile}
          showUploadList={{
            showPreviewIcon: false,
            showDownloadIcon: false,
          }}
          fileList={files}
        />
      </div>

      <Flex justify="space-between" align="center">
        <TextThirdGen size="sm">
          <Button
            type="link"
            icon={<Expand fill={colorCerulean} />}
            onClick={onShowModal}
            data-test-id="showModal"
          />
        </TextThirdGen>
        <Flex gap={spacingSM}>
          <TextThirdGen size="sm">
            <AttachFileButton
              onAttach={onAttachFiles}
              type="link"
              accept={ALLOWED_UPLOAD_FILE_TYPES_NOTE_ATTACHMENT}
              dataAnalyticsId="note-input-file-button"
              dataTestId="noteInputFileButton"
              icon={<Icon component={Attach} />}
            />
          </TextThirdGen>

          <Button
            disabled={isSubmitDisabled}
            onClick={onSubmitNote}
            type="primary"
            data-test-id="submitNoteButton"
            icon={<Icon component={Send} />}
          />
        </Flex>
      </Flex>
    </div>
  )
}

export default NoteInput
