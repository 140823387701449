import { Form } from '@ant-design/compatible'
import { Select } from 'antd'
import { FC, RefObject } from 'react'
import { useTranslation } from 'react-i18next'
import {
  SHIPPING_METHODS,
  SEARCH_FIELD,
  FULFILLMENT_METHOD,
} from '@signifyd/http'
import { FilterState } from 'store/search'

interface Props {
  value: Array<SHIPPING_METHODS | FULFILLMENT_METHOD>
  updateFilterFn: (update: Partial<FilterState>) => void
  scrollRef?: RefObject<HTMLDivElement>
}

const dedupedMethods = new Set([
  ...Object.values(SHIPPING_METHODS),
  ...Object.values(FULFILLMENT_METHOD),
])

const shippingMethodOptionValues = [...dedupedMethods].sort()

const ShippingMethodFilter: FC<Props> = ({
  value,
  updateFilterFn,
  scrollRef,
}) => {
  const { t } = useTranslation()

  const handleChange = (
    value: Array<SHIPPING_METHODS | FULFILLMENT_METHOD>
  ): void => {
    updateFilterFn({
      [SEARCH_FIELD.shippingMethod]: value,
    })
  }

  return (
    <Form.Item label={t('filters.shippingMethod.label')}>
      <Select
        mode="multiple"
        allowClear
        placeholder={t('filters.placeholder.selectOneOrMore')}
        value={value}
        onChange={handleChange}
        data-analytics-id="filter-shipping-method-select"
        data-test-id="filterShippingMethodSelect"
        getPopupContainer={(triggerNode: HTMLElement) => {
          return scrollRef?.current ?? (triggerNode.parentNode as HTMLElement)
        }}
        options={shippingMethodOptionValues.map((option) => ({
          key: option,
          label: t(`filters.shippingMethod.${option}`),
          value: option,
          'data-analytics-id': `filter-shipping-method-selected-${option}`,
          'data-test-id': `filterShippingMethodSelected-${option}`,
        }))}
      />
    </Form.Item>
  )
}

export default ShippingMethodFilter
