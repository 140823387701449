import { Descriptions, Tooltip } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { formatCurrencyNumber } from '@signifyd/utils'
import CopyWrapper from 'core/components/CopyWrapper'
import EmptyWarning from 'core/components/EmptyWarning'
import FormattedDate from 'core/components/FormattedDate'
import SeederLabel from 'pages/CaseReviewPage/components/SeederLabel'
import { RuntimeSeller } from 'pages/CaseReviewPage/containers/OrderDetails/components/SellerDetailsThirdGen/SellerDetails.types'
import { DescriptionsItemType } from 'antd/es/descriptions'
import styles from './SellerRecord.less'

interface Props {
  seller: RuntimeSeller
  currency: string
}

const SellerRecord: FC<Props> = ({ seller, currency }) => {
  const { t } = useTranslation()
  const { tags, createdDate, aggregateOrderCount, aggregateOrderAmount } =
    seller

  const noInfo =
    !tags?.length &&
    !createdDate &&
    !aggregateOrderCount &&
    !aggregateOrderAmount

  const tagsList = tags?.join(', ')
  const tagsTooltip = (
    <Text size="md" className={styles.tooltipText} data-test-id="tagsTooltip">
      {tagsList}
    </Text>
  )

  const items = [
    !!tags?.length && {
      label: (
        <SeederLabel label={t('pages.caseReview.sellerDetails.sellerTag')} />
      ),
      children: (
        <Tooltip title={tagsTooltip} mouseEnterDelay={0.5}>
          <div data-test-id="tagsList">
            <CopyWrapper text={tagsList}>{tagsList}</CopyWrapper>
          </div>
        </Tooltip>
      ),
    },

    createdDate && {
      label: (
        <SeederLabel
          label={t('pages.caseReview.sellerDetails.enrollmentDate')}
        />
      ),
      children: (
        <CopyWrapper text={createdDate}>
          <FormattedDate date={createdDate} />
        </CopyWrapper>
      ),
    },

    aggregateOrderCount && {
      label: (
        <SeederLabel
          label={t('pages.caseReview.sellerDetails.aggregateOrderCount')}
        />
      ),
      children: (
        <CopyWrapper text={aggregateOrderCount.toString()}>
          {aggregateOrderCount}
        </CopyWrapper>
      ),
    },

    aggregateOrderAmount && {
      label: (
        <SeederLabel
          label={t('pages.caseReview.sellerDetails.aggregateOrderAmount')}
        />
      ),
      children: (
        <CopyWrapper text={aggregateOrderAmount}>
          {`${currency} ${formatCurrencyNumber(aggregateOrderAmount)}`}
        </CopyWrapper>
      ),
    },

    noInfo && {
      key: 'noSellerRecordInfo',
      label: '',
      labelStyle: { display: 'none' },
      children: (
        <EmptyWarning text={t('pages.caseReview.sellerDetails.sellerRecord')} />
      ),
    },
  ].filter((item) => !!item) as Array<DescriptionsItemType>

  return (
    <Descriptions
      title={
        <span id="sellerRecord">
          {t('pages.caseReview.sellerDetails.sellerRecord')}
        </span>
      }
      items={items}
      colon={false}
      size="small"
      bordered
      column={1}
      className={styles.container}
    />
  )
}

export default SellerRecord
