import { Card, Table } from 'antd'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { T4, TitleThirdGen, useIsThirdGen } from '@signifyd/components'
import { InvestigationInfo } from '@signifyd/http'
import { getEventDetailsColumnConfig } from 'pages/CaseReviewPage/containers/OrderDetails/components/EventDetails/EventDetailsTable.config'
import styles from './EventDetails.less'

interface Props {
  caseDetails: InvestigationInfo
}

export const EventDetails: FC<Props> = ({ caseDetails }) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()

  const { products, currency } = caseDetails

  const columnConfig = useMemo(() => {
    return getEventDetailsColumnConfig(currency)
  }, [currency])

  return (
    <Card
      title={
        isThirdGen ? (
          <TitleThirdGen level={2}>
            {t('eventTicketing.tableHeader.eventDetails')}
          </TitleThirdGen>
        ) : (
          <T4 className={styles.title}>
            {t('eventTicketing.tableHeader.eventDetails')}
          </T4>
        )
      }
      data-test-id="eventDetails"
      data-analytics-id="event-details"
      size={isThirdGen ? 'default' : 'small'}
    >
      <Table
        dataSource={products}
        rowKey="itemId"
        columns={columnConfig}
        scroll={{ x: true }}
        pagination={false}
        size={isThirdGen ? 'small' : 'middle'}
        bordered={isThirdGen}
      />
    </Card>
  )
}

export default EventDetails
