import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { notNil } from '@signifyd/utils'

interface Props {
  isReceivingMail?: boolean | null
  deliveryPoint?: string | null
}

const AddressInsight: FC<Props> = ({ isReceivingMail, deliveryPoint }) => {
  const { t } = useTranslation()

  return (
    <>
      {notNil(isReceivingMail) && (
        <>
          <br />
          <Text>
            {t('pages.caseReview.details.addressInsight.receivingMail', {
              yesNo: t(
                `pages.caseReview.details.addressInsight.${isReceivingMail}`
              ),
            })}
          </Text>
        </>
      )}
      {notNil(deliveryPoint) && (
        <>
          <br />
          <Text>
            {t('pages.caseReview.details.addressInsight.deliveryPoint', {
              type: deliveryPoint,
            })}
          </Text>
        </>
      )}
    </>
  )
}

export default AddressInsight
