import resources from 'locales'
import { i18nInstance } from '@signifyd/components'

const translations = resources['en-US'].translation.ekataIntelligence

type LabelKey = keyof (typeof translations)['labels']

export const getBooleanTranslation = (word: string): string => {
  if (Object.keys(translations.data.booleanConversion).includes(word)) {
    return i18nInstance.t(
      `ekataIntelligence.data.booleanConversion.${
        word as keyof typeof translations.data.booleanConversion
      }`
    )
  }

  return word
}

export const getPhoneIntelligenceLabel = (tKey: LabelKey): string =>
  i18nInstance.t(`ekataIntelligence.labels.${tKey}`)
