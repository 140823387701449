import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import { GetRulesResponse, listRules } from '@signifyd/http'

export const useGetPolicyDetails = (
  ruleId: number
): UseQueryResult<GetRulesResponse['data']> => {
  const { t } = useTranslation()

  return useQuery(
    ['policyDetails', ruleId],
    async () => {
      const res = await listRules({
        ruleIds: ruleId.toString(),
        includeDeleted: true,
      })

      return res.data.data
    },
    {
      enabled: !!ruleId,
      onError: (error: Error) => {
        console.error(error)

        message.error({
          content: t('store.policy.getPolicyDetailsFailure'),
          key: ruleId,
        })
      },
    }
  )
}

export const useGetPolicies = (
  teamIds: Array<number>
): UseQueryResult<GetRulesResponse['data']> => {
  return useQuery(
    ['policies', teamIds],
    async () => {
      const res = await listRules({
        teamIds: teamIds.join(','),
        includeDeleted: true,
      })

      return res.data.data
    },
    { enabled: !!teamIds.length }
  )
}
