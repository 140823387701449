import { TreeSelect } from 'antd'
import { FC, memo, useMemo, RefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'store'
import {
  CHECKOUT_CHECKPOINT_ACTION_VALUES,
  RETURN_CHECKPOINT_ACTION_VALUES,
  SEARCH_FIELD,
} from '@signifyd/http'
import { useGetPolicies } from 'core/hooks/usePolicy'
import { FilterState } from 'store/search'
import { filterConfig } from './AdvancedFiltersTree.config'
import {
  AdvancedFilterKey,
  AdvancedFilterConfig,
} from './AdvancedFiltersTree.types'
import {
  buildTreeData,
  encodeFilterState,
  decodeFilterState,
} from './AdvancedFiltersTree.utils'

interface Props {
  filters: FilterState
  updateFilterFn: (update: AdvancedFilterConfig) => void
  scrollRef?: RefObject<HTMLDivElement>
}

const defaultFilters = Object.keys({
  [SEARCH_FIELD.guaranteeRecommendedAction]: [],
  [SEARCH_FIELD.returnDecisionCheckpointAction]: [],
  ...filterConfig,
}).reduce((acc, key) => {
  acc[key as AdvancedFilterKey] = []

  return acc
}, {} as AdvancedFilterConfig)

const policyFilterConfig = {
  [SEARCH_FIELD.guaranteeRecommendedAction]: CHECKOUT_CHECKPOINT_ACTION_VALUES,
  [SEARCH_FIELD.returnDecisionCheckpointAction]:
    RETURN_CHECKPOINT_ACTION_VALUES,
  ...filterConfig,
}

const AdvancedFiltersTree: FC<Props> = ({
  filters,
  updateFilterFn,
  scrollRef,
}) => {
  const { t } = useTranslation()

  const teamIds = useStoreState((state) => {
    return state.user.userTeams.map(({ teamId }) => teamId)
  })

  const { data } = useGetPolicies(teamIds)

  const hasPolicies = !!data?.length

  const treeData = useMemo(() => {
    const allFiltersConfig = hasPolicies ? policyFilterConfig : filterConfig

    return buildTreeData(allFiltersConfig)
  }, [hasPolicies])

  const handleTreeChange = (values: Array<string>): void => {
    updateFilterFn({ ...defaultFilters, ...decodeFilterState(values) })
  }

  return (
    <TreeSelect
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder={t('filters.placeholder.selectOneOrMore')}
      allowClear
      multiple
      treeDefaultExpandAll
      treeData={treeData}
      value={encodeFilterState(treeData, filters)}
      onChange={handleTreeChange}
      treeCheckable
      data-analytics-id="filter-advanced"
      treeNodeLabelProp="tagLabel"
      getPopupContainer={(triggerNode: HTMLElement) => {
        return scrollRef?.current ?? (triggerNode.parentNode as HTMLElement)
      }}
    />
  )
}

export default memo(AdvancedFiltersTree)
