import { Row } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'store'
import { InvestigationInfo } from '@signifyd/http'
import { isTransportAirline } from 'core/utils/isTransportAirline/isTransportAirline'
import SummaryPanel from '../SummaryPanel'

interface Props {
  isLarge: boolean
  caseDetails: InvestigationInfo
  hasPowersearch?: boolean
}

export const CaseIntelligencePanel: FC<Props> = ({ isLarge, caseDetails }) => {
  const { t } = useTranslation()

  const analysis = useStoreState((state) => state.currentCase.analysisData)

  const transportIsAirline = isTransportAirline(caseDetails.products)

  return (
    <Row justify="space-between" gutter={[16, 8]}>
      <SummaryPanel
        isLarge={isLarge}
        title={t('caseIntelligence.address')}
        analysis={analysis?.locationAnalysis}
      />
      <SummaryPanel
        isLarge={isLarge}
        title={t('caseIntelligence.device')}
        analysis={analysis?.deviceAnalysis}
      />
      <SummaryPanel
        isLarge={isLarge}
        title={t('caseIntelligence.email')}
        analysis={analysis?.emailAnalysis}
      />
      {transportIsAirline && (
        <SummaryPanel
          isLarge={isLarge}
          title={t('caseIntelligence.time')}
          analysis={analysis?.timeAnalysis}
        />
      )}
    </Row>
  )
}

export default CaseIntelligencePanel
