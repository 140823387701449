import { i18nInstance } from '@signifyd/components'
import { CaseEntries } from '@signifyd/http'
import { findRolesMatchInSection } from 'store/currentCase/utils/case.entries.utils'
import { googleSearchLink } from '../DeepLinkConstants'
import { DeepLinkFactory, MENU_ICON } from '../DeepLinkDropdown.types'

const getDeepLinksAirportLocation: DeepLinkFactory = ({
  caseEntries,
  value,
}) => {
  const airportLocations = findRolesMatchInSection(
    caseEntries.physicalLocations,
    'flightLeg'
  ) as CaseEntries['physicalLocations']

  const airport = airportLocations?.find(
    (airport) => airport.fullAddress === value
  )

  const airportAddress = airport?.fullAddress ?? ''

  return [
    {
      icon: MENU_ICON.GoogleSearchIcon,
      text: i18nInstance.t('pages.caseReview.details.deepLinks.addressSearch'),
      url: `${googleSearchLink}${airportAddress}`,
    },
    {
      icon: MENU_ICON.GoogleMapIcon,
      text: i18nInstance.t('pages.caseReview.details.deepLinks.addressMapView'),
      url: `https://maps.google.com/maps?z=20&t=k&q=${airportAddress}`,
    },
  ]
}

export default getDeepLinksAirportLocation
