import { Tooltip } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TextThirdGen } from '@signifyd/components'
import { PredictionList } from '@signifyd/http'

interface Props {
  item: PredictionList
}

const TRUNCATE_LIMIT = 30

const OrderAttributeListItem: FC<Props> = ({ item }) => {
  const { t } = useTranslation()
  const { name, size } = item

  const exceedsItemNameLimit = name.length > TRUNCATE_LIMIT

  const listItem = exceedsItemNameLimit
    ? `${name.substring(0, TRUNCATE_LIMIT)}...`
    : name

  return (
    <>
      <Tooltip title={exceedsItemNameLimit && listItem} placement="left">
        <TextThirdGen>{listItem}</TextThirdGen>
      </Tooltip>
      <TextThirdGen>
        {t('pages.caseReview.details.itemsCountThirdGen', {
          count: size,
        })}
      </TextThirdGen>
    </>
  )
}

export default OrderAttributeListItem
