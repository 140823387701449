import { Button, Descriptions } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CaseEntries } from '@signifyd/http'
import CopyWrapper from 'core/components/CopyWrapper'
import { useToggle } from 'core/utils/useToggle'
import { getShowHideItems } from 'pages/CaseReviewPage/containers/OrderDetails/OrderDetails.utils'
import styles from '../CaseDetails.less'
import DeepLinkDropdown from '../components/DeepLinkDropdown'
import {
  MENU_SECTION,
  MENU_SECTION_TYPE,
} from '../components/DeepLinkDropdown/DeepLinkDropdown.types'

const { Item: DescriptionItem } = Descriptions

interface Props {
  airportLocations: CaseEntries['physicalLocations']
}

const AirlineLocations: FC<Props> = ({ airportLocations }) => {
  const { t } = useTranslation()

  const { value: showAll, toggle: toggleShowAll } = useToggle()

  const { maxCountItems, itemsToShow } = getShowHideItems({
    items: airportLocations,
    showAll,
  })

  return (
    <Descriptions size="small" colon={false}>
      {itemsToShow.map((airport, index) => (
        <DescriptionItem
          key={airport.uuid}
          label={index === 0 && t('pages.caseReview.details.airportLocations')}
        >
          <CopyWrapper text={airport.fullAddress} className={styles.fullText}>
            <DeepLinkDropdown
              section={MENU_SECTION.airportLocations}
              type={MENU_SECTION_TYPE.airport}
              value={airport.fullAddress ?? ''}
            >
              {airport.fullAddress}
            </DeepLinkDropdown>
          </CopyWrapper>
        </DescriptionItem>
      ))}
      {!!maxCountItems && (
        <DescriptionItem>
          <Button
            className={styles.showAllButton}
            onClick={toggleShowAll}
            type="link"
            data-analytics-id={`${
              showAll ? 'show-all' : 'show-less'
            }-airline-locations`}
          >
            {showAll
              ? t('pages.caseReview.details.showLess')
              : t('pages.caseReview.details.showAll')}
          </Button>
        </DescriptionItem>
      )}
    </Descriptions>
  )
}

export default AirlineLocations
