import Icon from '@ant-design/icons'
import { Tooltip } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { colorJade, colorRed, colorSlate } from '@signifyd/colors'
import { SigTag, RecommendationIcon } from '@signifyd/components'
import { RECOMMENDED_DECISION_DISPOSITION } from '@signifyd/http'
import { TooltipPlacement } from 'antd/lib/tooltip'

const colors: Record<RECOMMENDED_DECISION_DISPOSITION, Array<string>> = {
  APPROVED: ['jade', colorJade],
  DECLINED: ['red', colorRed],
  PENDING: ['slate', colorSlate],
}

interface Props {
  recommendedDecisionDisposition?: RECOMMENDED_DECISION_DISPOSITION
  tooltipPlacement: TooltipPlacement
  showText?: boolean
}

const RecommendationTag: FC<Props> = ({
  recommendedDecisionDisposition,
  showText = true,
  tooltipPlacement,
}) => {
  const { t } = useTranslation()

  if (!recommendedDecisionDisposition) {
    return null
  }

  return (
    <Tooltip
      placement={tooltipPlacement}
      title={`${t('recommendationTag.recommendation')}: ${t(
        `recommendationTag.${recommendedDecisionDisposition}`
      )}`}
    >
      <SigTag
        type="secondary"
        color={colors[recommendedDecisionDisposition][0]}
      >
        <Icon component={RecommendationIcon} />
        {showText && (
          <span>
            {t(`recommendationTag.${recommendedDecisionDisposition}`)}
          </span>
        )}
      </SigTag>
    </Tooltip>
  )
}

export default RecommendationTag
