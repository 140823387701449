import { Descriptions } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { InvestigationInfo } from '@signifyd/http'
import { COUNTRY_ISO } from '@signifyd/utils'
import CopyWrapper from 'core/components/CopyWrapper'
import NoInformation from 'core/components/NoInformation'
import { defaultDescriptionProps } from 'core/constants'
import { useCaseEntries } from 'core/hooks/useCaseEntries'
import { DescriptionsItemType } from 'antd/es/descriptions'
import {
  findRoleInSection,
  findRolesMatchInSection,
} from 'store/currentCase/utils/case.entries.utils'
import getAddressRow from './getAddressRow'
import IPGeo from './IPGeo'
import { processUniqueAddresses } from './Locations.utils'
import useAirportLocations from './useAirportLocations'

export interface LocationsProps {
  caseDetails: InvestigationInfo
}

const Locations: FC<LocationsProps> = ({ caseDetails }) => {
  const { t } = useTranslation()
  // TODO: Pass this in from parent
  const { data: caseEntries } = useCaseEntries(caseDetails?.investigationId)
  const airportItems = useAirportLocations(caseDetails, caseEntries)

  if (!caseEntries || !caseDetails) {
    return (
      <Descriptions
        title={t('pages.caseReview.details.locations')}
        {...defaultDescriptionProps}
        items={[
          {
            label: '',
            labelStyle: { display: 'none' },
            children: <NoInformation />,
          },
        ]}
      />
    )
  }

  const creditCardIssuer = findRoleInSection(
    caseEntries.organizations,
    'creditCardIssuer'
  )

  const locationIPGeo = findRoleInSection(
    caseEntries.physicalLocations,
    'ipGeo'
  )

  const allDeliveryAddresses = findRolesMatchInSection(
    caseEntries.physicalLocations,
    'deliveryAddress'
  )
  const cardHolderAddress = findRoleInSection(
    caseEntries.physicalLocations,
    'billingAddress'
  )

  const countryIsoCode = creditCardIssuer?.details
    ?.countryIsoCode as keyof typeof COUNTRY_ISO

  const uniqueAddresses = caseDetails ? processUniqueAddresses(caseDetails) : []
  const hasDeliveryAddresses = uniqueAddresses.length > 0

  const hasCountryIsoOrIPGeo = !!countryIsoCode || !!locationIPGeo
  const noInfo = !hasDeliveryAddresses && !hasCountryIsoOrIPGeo

  const locationItems: Array<DescriptionsItemType> = [
    ...uniqueAddresses.map((uniqueAddress) =>
      getAddressRow({ uniqueAddress, allDeliveryAddresses, cardHolderAddress })
    ),
    countryIsoCode && {
      label: t('pages.caseReview.details.creditCardIssuanceLocation'),
      children: (
        <CopyWrapper
          text={`${COUNTRY_ISO[countryIsoCode]} (${countryIsoCode})`}
        >
          {`${COUNTRY_ISO[countryIsoCode]} (${countryIsoCode})`}
        </CopyWrapper>
      ),
    },
    locationIPGeo?.entityName && {
      label: t('pages.caseReview.details.ipGeo'),
      children: (
        <CopyWrapper text={locationIPGeo.entityName}>
          <IPGeo ipGeo={locationIPGeo} />
        </CopyWrapper>
      ),
    },
    ...airportItems,
    noInfo && {
      label: '',
      labelStyle: { display: 'none' },
      children: <NoInformation />,
    },
  ].filter((item) => !!item)

  return (
    <Descriptions
      title={t('pages.caseReview.details.locations')}
      items={locationItems}
      {...defaultDescriptionProps}
    />
  )
}

export default Locations
