import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'store'
import { Policy, USER_ROLE } from '@signifyd/http'
import DecisionCenterLink from 'core/components/DecisionCenterLink'

interface Policies {
  default: Policy
  overriding: Array<Policy>
}

interface Props {
  policies: Policies | null
  checkpointActionPolicy: string | null
}

const ReturnPolicy: FC<Props> = ({ policies, checkpointActionPolicy }) => {
  const { t } = useTranslation()
  const roles = useStoreState((state) => state.user.currentUser?.roles)

  const isRulesBuilderUser = roles?.includes(USER_ROLE.RULES_BUILDER)

  if (!policies || !isRulesBuilderUser) {
    return <p>{checkpointActionPolicy}</p>
  }

  const { id } = policies.overriding[0] ?? policies.default

  return (
    <DecisionCenterLink
      policyName={checkpointActionPolicy}
      policyId={id}
      noPolicyText={t('returnPolicy.noPolicyText')}
    />
  )
}

export default ReturnPolicy
