import { Flex } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'store'
import { spacingSM } from '@signifyd/ant'
import { getOrdersUrl, MagnifyingGlassChart } from '@signifyd/components'
import { USER_ROLE } from '@signifyd/http'
import { ACTION_CONTEXT } from 'core/hooks/caseActions/useClaimReimbursement/types'
import ActionIconLink from 'pages/CaseReviewPage/components/ActionIconButton/ActionIconLink'
import CancelGuaranteeButton from 'pages/CaseReviewPage/components/CancelGuaranteeButton'
import CaseStatusButton from 'pages/CaseReviewPage/components/CaseStatusButton'
import ClaimReimbursementButton from 'pages/CaseReviewPage/components/ClaimReimbursementButton'
import FeedbackActionIconButtons from 'pages/CaseReviewPage/components/FeedbackActionIconButtons/FeedbackActionIconButtons'
import GuaranteeButton from 'pages/CaseReviewPage/components/GuaranteeButton'
import ResubmitGuaranteeButton from 'pages/CaseReviewPage/components/ResubmitGuaranteeButton'
import UpdateAddressButton from 'pages/CaseReviewPage/components/UpdateAddressButton'
import CaseActivityDrawerThirdGen from '../../CaseActivityDrawerThirdGen/CaseActivityDrawerThirdGen'
import styles from './CaseHeaderActionsBar.less'
import AddNoteIconPopover from '../AddNoteIconPopover/AddNoteIconPopover'

const CaseHeaderActionsBar: FC = () => {
  const { t } = useTranslation()

  const caseDetails = useStoreState((state) => state.currentCase.details)
  const canSeeGuaranteeActions = useStoreState(
    (state) => state.caseActions.canSeeGuaranteeActions
  )

  const currentUser = useStoreState((state) => state.user.currentUser)

  const hasAdminRole = currentUser?.roles.includes(USER_ROLE.ADMIN)

  if (!caseDetails) {
    return null
  }

  return (
    <Flex align="center" gap={spacingSM}>
      <Flex className={styles.group} align="center" gap={spacingSM}>
        {canSeeGuaranteeActions && (
          <>
            <GuaranteeButton caseDetails={caseDetails} />
            <ResubmitGuaranteeButton caseDetails={caseDetails} />
            <UpdateAddressButton caseDetails={caseDetails} />
            <ClaimReimbursementButton
              caseDetails={caseDetails}
              context={ACTION_CONTEXT.CaseReview}
            />
            <CancelGuaranteeButton caseDetails={caseDetails} />
          </>
        )}
        <CaseStatusButton caseDetails={caseDetails} />
      </Flex>
      <Flex className={styles.group} align="center" gap={spacingSM}>
        <FeedbackActionIconButtons caseDetails={caseDetails} />
      </Flex>
      {hasAdminRole && (
        <Flex className={styles.group} align="center" gap={spacingSM}>
          <ActionIconLink
            Icon={MagnifyingGlassChart}
            actionState={{ disabled: false }}
            link={`${getOrdersUrl()}/event-viewer/${
              caseDetails.investigationId
            }`}
            tooltip={t('eventViewer.viewEvents')}
          />
        </Flex>
      )}
      <Flex className={styles.group} align="center" gap={spacingSM}>
        <AddNoteIconPopover caseDetails={caseDetails} />
        <CaseActivityDrawerThirdGen />
      </Flex>
    </Flex>
  )
}

export default CaseHeaderActionsBar
