import { DownOutlined } from '@ant-design/icons'
import { Dropdown, MenuProps } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { FILTER_TYPE } from '../CaseActivity.types'
import styles from './NoteFilter.less'

interface Props {
  setNoteFilter: (value: null | FILTER_TYPE) => void
  noteFilter: null | FILTER_TYPE
  filteredNotesCount: number
}

export const NoteFilter: FC<Props> = ({
  setNoteFilter,
  noteFilter,
  filteredNotesCount,
}) => {
  const { t } = useTranslation()

  const filterMenuItems: MenuProps['items'] = [
    {
      key: 'all',
      onClick: () => {
        setNoteFilter(null)
      },
      label: (
        <span
          tabIndex={0}
          role="button"
          id="all"
          data-analytics-id="note-filter-all"
        >
          {t('caseActivity.allActivities')}
        </span>
      ),
    },
    {
      key: 'events',
      onClick: () => {
        setNoteFilter(FILTER_TYPE.EVENTS)
      },
      label: (
        <span
          tabIndex={0}
          role="button"
          id="events"
          data-analytics-id="note-filter-events"
        >
          {t('caseActivity.events')}
        </span>
      ),
    },
    {
      key: 'notes',
      onClick: () => {
        setNoteFilter(FILTER_TYPE.NOTES)
      },
      label: (
        <span
          tabIndex={0}
          role="button"
          id="notes"
          data-analytics-id="note-filter-notes"
        >
          {t('caseActivity.notes')}
        </span>
      ),
    },
  ]

  return (
    <Dropdown
      menu={{ items: filterMenuItems }}
      placement="bottomRight"
      trigger={['click']}
    >
      <Text className={styles.noteFilterBtn}>
        {!noteFilter &&
          t('caseActivity.allActivitiesCount', {
            count: filteredNotesCount,
          })}
        {noteFilter &&
          t(`caseActivity.${noteFilter.toLowerCase()}Count`, {
            count: filteredNotesCount,
            defaultValue: '',
          })}
        <DownOutlined />
      </Text>
    </Dropdown>
  )
}

export default NoteFilter
