import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'store'
import { decodeUrlHash } from 'core/utils/urlEncoder'
import {
  FilterState,
  SearchFilterState,
  SelectedSavedFilterState,
} from 'store/search'
import { defaultSearchFilters } from 'store/search/search.store'

interface Props {
  resetFiltersOnMount?: boolean
  searchId?: string
}

type Filter = {
  filters: FilterState
  updateFilters: (filters: FilterState) => void
  searchTerm: any
  updateSearchTerm: (searchTerm: string) => void
}

const defaultSearchTerm = ''

const getFilterState = (
  defaultState: FilterState,
  searchId: string,
  savedFilterMatch: SelectedSavedFilterState
): FilterState => {
  if (searchId) {
    const savedFilter =
      savedFilterMatch.selectedSavedFilter || savedFilterMatch.selectedQuickView

    const decodedState = decodeUrlHash<SearchFilterState>(
      savedFilter ? savedFilter.searchHash : searchId
    )

    return decodedState.filters
  }

  return defaultState
}

const useSearchFilters = ({
  resetFiltersOnMount,
  searchId = '',
}: Props): Filter => {
  const filtersStoreValue = useStoreState((state) => state.search.filters)
  const searchTermStoreValue = useStoreState((state) => state.search.searchTerm)
  const { setFilterValue } = useStoreActions((actions) => actions.search)
  const getSavedFilterMatch = useStoreState(
    (state) => state.search.getSavedFilterMatch
  )

  const [filters, setFilters] = useState<FilterState>(
    getFilterState(filtersStoreValue, searchId, getSavedFilterMatch(searchId))
  )
  const [searchTerm, setSearchTerm] = useState(searchTermStoreValue)

  useEffect(() => {
    if (!isEqual(filtersStoreValue, filters)) {
      setFilters(filtersStoreValue)
    }
  }, [filtersStoreValue, filters])

  useEffect(() => {
    if (resetFiltersOnMount) {
      setFilters(defaultSearchFilters)
      setSearchTerm(defaultSearchTerm)
    }
  }, [resetFiltersOnMount])

  const updateFilters = (filters: FilterState): void => {
    setFilters(filters)
    setFilterValue(filters)
  }

  const updateSearchTerm = (searchTerm: string): void => {
    setSearchTerm(searchTerm)
  }

  return {
    filters,
    updateFilters,
    searchTerm,
    updateSearchTerm,
  }
}

export default useSearchFilters
