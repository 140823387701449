import { Dropdown } from 'antd'
import { FC, ReactNode } from 'react'
import {
  EkataIcon,
  GoogleMapIcon,
  GoogleSearchIcon,
  LinkedinIcon,
  PiplIcon,
  SigLogoSmall,
  WhitepagesIcon,
  IconProps,
} from '@signifyd/components'
import DeepLinkMenuItems from 'core/components/DeepLinkMenuItems'
import { RuntimeSeller } from 'pages/CaseReviewPage/containers/OrderDetails/components/SellerDetails/SellerDetails.types'
import styles from './DeepLinkDropdown.less'
import { MENU_ICON, DeepLinkFactory } from './DeepLinkDropdown.types'
import {
  getDeepLinksAddress,
  getDeepLinksEmail,
  getDeepLinksName,
  getDeepLinksPhone,
  getDeepLinksAgentConsoleSearch,
} from './getDeepLinks'

const menuTypes = new Map<keyof RuntimeSeller, DeepLinkFactory>([
  ['sellerId', getDeepLinksAgentConsoleSearch],
  ['parentEntity', getDeepLinksAgentConsoleSearch],
  ['name', getDeepLinksName],
  ['address', getDeepLinksAddress],
  ['email', getDeepLinksEmail],
  ['phone', getDeepLinksPhone],
])

const menuIcons = new Map<MENU_ICON, FC<IconProps>>([
  [MENU_ICON.GoogleMapIcon, GoogleMapIcon],
  [MENU_ICON.GoogleSearchIcon, GoogleSearchIcon],
  [MENU_ICON.SigLogoSmall, SigLogoSmall],
  [MENU_ICON.PiplIcon, PiplIcon],
  [MENU_ICON.EkataIcon, EkataIcon],
  [MENU_ICON.WhitepagesIcon, WhitepagesIcon],
  [MENU_ICON.LinkedinIcon, LinkedinIcon],
])

interface Props {
  seller: RuntimeSeller
  field: keyof RuntimeSeller
  children: ReactNode
}

const DeepLinkDropdown: FC<Props> = ({ seller, field, children }) => {
  const menuSection = menuTypes.get(field)
  const menuItemsData = menuSection ? menuSection({ seller, field }) : []

  return (
    <Dropdown
      data-analytics-id={`deeplinkLinkType${field}`}
      data-test-id={`deeplinkLinkType${field}`}
      getPopupContainer={(trigger) => trigger as HTMLElement}
      menu={{ items: DeepLinkMenuItems(menuItemsData, menuIcons) }}
      trigger={['click']}
      placement="bottomRight"
      // Actually make dropdown respect placement given the getPopupContainer is a smaller element https://github.com/ant-design/ant-design/issues/12070
      align={{ overflow: { adjustX: false, adjustY: false } }}
    >
      <span className={styles.sigDropdownTrigger}>{children}</span>
    </Dropdown>
  )
}

export default DeepLinkDropdown
