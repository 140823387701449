import { InboxOutlined } from '@ant-design/icons'
import { Button, Modal, Upload, message } from 'antd'
import { getPciUploadUrl } from 'AppConstants'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, Space, useAuthToken, useIsThirdGen } from '@signifyd/components'
import { COMMON_MODAL_PROPS } from 'core/constants'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'

const { Dragger } = Upload

interface Props {
  investigationId: number
}

export const CaseClaimsBannerUpload: FC<Props> = ({ investigationId }) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()

  const token = useAuthToken()

  const [showModal, setShowModal] = useState(false)
  const [fileList, setFileList] = useState<Array<UploadFile>>([])
  const hasPendingUpload = !!fileList.find(({ status }) => status !== 'done')

  const handleChange = (data: UploadChangeParam): void => {
    if (!data.file?.status) {
      return
    } // Filter rejected files from the beforeUpload function

    setFileList([...data.fileList]) // This needs to be spread due to known antd v3 issue https://github.com/ant-design/ant-design/issues/2423
  }

  const handleSubmit = (): void => {
    setFileList([])
    setShowModal(false)
    message.success(t('claimsBanner.fileUpload.modal.success'))
  }

  const beforeUpload = (file: File): boolean => {
    const supportedFileType =
      file.type.startsWith('application/pdf') ||
      file.type.startsWith('text/') ||
      file.type.startsWith('image/') ||
      file.type.startsWith('audio/') ||
      file.type.startsWith('video/')

    if (!supportedFileType) {
      message.error(t('claimsBanner.fileUpload.modal.unsupportedFileType'))
    }

    const tooLarge = file.size >= 10_000_000 // 10MB in Bytes
    if (tooLarge) {
      message.error(t('claimsBanner.fileUpload.modal.tooLarge'))
    }

    return supportedFileType && !tooLarge
  }

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        type="primary"
        data-test-id="attachFileButton"
      >
        {t('claimsBanner.fileUpload.attachButton')}
      </Button>
      <Modal
        {...COMMON_MODAL_PROPS}
        title={t('claimsBanner.fileUpload.modal.title')}
        open={showModal}
        okType={isThirdGen ? 'primary' : 'link'}
        okText={t('claimsBanner.fileUpload.modal.okButton')}
        okButtonProps={{ disabled: !fileList.length || hasPendingUpload }}
        onOk={handleSubmit}
        onCancel={() => setShowModal(false)}
      >
        <Text>{t('claimsBanner.fileUpload.modal.helpText')}</Text>
        <Space size="sm" />
        <Text size="sm">{t('claimsBanner.fileUpload.modal.subtitle')}</Text>
        <Dragger
          beforeUpload={beforeUpload}
          multiple
          withCredentials
          onChange={handleChange}
          fileList={fileList}
          action={getPciUploadUrl()}
          headers={{
            Authorization: `Bearer ${token}`,
          }}
          data={(file) => ({
            filename: file.name,
            sensitive: 1,
            caseId: `${investigationId}`,
            withCredentials: true,
          })}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            {t('claimsBanner.fileUpload.modal.dragOrClick')}
          </p>
          <p className="ant-upload-hint">
            {t('claimsBanner.fileUpload.modal.provideDocuments')}
          </p>
        </Dragger>
      </Modal>
    </>
  )
}
export default CaseClaimsBannerUpload
