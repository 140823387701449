import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TextThirdGen } from '@signifyd/components'
import { CaseNote, NOTE_TYPE } from '@signifyd/http'
import FormattedOrderReviewChecklistItem from './FormattedOrderReviewChecklistItem'
import NoteFileLink from './NoteFileLink'
import styles from './NoteTimeline.less'
import { isOrderReviewChecklistItem } from './NoteTimelineContent.util'

interface Props {
  note: CaseNote
}

export const NoteTimelineContent: FC<Props> = ({
  note: { text, noteType, files },
}) => {
  const { t } = useTranslation()

  if (isOrderReviewChecklistItem(text)) {
    return <FormattedOrderReviewChecklistItem text={text} />
  }

  return (
    <div
      className={
        noteType === NOTE_TYPE.SIGNIFYD_EVENTS
          ? styles.eventNoteText
          : styles.noteText
      }
    >
      {text}
      {!!files.length && (
        <div>
          <TextThirdGen weight="semibold" paragraph>
            {t('caseActivity.fileNoteLabel')}
          </TextThirdGen>
          {files.map((file) => (
            <NoteFileLink key={file.presignedUrl} file={file} />
          ))}
        </div>
      )}
    </div>
  )
}

export default NoteTimelineContent
