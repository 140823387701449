import { Row } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DateField, TextField } from '../Fields'
import { ReactHookFormTypes } from './Card.types'
import FormCard from './FormCard'

export const CustomerCard: FC<ReactHookFormTypes> = ({ control, errors }) => {
  const { t } = useTranslation()

  return (
    <FormCard title={t('pages.newCase.cards.customer')}>
      <Row>
        <TextField
          fieldName="recipient.confirmationEmail"
          span={16}
          required
          control={control}
          errors={errors}
        />

        <TextField
          fieldName="purchase.browserIpAddress"
          span={8}
          control={control}
          errors={errors}
        />
      </Row>

      <Row>
        <TextField
          fieldName="userAccount.accountNumber"
          span={8}
          control={control}
          errors={errors}
        />

        <DateField
          fieldName="userAccount.createdDate"
          span={8}
          control={control}
          errors={errors}
        />
      </Row>
    </FormCard>
  )
}

export default CustomerCard
