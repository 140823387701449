import { isNil } from 'lodash'
import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { formatCurrencyNumber, notNil } from '@signifyd/utils'
import { RangeFilter } from 'store/search'
import SummaryItem from '../SummaryItem'

interface Props {
  values: RangeFilter<string>
}

const OrderAmountItem: FC<Props> = ({ values }) => {
  const { t } = useTranslation()

  const currency = 'USD'
  const min = isNil(values.min) ? null : Number(values.min)
  const max = isNil(values.max) ? null : Number(values.max)
  const formattedMin = formatCurrencyNumber(min || 0)
  const formattedMax = formatCurrencyNumber(max || 0)

  const rangeText = (): string => {
    if (notNil(min) && isNil(max)) {
      return t('filters.common.atLeast', { currency, min: formattedMin })
    }
    if (notNil(max) && isNil(min)) {
      return t('filters.common.upTo', { currency, max: formattedMax })
    }
    if (notNil(max) && notNil(min)) {
      return t('filters.common.range', {
        currency,
        min: formattedMin,
        max: formattedMax,
      })
    }

    return ''
  }

  return (
    <SummaryItem label={t('filters.orderAmount.label')} value={rangeText()} />
  )
}

export default memo(OrderAmountItem)
