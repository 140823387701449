import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'store'
import { colorEmerald, colorRed, colorMarengo } from '@signifyd/colors'
import { ThumbsDown, ThumbsUp } from '@signifyd/components'
import { INV_REVIEW_DISPOSITION, InvestigationInfo } from '@signifyd/http'
import { canSetCaseFeedback, useCaseFeedback } from 'core/hooks/useCaseFeedback'
import { CASE_CAPABILITIES, hasCapability } from 'core/utils/capabilities'
// TODO: Move in here post 3rd gen
import ActionIconButton from '../ActionIconButton/ActionIconButton'
import FeedbackReasonModal from '../FeedbackDropdown/FeedbackReasonModal'

interface Props {
  caseDetails: InvestigationInfo
}

const FeedbackActionIconButtons: FC<Props> = ({ caseDetails }) => {
  const { t } = useTranslation()

  const isCaseLoading = useStoreState((state) => state.currentCase.loading)
  const actionState = canSetCaseFeedback(caseDetails)

  const [showReasonModal, setShowReasonModal] = useState(false)

  const { isLoading: isUpdating, mutate } = useCaseFeedback()

  // Wait for case to refresh or there's a weird UX where the button colors show the old one for a split second
  const isLoading = isUpdating || isCaseLoading

  const handleChange = (merchantFeedback: INV_REVIEW_DISPOSITION): void => {
    if (isLoading) {
      return
    }

    const needsFeedbackForGoodFlag = hasCapability(
      caseDetails.teamId,
      caseDetails.customerId,
      CASE_CAPABILITIES.REQUIRE_GOOD_FEEDBACK_REASON
    )

    if (
      needsFeedbackForGoodFlag &&
      merchantFeedback === INV_REVIEW_DISPOSITION.GOOD
    ) {
      setShowReasonModal(true)
    } else {
      mutate({
        caseDetails,
        feedback: merchantFeedback ?? INV_REVIEW_DISPOSITION.NONE,
      })
    }
  }

  const { investigationReviewDisposition: disposition } = caseDetails

  return (
    <>
      <FeedbackReasonModal
        caseDetails={caseDetails}
        showModal={showReasonModal}
        setShowModal={setShowReasonModal}
      />
      <ActionIconButton
        Icon={ThumbsUp}
        iconColor={
          !isLoading && disposition === INV_REVIEW_DISPOSITION.GOOD
            ? colorEmerald
            : colorMarengo
        }
        actionState={actionState}
        isLoading={isLoading}
        testId="thumbsUpButton"
        tooltip={t('caseActions.actionIcons.thumbsUp.tooltip')}
        onClick={() => {
          const newDisposition =
            disposition === INV_REVIEW_DISPOSITION.GOOD
              ? INV_REVIEW_DISPOSITION.NONE
              : INV_REVIEW_DISPOSITION.GOOD

          handleChange(newDisposition)
        }}
      />
      <ActionIconButton
        Icon={ThumbsDown}
        iconColor={
          !isLoading && disposition === INV_REVIEW_DISPOSITION.BAD
            ? colorRed
            : colorMarengo
        }
        actionState={actionState}
        isLoading={isLoading}
        testId="thumbsDownButton"
        tooltip={t('caseActions.actionIcons.thumbsDown.tooltip')}
        onClick={() => {
          const newDisposition =
            disposition === INV_REVIEW_DISPOSITION.BAD
              ? INV_REVIEW_DISPOSITION.NONE
              : INV_REVIEW_DISPOSITION.BAD

          handleChange(newDisposition)
        }}
      />
    </>
  )
}

export default FeedbackActionIconButtons
