import moment, { Moment } from 'moment-timezone'
import {
  getMaxFlexibleSearchIsoDate,
  getMinFlexibleSearchIsoDate,
} from '@signifyd/utils'
import { RangeFilter } from 'store/search/types'

interface DateTimezoneConfig {
  subtractValue: number
  subtractInterval: moment.unitOfTime.DurationConstructor
  input?: Moment
}

type DateTimeFormat = {
  date?: string | Moment
  timeZone: string
  format?: string
}

export const formatDateTimezone = ({
  date = moment(),
  timeZone,
  format,
}: DateTimeFormat): string =>
  moment(date)
    .utc()
    .tz(timeZone ?? moment.tz.guess())
    .format(format)

export const getDateTimezone = ({
  date,
  timeZone,
}: {
  date: string
  timeZone: string
}): Moment =>
  moment(date)
    .clone()
    .tz(timeZone ?? moment.tz.guess())

export const getStartOfDay = (input: Moment = moment()): string =>
  getMinFlexibleSearchIsoDate(input)

export const getEndOfDay = (): string => getMaxFlexibleSearchIsoDate(moment())

export const getPastStartDate = ({
  input = moment(),
  subtractValue = 2,
  subtractInterval = 'weeks',
}: DateTimezoneConfig): string =>
  getMinFlexibleSearchIsoDate(input.subtract(subtractValue, subtractInterval))

export const getDefaultDateRange = (): { min: string; max: string } => {
  return {
    min: getPastStartDate({
      subtractInterval: 'weeks',
      subtractValue: 2,
    }),
    max: getEndOfDay(),
  }
}

export const getMinMaxDate = (
  dates: RangeFilter<string>
): { minDate: Date; maxDate: Date } => {
  const defaultDateRange = getDefaultDateRange()

  const minDate = new Date(dates.min ?? defaultDateRange.min)
  const maxDate = new Date(dates.max ?? defaultDateRange.max)

  return { minDate, maxDate }
}
