import { Tooltip, Popover } from 'antd'
import { FC } from 'react'
import { Text } from '@signifyd/components'
import styles from './AdditionalValues.less'

export interface AdditionalValueProps {
  values: Array<string>
  total?: number
  title?: string
  displayType?: 'popover' | 'tooltip'
}

const ValuesTooltip: FC<AdditionalValueProps> = ({ values, total = 2 }) => {
  return (
    <Tooltip
      overlayStyle={{ maxWidth: 'none' }}
      title={
        <>
          <Text className={styles.additionalValuesTooltip} size="md" strong>
            {values.join(', ')}
          </Text>
        </>
      }
      mouseEnterDelay={0.5}
    >
      {', '}
      <Text className={styles.additionalValuesText}>
        +{values.length - total} More
      </Text>
    </Tooltip>
  )
}

const ValuesPopover: FC<AdditionalValueProps> = ({
  values,
  total = 2,
  title = '',
}) => {
  return (
    <Popover
      title={title}
      content={
        <div>
          <ul
            className={styles.additionalValuesPopoverText}
            data-test-id="popoverList"
          >
            {values.map((value) => (
              <li key={value}>{value}</li>
            ))}
          </ul>
        </div>
      }
      mouseEnterDelay={0.5}
    >
      {', '}
      <Text className={styles.additionalValuesText}>
        +{values.length - total} More
      </Text>
    </Popover>
  )
}

const AdditionalValuesDisplay: FC<AdditionalValueProps> = ({
  values,
  total = 2,
  title = '',
  displayType = 'tooltip',
}) => {
  if (values.length <= total) {
    return null
  }
  if (displayType === 'popover') {
    return <ValuesPopover values={values} total={total} title={title} />
  }

  return <ValuesTooltip values={values} total={total} />
}

export default AdditionalValuesDisplay
