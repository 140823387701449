import { i18nInstance } from '@signifyd/components'
import { googleSearchLink, linkedInSearchLink } from '../DeepLinkConstants'
import { DeepLinkFactory, MENU_ICON } from '../DeepLinkDropdown.types'

const getDeepLinksOccupants: DeepLinkFactory = ({ caseEntries, value }) => {
  if (!value) {
    return []
  }

  const { deliveryRecipientAddress } = caseEntries

  // build a Google Search URL with Full Name/City/Region
  const googleFullNameCityRegionSearchURL = [
    googleSearchLink + value,
    deliveryRecipientAddress?.details?.cityName || '',
    deliveryRecipientAddress?.details?.regionAlpha || '',
  ].join(' ')

  // build a Linkedin URL
  const linkedinURL = linkedInSearchLink + value

  return [
    {
      icon: MENU_ICON.GoogleSearchIcon,
      text: i18nInstance.t(
        'pages.caseReview.details.deepLinks.googleFullNameAddressSearch'
      ),
      url: googleFullNameCityRegionSearchURL,
    },
    {
      icon: MENU_ICON.LinkedinIcon,
      text: i18nInstance.t(
        'pages.caseReview.details.deepLinks.linkedinFullNameSearch'
      ),
      url: linkedinURL,
    },
  ]
}

export default getDeepLinksOccupants
