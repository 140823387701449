import { Tooltip } from 'antd'
import resources from 'locales'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Text,
  ExternalLink,
  getCaseSearchUrl,
  IconProps,
} from '@signifyd/components'
import { CountAttrs } from '@signifyd/http'
import styles from './SummaryPanelActions.less'

interface Props {
  type: keyof (typeof resources)['en-US']['translation']['caseIntelligence']['panelActions']
  Icon: FC<IconProps>
  investigationId: number
  countAttrs?: CountAttrs
}

export const SummaryPanelActions: FC<Props> = ({
  type,
  Icon,
  countAttrs,
  investigationId,
}) => {
  const { t } = useTranslation()

  if (!countAttrs) {
    return null
  }

  return (
    <ExternalLink
      url={`${getCaseSearchUrl()}?similarityInvestigationId=${investigationId}&similarityAttributes=${
        countAttrs.similarityAttributes
      }`}
      target="_blank"
      className={styles.hoverWrapper}
      data-analytics-id={`summary-panel-action-${type}`}
      data-test-id={`summaryPanelAction${type}`}
    >
      <Tooltip
        title={t('caseIntelligence.panelActions.disclaimer', {
          type: t(`caseIntelligence.panelActions.${type}`),
          count: countAttrs.count,
        })}
      >
        <Icon data-test-id={`countIcon-${type}`} />
        <Text
          type="secondary"
          className={styles.actionCount}
          data-test-id={`summaryPanelAction${type}Count`}
        >
          {countAttrs.count}
        </Text>
      </Tooltip>
    </ExternalLink>
  )
}

export default SummaryPanelActions
