import { message } from 'antd'
import { action, computed, thunk } from 'easy-peasy'
import { i18nInstance } from '@signifyd/components'
import caseAnalysisModel from './case.analysis.store'
import caseArkOwlDataModel from './case.arkOwl.store'
import caseDetailsModel from './case.details.store'
import caseEkataDataModel from './case.ekata.store'
import caseEntriesModel from './case.entries.store'
import caseNotesModel from './case.notes.store'
import caseOrderModel from './case.order.store'
import { CaseStoreModel } from './types/index'
import versionChangePoller from './utils/case.polling.utils'

export * from './types/index'

export const caseStoreModel: CaseStoreModel = {
  // Case Model
  ...caseDetailsModel,
  ...caseNotesModel,
  ...caseOrderModel,
  ...caseAnalysisModel,
  ...caseEntriesModel,
  // 3rd Part enrichment data
  ...caseArkOwlDataModel,
  ...caseEkataDataModel,

  // Get / Set / Clear currentCase model
  loading: false,

  caseNotFound: computed((state) => {
    return !state.loading && !state.details && !state.detailsServerError
  }),

  setLoading: action((state, payload) => {
    state.loading = payload
  }),

  getCurrentCase: thunk((actions, caseId) => {
    actions.setLoading(true)

    return Promise.all([
      actions.getCaseDetails(caseId),
      actions.getCaseOrder(caseId),
      actions.getCaseAnalysis(caseId),
      actions.getCaseNotes(caseId),
    ])
      .catch(({ response }) => {
        if (response?.status !== 404) {
          message.error(i18nInstance.t('store.case.getCaseFailure'))
        }
      })
      .finally(() => {
        actions.setLoading(false)
      })
  }),

  pollForChanges: thunk((actions, _p, { getState }) => {
    const caseDetails = getState().details
    if (caseDetails) {
      versionChangePoller.pollForChanges({
        ...caseDetails,
        onChangeHandler: () => {
          actions.refreshCurrentCase()
        },
      })
    }
  }),

  refreshCurrentCase: thunk((actions, _p, { getState }) => {
    const caseDetails = getState().details

    if (caseDetails) {
      actions.getCurrentCase(caseDetails.investigationId)
    }
  }),

  clearCurrentCase: thunk((actions) => {
    actions.clearCaseDetails()
    actions.clearCaseAnalysis()
    actions.clearCaseOrder()
    actions.clearCaseEntries()
    actions.clearCaseNotes()
    versionChangePoller.stopPolling()
  }),
}
