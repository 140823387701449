import Icon, { FullscreenOutlined, PaperClipOutlined } from '@ant-design/icons'
import { Button, Input, Tooltip, Upload } from 'antd'
import { forwardRef, ForwardRefRenderFunction } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AttachFileButton,
  AttachFilesForUploadHook,
  SendIcon,
  Text,
} from '@signifyd/components'
import { joinClassNames } from '@signifyd/utils'
import { ALLOWED_UPLOAD_FILE_TYPES_NOTE_ATTACHMENT } from 'core/constants'
import styles from './NoteInput.less'

interface Props {
  value: string
  isSubmitDisabled: boolean
  fileAttacher: AttachFilesForUploadHook
  maxNoteLength: number
  onChange: (value: string) => void
  onSubmitNote: () => void
  onShowModal: () => void
  className?: string | false
}

const { TextArea } = Input

// TODO FET-1810 https://signifyd.atlassian.net/browse/FET-1810
// Rename to sidebar or panelnoteinput
export const NoteInput: ForwardRefRenderFunction<unknown, Props> = (
  {
    value,
    isSubmitDisabled,
    className,
    onChange,
    onSubmitNote,
    onShowModal,
    fileAttacher,
    maxNoteLength,
  },
  ref: any
) => {
  const { t } = useTranslation()

  const { onAttachFiles, onRemoveFile, files } = fileAttacher

  const noteTooLong = value.length > maxNoteLength

  return (
    <div
      className={joinClassNames([
        className,
        noteTooLong ? styles.noteCardExceeded : styles.noteCard,
      ])}
      ref={ref}
      data-test-id="noteInput"
    >
      <TextArea
        className={styles.noteInputArea}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={t('caseActivity.addNotesPlaceholder')}
        rows={3}
        style={{ resize: 'none' }}
        data-test-id="noteInputArea"
      />

      {noteTooLong && (
        <Tooltip
          placement="top"
          title={t('caseActivity.maxLength', {
            count: value.length - maxNoteLength,
            limit: maxNoteLength,
          })}
        >
          <Text type="danger" data-test-id="noteTooLongTooltip">
            {value.length - maxNoteLength}/{maxNoteLength}
          </Text>
        </Tooltip>
      )}

      <div className={styles.uploadList}>
        <Upload
          onRemove={onRemoveFile}
          showUploadList={{
            showPreviewIcon: false,
            showDownloadIcon: false,
          }}
          fileList={files}
        />
      </div>

      <div className={styles.noteActionBtns}>
        <Text type="secondary">
          <FullscreenOutlined onClick={onShowModal} data-test-id="showModal" />
        </Text>
        <span className={styles.rightBtns}>
          <Text type="secondary">
            <AttachFileButton
              onAttach={onAttachFiles}
              type="link"
              accept={ALLOWED_UPLOAD_FILE_TYPES_NOTE_ATTACHMENT}
              buttonClassName={styles.attachFileButton}
              dataAnalyticsId="note-input-file-button"
              dataTestId="noteInputFileButton"
            >
              <PaperClipOutlined
                data-test-id="clippy"
                className={styles.iconWithLabel}
              />
            </AttachFileButton>
          </Text>

          <Button
            className={styles.sendButton}
            disabled={isSubmitDisabled}
            onClick={onSubmitNote}
            type="primary"
            data-test-id="submitNoteButton"
          >
            <Icon component={SendIcon} />
          </Button>
        </span>
      </div>
    </div>
  )
}

export default forwardRef(NoteInput)
