import { Button, Descriptions } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CaseEntriesItem, CaseEntryPersonDetails } from '@signifyd/http'
import CopyWrapper from 'core/components/CopyWrapper'
import { useToggle } from 'core/utils/useToggle'
import SeederLabel from 'pages/CaseReviewPage/components/SeederLabel'
import { getShowHideItems } from 'pages/CaseReviewPage/containers/OrderDetails/OrderDetails.utils'

import styles from '../CaseDetails.less'
import { MENU_SECTION_TYPE } from '../components/DeepLinkDropdown/DeepLinkDropdown.types'

const { Item: DescriptionItem } = Descriptions

type Passenger = CaseEntriesItem<CaseEntryPersonDetails>

interface Props {
  people: Array<Passenger>
}

const AirlinePassenger: FC<Props> = ({ people }) => {
  const { t } = useTranslation()

  const { value: showAll, toggle: toggleShowAll } = useToggle()

  const leadPassenger = people.find(
    (passenger) => passenger.role === MENU_SECTION_TYPE.leadPassenger
  )

  const passengers = people.filter(
    (passenger) => passenger.role === MENU_SECTION_TYPE.passenger
  )

  const { maxCountItems, itemsToShow } = getShowHideItems({
    items: passengers,
    showAll,
  })

  return (
    <Descriptions size="small" colon={false}>
      {leadPassenger?.entityName && (
        <DescriptionItem
          label={
            <SeederLabel label={t('pages.caseReview.details.leadPassenger')} />
          }
        >
          <CopyWrapper text={leadPassenger.entityName}>
            {leadPassenger.entityName}
          </CopyWrapper>
        </DescriptionItem>
      )}
      {itemsToShow.map((passenger) => (
        <DescriptionItem
          key={passenger.uuid}
          label={
            <SeederLabel label={t('pages.caseReview.details.passenger')} />
          }
        >
          <CopyWrapper text={passenger.entityName}>
            {passenger.entityName}
          </CopyWrapper>
        </DescriptionItem>
      ))}
      {!!maxCountItems && (
        <DescriptionItem>
          <Button
            className={styles.showAllButton}
            onClick={toggleShowAll}
            type="link"
          >
            {showAll
              ? t('pages.caseReview.details.showLess')
              : t('pages.caseReview.details.showAll')}
          </Button>
        </DescriptionItem>
      )}
    </Descriptions>
  )
}

export default AirlinePassenger
