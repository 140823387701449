import { Row } from 'antd'
import { compact, uniq, map } from 'lodash'
import { FC } from 'react'
import { Shipment } from '@signifyd/http'
import { toTitleCase } from '@signifyd/utils'
import SummaryIcon from '../SummaryIcon'

interface Props {
  sectionName: string
  shipments: Array<Shipment>
}

const ShippingSummaryLabel: FC<Props> = ({ sectionName, shipments }) => {
  const shippers = compact(
    uniq([...map(shipments, 'shipper'), ...map(shipments, 'shippingMethod')])
  )

  return (
    <>
      {sectionName}
      <Row>
        {shippers.map((shipper, key) => (
          <SummaryIcon
            key={key}
            type={shipper}
            tooltip={toTitleCase(shipper)}
          />
        ))}
      </Row>
    </>
  )
}

export default ShippingSummaryLabel
