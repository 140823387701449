import { Button, Flex, Tooltip } from 'antd'
import cx from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { spacingMD } from '@signifyd/ant'
import { colorMarengo } from '@signifyd/colors'
import { Padlock, TextThirdGen } from '@signifyd/components'
import { SEARCH_FIELD } from '@signifyd/http'
import styles from './ThirdGenTableColumnListItem.less'

interface Props {
  isLocked: boolean
  isHidden: boolean
  title: SEARCH_FIELD
  onToggleHidden: () => void
}

const ThirdGenTableColumnListItem: FC<Props> = ({
  isLocked,
  title,
  isHidden,
  onToggleHidden,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.results.columnSelect',
  })

  const columnTitle = t(`keys.${title}`)

  const lockedItem = (
    <Tooltip
      placement="top"
      arrow={{ pointAtCenter: true }}
      title={<div>{t('tooltip')}</div>}
    >
      <Flex
        align="center"
        gap={spacingMD}
        className={styles.lockedContainer}
        data-test-id="columnLocked"
      >
        <Padlock className={styles.lockIcon} fill={colorMarengo} />
        <TextThirdGen className={styles.lockedItem}>{columnTitle}</TextThirdGen>
      </Flex>
    </Tooltip>
  )

  const item = (
    <Flex justify="space-between" align="center">
      <TextThirdGen>{columnTitle}</TextThirdGen>
      <Button
        type="link"
        data-test-id={`${title}-drawer-button-${isHidden ? 'show' : 'hide'}`}
        onClick={onToggleHidden}
      >
        {t(`${isHidden ? 'show' : 'hide'}Column`)}
      </Button>
    </Flex>
  )

  return (
    <div className={cx([styles.itemContainer])}>
      {isLocked ? lockedItem : item}
    </div>
  )
}

export default ThirdGenTableColumnListItem
