import { Button, Descriptions } from 'antd'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { InvestigationInfo, PREDICTION_LIST_TYPE } from '@signifyd/http'
import CopyWrapper from 'core/components/CopyWrapper'
import NoInformation from 'core/components/NoInformation'
import OrderAttributeLists from 'core/components/OrderAttributeLists/OrderAttributeLists'
import { isTransportAirline } from 'core/utils/isTransportAirline/isTransportAirline'
import { useToggle } from 'core/utils/useToggle'
import SeederLabel from 'pages/CaseReviewPage/components/SeederLabel'
import { getShowHideItems } from 'pages/CaseReviewPage/containers/OrderDetails/OrderDetails.utils'
import { CaseEntriesResolvedState } from 'store/currentCase/types/case.entries.types'
import styles from '../CaseDetails.less'
import AirlinePassenger from './AirlinePassenger'
import DeepLinkDropdown from '../components/DeepLinkDropdown'
import {
  MENU_SECTION,
  MENU_SECTION_TYPE,
} from '../components/DeepLinkDropdown/DeepLinkDropdown.types'

const { Item: DescriptionItem } = Descriptions

interface Props {
  caseEntries: CaseEntriesResolvedState
  caseDetails: InvestigationInfo
  accountHolderTaxInfo: {
    accountHolderTaxIdCountry?: string
    accountHolderTaxId?: string
  }
}

const People: FC<Props> = ({
  caseEntries,
  caseDetails,
  accountHolderTaxInfo,
}) => {
  const { t } = useTranslation()

  const { cardHolder, deliveryRecipient, deliveryRecipientAddress, people } =
    caseEntries

  const isAirlineOrder = isTransportAirline(caseDetails.products)

  const { accountHolderTaxIdCountry, accountHolderTaxId } = accountHolderTaxInfo

  const { value: showAll, toggle: toggleShowAll } = useToggle()

  const addressOccupants =
    deliveryRecipientAddress?.details?.wpReverseAddressV3Model
      ?.current_residents ?? []

  const { maxCountItems, itemsToShow } = getShowHideItems({
    items: addressOccupants,
    showAll,
  })

  const noInfo =
    !cardHolder &&
    !deliveryRecipient &&
    !addressOccupants.length &&
    !accountHolderTaxIdCountry &&
    !accountHolderTaxId &&
    !people.length

  const sectionName = t('pages.caseReview.details.people')

  if (noInfo) {
    return (
      <>
        <Descriptions title={sectionName} colon={false} size="small" />
        <NoInformation />
      </>
    )
  }

  return (
    <>
      <Descriptions title={sectionName} colon={false} size="small" />
      {cardHolder?.entityName && (
        <Descriptions colon={false} size="small">
          <DescriptionItem
            label={
              <SeederLabel label={t('pages.caseReview.details.cardHolder')} />
            }
          >
            <CopyWrapper text={cardHolder.entityName}>
              <DeepLinkDropdown
                section={MENU_SECTION.people}
                type={MENU_SECTION_TYPE.cardHolder}
              >
                {cardHolder.entityName}
              </DeepLinkDropdown>
            </CopyWrapper>
          </DescriptionItem>
        </Descriptions>
      )}
      {deliveryRecipient?.entityName && (
        <Descriptions colon={false} size="small">
          <DescriptionItem
            label={
              <SeederLabel
                label={t('pages.caseReview.details.deliveryRecipient')}
              />
            }
          >
            <CopyWrapper text={deliveryRecipient.entityName}>
              <DeepLinkDropdown
                section={MENU_SECTION.people}
                type={MENU_SECTION_TYPE.deliveryRecipient}
              >
                {deliveryRecipient.entityName}
              </DeepLinkDropdown>
            </CopyWrapper>
          </DescriptionItem>
        </Descriptions>
      )}
      {accountHolderTaxId && (
        <>
          <Descriptions colon={false} size="small">
            <DescriptionItem
              label={
                <SeederLabel
                  label={t('pages.caseReview.details.accountHolderTaxId')}
                />
              }
            >
              <CopyWrapper text={accountHolderTaxId}>
                {accountHolderTaxId}
              </CopyWrapper>
            </DescriptionItem>
          </Descriptions>
          <OrderAttributeLists
            type={PREDICTION_LIST_TYPE.TAX_ID}
            value={accountHolderTaxId}
            analyticsId="tax-ID"
          />
        </>
      )}
      {accountHolderTaxIdCountry && (
        <Descriptions colon={false} size="small">
          <DescriptionItem
            label={
              <SeederLabel
                label={t('pages.caseReview.details.accountHolderTaxIdCountry')}
              />
            }
          >
            <CopyWrapper text={accountHolderTaxIdCountry}>
              {accountHolderTaxIdCountry}
            </CopyWrapper>
          </DescriptionItem>
        </Descriptions>
      )}
      {itemsToShow.map((occupant) => (
        <Descriptions colon={false} size="small" key={occupant.id}>
          <DescriptionItem
            label={t('pages.caseReview.details.addressOccupants.label')}
          >
            <CopyWrapper text={occupant.name}>
              <DeepLinkDropdown
                section={MENU_SECTION.occupants}
                type={MENU_SECTION_TYPE.addressOccupant}
                value={occupant.name}
              >
                {occupant.name}
              </DeepLinkDropdown>
              {occupant.age_range && (
                <>
                  <br />
                  <Text>
                    {t('pages.caseReview.details.addressOccupants.age', {
                      ageRange: occupant.age_range,
                    })}
                  </Text>
                </>
              )}
              {occupant.gender && (
                <>
                  <br />
                  <Text>
                    {t('pages.caseReview.details.addressOccupants.gender', {
                      gender: occupant.gender,
                    })}
                  </Text>
                </>
              )}
              {occupant.phones?.map((phoneDetails) => (
                <Fragment key={phoneDetails.id}>
                  <br />
                  <Text>
                    {t('pages.caseReview.details.addressOccupants.phone', {
                      phoneNumber: phoneDetails.phone_number,
                    })}
                  </Text>
                </Fragment>
              ))}
            </CopyWrapper>
          </DescriptionItem>
        </Descriptions>
      ))}
      {!!maxCountItems && (
        <Descriptions colon={false} size="small">
          <DescriptionItem>
            <Button
              className={styles.showAllButton}
              onClick={toggleShowAll}
              type="link"
            >
              {showAll
                ? t('pages.caseReview.details.addressOccupants.showLess')
                : t('pages.caseReview.details.addressOccupants.showAll')}
            </Button>
          </DescriptionItem>
        </Descriptions>
      )}
      {isAirlineOrder && <AirlinePassenger people={people} />}
    </>
  )
}

export default People
