import { uniqBy } from 'lodash'
import { AncillaryPurchase } from '@signifyd/http'
import { toSentenceCase } from '@signifyd/utils'

interface AncillaryDetails {
  ancillaryCount: number | undefined
  ancillaryText: string | undefined
}

export const getAncillaryDetails = (
  ancillaryPurchases?: Array<AncillaryPurchase>
): AncillaryDetails => {
  const ancillaryCount = ancillaryPurchases?.reduce(
    (count: number, ancillaryPurchase) => {
      return count + (ancillaryPurchase.itemQuantity ?? 0)
    },
    0
  )

  const dedupeAncillaries = uniqBy(ancillaryPurchases, 'ancillaryPurchaseType')

  const ancillaryText = dedupeAncillaries
    .map((ancillaryPurchase) => toSentenceCase(ancillaryPurchase.itemName))
    .join(', ')

  return {
    ancillaryCount,
    ancillaryText,
  }
}
