import { Descriptions } from 'antd'
import { uniqBy } from 'lodash'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CaseEntriesItem,
  CaseEntryNetworkDetails,
  InvestigationInfo,
  PREDICTION_LIST_TYPE,
} from '@signifyd/http'
import CopyWrapper from 'core/components/CopyWrapper'
import NoInformation from 'core/components/NoInformation'
import OrderAttributeLists from 'core/components/OrderAttributeLists'
import SeederLabel from 'pages/CaseReviewPage/components/SeederLabel'
import { CaseEntriesResolvedState } from 'store/currentCase/types/case.entries.types'
import DeepLinkDropdown from '../components/DeepLinkDropdown'
import { MENU_SECTION } from '../components/DeepLinkDropdown/DeepLinkDropdown.types'

const { Item: DescriptionItem } = Descriptions

export interface NetworkProps {
  caseEntries: CaseEntriesResolvedState
  caseDetails: InvestigationInfo
}

const Network: FC<NetworkProps> = ({ caseEntries, caseDetails }) => {
  const { t } = useTranslation()
  const { emailDomains, purchaseIP, ipDomain } = caseEntries

  const { browserIpAddress } = caseDetails

  const deDupedDomains = uniqBy(emailDomains, 'seederUuid')

  const noInfo = !purchaseIP && !emailDomains && !ipDomain
  const sectionName = t('pages.caseReview.details.network')

  return (
    <>
      <Descriptions title={sectionName} colon={false} size="small">
        {purchaseIP?.entityName && (
          <DescriptionItem
            label={
              <SeederLabel label={t('pages.caseReview.details.purchaseIP')} />
            }
          >
            <CopyWrapper text={purchaseIP.entityName}>
              <DeepLinkDropdown section={MENU_SECTION.network}>
                {purchaseIP.entityName}
              </DeepLinkDropdown>
            </CopyWrapper>
          </DescriptionItem>
        )}
      </Descriptions>
      {browserIpAddress && (
        <OrderAttributeLists
          type={PREDICTION_LIST_TYPE.IP_ADDRESS}
          value={browserIpAddress}
          analyticsId="purchase-IP"
        />
      )}
      <Descriptions colon={false} size="small">
        {deDupedDomains.map(
          (emailDomain: CaseEntriesItem<CaseEntryNetworkDetails>) => (
            <DescriptionItem
              key={emailDomain.seederUuid}
              label={t('pages.caseReview.details.emailDomain')}
            >
              <CopyWrapper text={emailDomain.entityName}>
                {emailDomain.entityName}
              </CopyWrapper>
            </DescriptionItem>
          )
        )}
        {ipDomain?.entityName && (
          <DescriptionItem label={t('pages.caseReview.details.ipDomain')}>
            <CopyWrapper text={ipDomain.entityName}>
              {ipDomain.entityName}
            </CopyWrapper>
          </DescriptionItem>
        )}
      </Descriptions>
      {noInfo && <NoInformation />}
    </>
  )
}

export default Network
