import { Form } from '@ant-design/compatible'
import { Flex, InputNumber } from 'antd'
import { isNil } from 'lodash'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { spacingSM } from '@signifyd/ant'
import { TextThirdGen } from '@signifyd/components'
import { SEARCH_FIELD } from '@signifyd/http'
import { FilterState, RangeFilter } from 'store/search'
import styles from '../FiltersContainer.less'

interface Props {
  currentFilter: RangeFilter<string>
  updateFilterFn: (update: Partial<FilterState>) => void
}

const ThirdGenOrderAmountFilter: FC<Props> = ({
  currentFilter: { min: minAmount, max: maxAmount },
  updateFilterFn,
}) => {
  const { t } = useTranslation()

  const handleChange = (isMin: boolean, value?: number | null): void => {
    const normalizedValue = isNil(value) ? null : String(value)

    updateFilterFn({
      [SEARCH_FIELD.orderTotalAmount]: {
        min: isMin ? normalizedValue : minAmount,
        max: !isMin ? normalizedValue : maxAmount,
      },
    })
  }

  return (
    <Form.Item
      label={t('filters.orderAmount.label')}
      className={styles.rangeInputs}
    >
      <Flex gap={spacingSM} align="center">
        <InputNumber
          data-test-id="orderAmountMin"
          data-analytics-id="order-amount-min-filter"
          className={styles.InputNumber}
          placeholder={t('filters.placeholder.min')}
          value={isNil(minAmount) ? undefined : Number(minAmount)}
          onChange={(value) => handleChange(true, value)}
          min={0}
        />
        <TextThirdGen>{t('filters.placeholder.to')}</TextThirdGen>
        <InputNumber
          data-test-id="orderAmountMax"
          data-analytics-id="order-amount-max-filter"
          className={styles.InputNumber}
          placeholder={t('filters.placeholder.max')}
          value={isNil(maxAmount) ? undefined : Number(maxAmount)}
          onChange={(value) => handleChange(false, value)}
          min={0}
        />
      </Flex>
    </Form.Item>
  )
}

export default ThirdGenOrderAmountFilter
