import {
  colorSlate,
  colorEmeraldDark20,
  colorRedDark20,
} from '@signifyd/colors'
import { ArrowRightArrowLeftDuoTone, i18nInstance } from '@signifyd/components'
import { CHECKPOINT_ACTION } from '@signifyd/http'

export interface DisplayData {
  icon: JSX.Element
  label: string
  className: string
}

export const fallbackCheckpoint = (text: string): DisplayData => {
  console.error(`Checkpoint action ${text} does not exist.`)

  return {
    icon: <ArrowRightArrowLeftDuoTone fill={colorSlate} />,
    label: text,
    className: 'noMatch',
  }
}

export const getReturnTagCheckpointData = (
  checkpoint: CHECKPOINT_ACTION
): DisplayData => {
  const { t } = i18nInstance

  const checkpointActions = {
    ACCEPT: {
      icon: <ArrowRightArrowLeftDuoTone fill={colorEmeraldDark20} />,
      label: t('pages.results.returnTag.accept'),
      className: 'accept',
    },
    HOLD: {
      icon: <ArrowRightArrowLeftDuoTone fill={colorSlate} />,
      label: t('pages.results.returnTag.hold'),
      className: 'hold',
    },
    REJECT: {
      icon: <ArrowRightArrowLeftDuoTone fill={colorRedDark20} />,
      label: t('pages.results.returnTag.reject'),
      className: 'reject',
    },
    CHALLENGE: {
      icon: <ArrowRightArrowLeftDuoTone fill={colorSlate} />,
      label: t('pages.results.returnTag.challenge'),
      className: 'challenge',
    },
    CREDIT: {
      icon: <ArrowRightArrowLeftDuoTone fill={colorSlate} />,
      label: t('pages.results.returnTag.credit'),
      className: 'credit',
    },
  }

  return checkpointActions[checkpoint] ?? fallbackCheckpoint(checkpoint)
}
