import { DislikeOutlined, LikeOutlined } from '@ant-design/icons'
import { Select, Tooltip } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { INV_REVIEW_DISPOSITION, InvestigationInfo } from '@signifyd/http'
import { canSetCaseFeedback, useCaseFeedback } from 'core/hooks/useCaseFeedback'
import { CASE_CAPABILITIES, hasCapability } from 'core/utils/capabilities'
import styles from './FeedbackDropdown.less'
import FeedbackReasonModal from './FeedbackReasonModal'

interface Props {
  caseDetails: InvestigationInfo
}

const FeedbackDropdown: FC<Props> = ({ caseDetails }) => {
  const { t } = useTranslation()

  const { disabled, disabledReason } = canSetCaseFeedback(caseDetails)

  const [showReasonModal, setShowReasonModal] = useState(false)

  const { isLoading, mutate } = useCaseFeedback()

  const handleSelectChange = (
    merchantFeedback: INV_REVIEW_DISPOSITION
  ): void => {
    const needsFeedbackForGoodFlag = hasCapability(
      caseDetails.teamId,
      caseDetails.customerId,
      CASE_CAPABILITIES.REQUIRE_GOOD_FEEDBACK_REASON
    )

    if (
      needsFeedbackForGoodFlag &&
      merchantFeedback === INV_REVIEW_DISPOSITION.GOOD
    ) {
      setShowReasonModal(true)
    } else {
      mutate({
        caseDetails,
        feedback: merchantFeedback ?? INV_REVIEW_DISPOSITION.NONE,
      })
    }
  }

  const getCurrentMerchantFeedback = (): INV_REVIEW_DISPOSITION | undefined => {
    const { investigationReviewDisposition: disposition } = caseDetails

    if (!disposition || disposition === INV_REVIEW_DISPOSITION.NONE) {
      return undefined
    }

    return disposition
  }

  return (
    <>
      <FeedbackReasonModal
        caseDetails={caseDetails}
        showModal={showReasonModal}
        setShowModal={setShowReasonModal}
      />
      <Tooltip placement="bottom" title={disabledReason} mouseEnterDelay={0.5}>
        <Select<INV_REVIEW_DISPOSITION>
          onChange={handleSelectChange}
          disabled={disabled}
          loading={isLoading}
          allowClear
          value={getCurrentMerchantFeedback()}
          className={styles.dropdown}
          placeholder={t('caseActions.feedback.placeholder')}
          data-analytics-id="feedback-select"
          data-test-id="feedbackSelect"
          options={[
            {
              key: INV_REVIEW_DISPOSITION.GOOD,
              label: (
                <>
                  <LikeOutlined className={styles.icon} />{' '}
                  {t('caseActions.feedback.GOOD')}
                </>
              ),
              value: INV_REVIEW_DISPOSITION.GOOD,
              'data-analytics-id': 'feedback-selected-good',
              'data-test-id': 'feedbackSelectedGood',
            },
            {
              key: INV_REVIEW_DISPOSITION.BAD,
              label: (
                <>
                  <DislikeOutlined className={styles.icon} />{' '}
                  {t('caseActions.feedback.BAD')}
                </>
              ),
              value: INV_REVIEW_DISPOSITION.BAD,
              'data-analytics-id': 'feedback-selected-bad',
              'data-test-id': 'feedbackSelectedBad',
            },
          ]}
        />
      </Tooltip>
    </>
  )
}

export default FeedbackDropdown
