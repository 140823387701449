import { Tooltip } from 'antd'
import cx from 'classnames'
import { FC } from 'react'
import { colorMarengo, colorYonder } from '@signifyd/colors'
import { ExternalLinkThirdGen } from '@signifyd/components'
import styles from './ActionIconButton.less'
import { ActionIconButtonProps } from './ActionIconButton.types'

interface ActionIconLinkProps extends Omit<ActionIconButtonProps, 'onClick'> {
  link: string
}

const ActionIconLink: FC<ActionIconLinkProps> = ({
  Icon,
  tooltip,
  className,
  actionState = { disabled: false },
  isLoading = false,
  testId,
  iconColor = colorMarengo,
  link,
}) => {
  const disabled = isLoading || actionState.hidden || actionState.disabled

  return (
    <Tooltip
      title={!disabled ? tooltip : actionState.disabledReason}
      placement="bottom"
      // without this, tooltip persists after click if ui refreshes
      trigger={['hover', 'click']}
    >
      <ExternalLinkThirdGen
        url={link}
        data-test-id={testId}
        className={cx(styles.actionIcon, className)}
      >
        <Icon fill={disabled ? colorYonder : iconColor} />
      </ExternalLinkThirdGen>
    </Tooltip>
  )
}

export default ActionIconLink
