import { CaretRightOutlined } from '@ant-design/icons'
import { Table, Collapse } from 'antd'
import { ReactNode } from 'react'
import { Text, Space, i18nInstance } from '@signifyd/components'
import { AirlineTripDetail, Leg } from '@signifyd/http'
import FormattedDate from 'core/components/FormattedDate'
import NoInformation from 'core/components/NoInformation'
import OrderDetailsCollapsePanel from 'core/components/OrderDetailsCollapsePanel'
import { ColumnProps } from 'antd/lib/table'
import PanelHeader from './PanelHeader'

const { t } = i18nInstance

const formatDates = (portCode?: string, time?: string): ReactNode =>
  portCode &&
  time && (
    <span>
      {portCode} (<FormattedDate date={time} />)
    </span>
  )

const getLegColumns = (): Array<ColumnProps<Leg>> => [
  {
    title: t('airline.routeCode'),
    dataIndex: 'routeCode',
    key: 'routeCode',
    width: 120,
  },
  {
    title: t('airline.departure'),
    key: 'departurePortCode',
    render: ({ departurePortCode, departureTime }: Leg) =>
      formatDates(departurePortCode, departureTime),
    width: 210,
  },
  {
    title: t('airline.arrival'),
    key: 'arrivalPortCode',
    render: ({ arrivalPortCode, arrivalTime }: Leg) =>
      formatDates(arrivalPortCode, arrivalTime),
  },
]

const AirlineTripDetails = ({
  travelData,
}: {
  travelData?: Array<AirlineTripDetail>
}): JSX.Element => {
  if (!travelData?.length) {
    return (
      <div>
        <Space size="md" />
        <Text size="md" weight="semibold">
          {t('airline.tripDetails')}
        </Text>
        <Space size="sm" />
        <NoInformation />
      </div>
    )
  }

  return (
    <div>
      <Space size="md" />
      <Text size="md" weight="semibold">
        {t('airline.tripDetails')}
      </Text>
      <Space size="sm" />
      <Collapse
        bordered={false}
        style={{ background: 'none' }}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            style={{ fontSize: '16px' }}
            data-test-id="airlineTripAccordion"
            data-analytics-id={`${
              isActive ? 'expand' : 'close'
            }-airline-trip-accordion`}
            rotate={isActive ? 90 : 0}
          />
        )}
        destroyInactivePanel
      >
        {travelData.map((trip) => (
          <OrderDetailsCollapsePanel
            header={PanelHeader(trip)}
            style={{
              padding: '12px 0px 0px 0px',
            }}
            key={`${trip.departurePortCode}-${trip.arrivalPortCode}`}
          >
            <Table
              dataSource={trip.legs}
              columns={getLegColumns()}
              pagination={false}
              rowKey="routeCode"
            />
          </OrderDetailsCollapsePanel>
        ))}
      </Collapse>
    </div>
  )
}

export default AirlineTripDetails
