import { useEffect } from 'react'
import { useStoreState } from 'store'
import { getAppUrls } from '@signifyd/components'
import { LANDING_PAGES, USER_FEATURE } from '@signifyd/http'

const HOMEPAGE_REDIRECT = 'homepage_redirect'

const useHomepageRedirect = (): void => {
  const {
    location: { search },
  } = window
  const { currentUser, hasFeatureFlag } = useStoreState((state) => state.user)

  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const homepageRedirect = searchParams.get(HOMEPAGE_REDIRECT)

    if (currentUser && homepageRedirect === 'true') {
      searchParams.delete(HOMEPAGE_REDIRECT)

      if (currentUser.uiState?.homepage) {
        const { homepage } = currentUser.uiState

        if (
          homepage === LANDING_PAGES.INSIGHTS &&
          hasFeatureFlag(USER_FEATURE.INSIGHTS)
        ) {
          window.location.replace(getAppUrls().insightsURL)
        }
      }
    }
  }, [search, currentUser, hasFeatureFlag])
}

export default useHomepageRedirect
