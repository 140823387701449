import { Button } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TicketDetailsWithCurrency } from 'pages/CaseReviewPage/containers/OrderDetails/components/EventDetails/types'
import { getTicketDetails } from 'pages/CaseReviewPage/containers/OrderDetails/components/EventDetails/utils'
import styles from './TicketDetails.less'

interface TicketDetailsProps {
  items: Array<TicketDetailsWithCurrency | null>
}

const TicketDetails: FC<TicketDetailsProps> = ({ items }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'eventTicketing.viewBtn',
  })

  const [isExpanded, setIsExpanded] = useState(false)
  const [firstItemValue] = items

  if (!firstItemValue) {
    return null
  }

  const toggleExpanded = (): void => {
    setIsExpanded(!isExpanded)
  }

  return (
    <>
      {getTicketDetails(firstItemValue)}

      {items.length > 1 && (
        <div data-test-id="viewMoreToggle">
          {isExpanded && (
            <>
              {items.slice(1).map((value, index) => (
                <div key={index}>{value && getTicketDetails(value)}</div>
              ))}
            </>
          )}
          <Button
            type="link"
            onClick={toggleExpanded}
            className={styles.itemToggleBtn}
          >
            {isExpanded ? t('viewLess') : t('viewMore')}
          </Button>
        </div>
      )}
    </>
  )
}

export default TicketDetails
