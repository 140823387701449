import {
  CreditCardOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  LaptopOutlined,
  MailOutlined,
  MobileOutlined,
} from '@ant-design/icons'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { Row } from 'antd'
import moment from 'moment-timezone'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'store'
import { Spinner } from '@signifyd/components'
import {
  getInvestigationCounts,
  InvestigationCounts,
  InvestigationInfo,
  UiState,
  USER_FEATURE,
} from '@signifyd/http'
import { DATE_FORMAT } from 'core/constants'
import useHasSecondaryMatches from 'core/hooks/useHasSecondaryMatches'
import isEventTicketing from 'core/utils/isEventTicketing'
import { isTransportAirline } from 'core/utils/isTransportAirline/isTransportAirline'
import SummaryPanel from '../SummaryPanel'
import SummaryPanelActions from '../SummaryPanelActions'
import styles from './CaseIntelligencePanel.less'

interface Props {
  isLarge: boolean
  caseDetails: InvestigationInfo
  hasPowersearch?: boolean
}

const useGetCounts = (
  investigationId: number,
  timeZone?: UiState['timeZone'],
  hasPowersearch?: boolean
): UseQueryResult<InvestigationCounts> => {
  return useQuery({
    queryKey: ['counts', investigationId],
    queryFn: async () => {
      const now = moment
        .utc()
        .tz(timeZone?.name || moment.tz.guess())
        .format(DATE_FORMAT.apiDate)

      const twoYearsAgo = moment
        .utc(moment().subtract(2, 'years'))
        .tz(timeZone?.name || moment.tz.guess())
        .format(DATE_FORMAT.apiDate)

      const { data } = await getInvestigationCounts(
        investigationId,
        now,
        twoYearsAgo
      )

      return data
    },
    enabled: !!investigationId && !!hasPowersearch,
  })
}

export const CaseIntelligencePanel: FC<Props> = ({ isLarge, caseDetails }) => {
  const { t } = useTranslation()
  const hasSecondaryMatches = useHasSecondaryMatches()

  const currentUser = useStoreState((state) => state.user.currentUser)

  const hasPowersearch = !!currentUser?.features[USER_FEATURE.POWER_SEARCH]

  const analysis = useStoreState((state) => state.currentCase.analysisData)
  const transportIsAirline = isTransportAirline(caseDetails.products)
  const isEventTicketingOrder = isEventTicketing(caseDetails.products)

  const { data, isFetching } = useGetCounts(
    caseDetails.investigationId,
    currentUser?.uiState?.timeZone,
    hasPowersearch
  )

  if (isFetching) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    )
  }

  const counts = data?.investigationCounts
  const showIntelligenceIcons =
    data?.investigationCounts && hasPowersearch && !hasSecondaryMatches

  const AddressAction = (
    <SummaryPanelActions
      countAttrs={counts?.billingAddressCount}
      investigationId={caseDetails.investigationId}
      key="billingAddressCount"
      type="billingAddress"
      Icon={CreditCardOutlined}
    />
  )

  const addressIcons = transportIsAirline
    ? [AddressAction]
    : [
        AddressAction,
        <SummaryPanelActions
          countAttrs={counts?.deliveryAddressCount}
          investigationId={caseDetails.investigationId}
          key="deliveryAddressCount"
          type="deliveryAddress"
          Icon={HomeOutlined}
        />,
      ]

  return (
    <Row justify="space-between" gutter={[16, 8]}>
      <SummaryPanel
        isLarge={isLarge}
        title={t('caseIntelligence.address')}
        analysis={analysis?.locationAnalysis}
        actions={showIntelligenceIcons ? addressIcons : undefined}
      />
      <SummaryPanel
        isLarge={isLarge}
        title={t('caseIntelligence.device')}
        analysis={analysis?.deviceAnalysis}
        actions={
          showIntelligenceIcons
            ? [
                <SummaryPanelActions
                  countAttrs={counts?.ipAddressCount}
                  investigationId={caseDetails.investigationId}
                  key="ipAddressCount"
                  type="ipAddress"
                  Icon={EnvironmentOutlined}
                />,
                <SummaryPanelActions
                  countAttrs={counts?.deviceIdCount}
                  investigationId={caseDetails.investigationId}
                  key="deviceIdCount"
                  type="deviceId"
                  Icon={LaptopOutlined}
                />,
              ]
            : undefined
        }
      />
      <SummaryPanel
        isLarge={isLarge}
        title={t('caseIntelligence.email')}
        analysis={analysis?.emailAnalysis}
        actions={
          showIntelligenceIcons
            ? [
                <SummaryPanelActions
                  countAttrs={counts?.confirmationPhoneCount}
                  investigationId={caseDetails.investigationId}
                  key="confirmationPhoneCount"
                  type="confirmationPhone"
                  Icon={MobileOutlined}
                />,
                <SummaryPanelActions
                  countAttrs={counts?.confirmationEmailCount}
                  investigationId={caseDetails.investigationId}
                  key="confirmationEmailCount"
                  type="confirmationEmail"
                  Icon={MailOutlined}
                />,
              ]
            : undefined
        }
      />
      {(transportIsAirline || isEventTicketingOrder) && (
        <SummaryPanel
          isLarge={isLarge}
          title={t('caseIntelligence.time')}
          analysis={analysis?.timeAnalysis}
        />
      )}
    </Row>
  )
}

export default CaseIntelligencePanel
