import { Flex, Typography } from 'antd'
import { FC } from 'react'
import { colorYonder, colorSlate } from '@signifyd/colors'
import {
  T4,
  Space,
  SearchResultsIcon,
  useIsThirdGen,
} from '@signifyd/components'
import styles from './NotFound.less'

interface Props {
  title: string
}

const { Title } = Typography

export const NotFound: FC<Props> = ({ title, children }) => {
  const isThirdGen = useIsThirdGen()

  if (!isThirdGen) {
    return (
      <div className={styles.wrapper}>
        <SearchResultsIcon style={{ fontSize: 80 }} fill={colorYonder} />
        <Space size="lg" />
        <T4 strong>{title}</T4>
        <div className={styles.messageWrapper}>{children}</div>
      </div>
    )
  }

  return (
    <Flex
      flex={1}
      vertical
      align="center"
      justify="center"
      className={styles.wrapper}
    >
      <SearchResultsIcon className={styles.logo} fill={colorSlate} />
      <Space size="lg" />
      <Title level={1}>{title}</Title>
      <Space size="sm" />
      <div>{children}</div>
    </Flex>
  )
}

export default NotFound
