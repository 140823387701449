import { Descriptions } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Membership, PREDICTION_LIST_TYPE } from '@signifyd/http'
import CopyWrapper from 'core/components/CopyWrapper'
import OrderAttributeLists from 'core/components/OrderAttributeLists'
import { defaultDescriptionProps } from 'core/constants'
import SeederLabel from 'pages/CaseReviewPage/components/SeederLabel'
import { DescriptionsItemType } from 'antd/es/descriptions'

interface Props {
  membership: Membership
}

export const MembershipDetailsThirdGen: FC<Props> = ({ membership }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.caseReview.membershipDetails',
  })
  const { membershipId, phoneNumber, emailAddress, membershipName } = membership

  const items = [
    membershipId && {
      label: (
        <SeederLabel
          label={t('membershipId')}
          dataTestId="membershipIdSeederLabel"
        />
      ),
      children: (
        <>
          <CopyWrapper text={membershipId}>{membershipId}</CopyWrapper>
          <OrderAttributeLists
            type={PREDICTION_LIST_TYPE.MEMBERSHIP_ID}
            value={membershipId}
            analyticsId="membership-ID"
          />
        </>
      ),
    },
    phoneNumber && {
      label: (
        <SeederLabel
          label={t('phoneNumber')}
          dataTestId="membershipPhoneSeederLabel"
        />
      ),
      children: <CopyWrapper text={phoneNumber}>{phoneNumber}</CopyWrapper>,
    },
    emailAddress && {
      label: (
        <SeederLabel
          label={t('email')}
          dataTestId="membershipEmailSeederLabel"
        />
      ),
      children: <CopyWrapper text={emailAddress}>{emailAddress}</CopyWrapper>,
    },
    membershipName && {
      label: (
        <SeederLabel label={t('name')} dataTestId="membershipNameSeederLabel" />
      ),
      children: (
        <CopyWrapper text={membershipName}>{membershipName}</CopyWrapper>
      ),
    },
  ].filter((item) => !!item) as Array<DescriptionsItemType>

  return (
    <Descriptions
      title={t('membershipInformation')}
      items={items}
      {...defaultDescriptionProps}
    />
  )
}

export default MembershipDetailsThirdGen
