import { message } from 'antd'
import { action, thunk } from 'easy-peasy'
import { i18nInstance } from '@signifyd/components'
import { getCaseOrder } from '@signifyd/http'
import { CaseOrderModel } from './types/case.order.types'

const caseOrderModel: CaseOrderModel = {
  // State
  order: null,

  // Getters / Setters
  setCaseOrder: action((state, payload) => {
    state.order = payload
  }),

  clearCaseOrder: action((state) => {
    state.order = null
  }),

  // Http Stuff
  getCaseOrder: thunk((actions, caseId) => {
    return getCaseOrder(caseId)
      .then(({ data: orderData }) => {
        actions.setCaseOrder(orderData)
      })
      .catch(({ response }) => {
        if (response.status !== 404) {
          message.error(i18nInstance.t('store.case.getCaseOrderFailure'))
        }
      })
  }),
}

export default caseOrderModel
