import { Modal } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AttachFilesForUploadHook,
  NoteTextAreaThirdGen,
} from '@signifyd/components'
import { COMMON_MODAL_PROPS } from 'core/constants'

interface Props {
  visible: boolean
  maxLength: number
  isSubmitDisabled: boolean
  note: string
  attachedFilesData: AttachFilesForUploadHook
  onChange: (value: string) => void
  onCancel: () => void
  onSubmit: () => void
}

export const NoteModal: FC<Props> = ({
  visible,
  note,
  onChange,
  onCancel,
  onSubmit,
  maxLength,
  isSubmitDisabled,
  attachedFilesData,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      {...COMMON_MODAL_PROPS}
      title={
        <span data-test-id="addNotesModal">{t('caseActivity.addNotes')}</span>
      }
      open={visible}
      okText={t('caseActivity.addNotes')}
      cancelText={t('caseActivity.cancelButton')}
      onCancel={onCancel}
      onOk={onSubmit}
      okButtonProps={{ disabled: isSubmitDisabled, size: 'large' }}
      cancelButtonProps={{ type: 'link', size: 'large' }}
    >
      <NoteTextAreaThirdGen
        placeholder={t('caseActivity.addNotesPlaceholder')}
        note={note}
        onChange={onChange}
        maxLength={maxLength}
        fileAttacher={attachedFilesData}
        attachFileButtonAnalyticsId="case-not-modal-attach-file-button"
        textAreaAnalyticsId="case-not-modal-text-area"
      />
    </Modal>
  )
}

export default NoteModal
