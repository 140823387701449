import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { getInvestigationSearchContext } from '@signifyd/http'

interface SearchContextResponse {
  availableFields: Array<string>
  hasEventTicketingOrders: boolean
}

const useUserHasEventTicketingOrders = (
  teams: Array<number>,
  isAdmin: boolean
): UseQueryResult<SearchContextResponse> => {
  return useQuery<SearchContextResponse>(
    ['searchContext', teams, isAdmin],
    async () => {
      const {
        data: { availableFields },
      } = await getInvestigationSearchContext({ teamIds: teams })

      const hasEventTicketingOrders = availableFields.some(
        (column: string) => column === 'eventType' || column === 'venueName'
      )

      return {
        availableFields,
        hasEventTicketingOrders: isAdmin ? true : hasEventTicketingOrders,
      }
    }
  )
}

export default useUserHasEventTicketingOrders
