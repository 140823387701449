import { Form } from '@ant-design/compatible'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { Row, Col, Button, message, Spin } from 'antd'
import { FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { T1, Text } from '@signifyd/components'
import { NewCaseFormSchemaType, createNewCase } from '@signifyd/http'
import PageWrapper from 'core/components/PageWrapper'
import TeamSelectField from './components/Fields/TeamSelectField'
import {
  BillingCard,
  CustomerCard,
  OrderCard,
  OrderHistoryCard,
  ShippingCard,
} from './components/FormCards'
import {
  NewCaseFormDefaultValues,
  NewCaseFormValidateSchema,
} from './NewCaseFormValidation'

import styles from './NewCasePage.less'

export const NewCasePage: FC = () => {
  const { t } = useTranslation()
  const [sameShipping, setSameShipping] = useState<boolean>(false)

  const {
    control,
    clearErrors,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    resetField,
  } = useForm<NewCaseFormSchemaType>({
    mode: 'onChange',
    resolver: zodResolver(NewCaseFormValidateSchema(sameShipping)),
    defaultValues: NewCaseFormDefaultValues,
  })

  const createNewCaseMutation = useMutation({
    mutationFn: async (data: NewCaseFormSchemaType) => {
      await createNewCase(data)
    },
    onSuccess: () => {
      message.success(t('pages.newCase.createSuccess'))
    },
    onError: (e) => {
      message.error(t('pages.newCase.createError'))
      console.error(e)
    },
  })

  const onSubmit: SubmitHandler<NewCaseFormSchemaType> = (data) => {
    createNewCaseMutation.mutate(data)
  }

  return (
    <PageWrapper>
      <Form layout="vertical" className={styles.form}>
        <Row justify="start">
          <Col>
            <T1>{t('pages.newCase.title')}</T1>
            <Text size="lg" block>
              {t('pages.newCase.intro')}
            </Text>
            <Text size="sm">
              {t('pages.newCase.requiredMessage')}
              <span className={styles.required}>*</span>
            </Text>
          </Col>
        </Row>

        <Spin
          spinning={createNewCaseMutation.isLoading}
          tip={t('pages.newCase.creatingCase')}
        >
          <Row justify="end">
            <TeamSelectField
              fieldName="team"
              control={control}
              errors={errors}
            />
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <CustomerCard control={control} errors={errors} />
              <OrderCard control={control} errors={errors} />
              <OrderHistoryCard control={control} errors={errors} />
            </Col>

            <Col span={12}>
              <BillingCard control={control} errors={errors} />
              <ShippingCard
                sameShipping={sameShipping}
                setSameShipping={setSameShipping}
                watch={watch}
                setValue={setValue}
                resetField={resetField}
                control={control}
                errors={errors}
                clearErrors={clearErrors}
              />
            </Col>
          </Row>

          <Row justify="center">
            <Button
              type="primary"
              onClick={handleSubmit(onSubmit)}
              data-analytics-id="create-case-button"
              data-test-id="createCaseButton"
            >
              {t('pages.newCase.createCase')}
            </Button>
          </Row>
        </Spin>
      </Form>
    </PageWrapper>
  )
}

export default NewCasePage
