export default {
  tabTitle: 'Actions',
  actionIcons: {
    thumbsUp: {
      tooltip: 'Order review flag good',
    },
    thumbsDown: {
      tooltip: 'Order review flag bad',
    },
  },
  headers: {
    feedback: 'Order review flag',
    order: 'Actions',
  },
  feedback: {
    placeholder: 'None selected',
    GOOD: 'Good',
    BAD: 'Bad',
  },
  feedbackReasons: {
    UNSET: 'None selected',
    FRAUDULENT: 'Bad',
    GOOD: 'Good',
  },
  feedbackNote: {
    info: 'Add note to confirm this order as ',
    title: 'Order review flag',
    okText: 'Mark as good',
  },
  updateAddress: {
    updateAddressButton: 'Update address',
    updateAddress: 'Update {{value}} address',
    modalTitle: 'Update address',
    cancelText: 'Back',
    cancelText_select: 'Cancel',
    selectAll: 'Select all addresses you want to update',
    helpText_one:
      'For unshipped orders, you can update this address one time. Once updated, this order will be sent back to Signifyd for review.',
    helpText_other:
      'For unshipped orders, you can update these addresses one time. Once updated, this order will be sent back to Signifyd for review.',
    confirmation_one: 'You are about to update 1 address',
    confirmation_other: 'You are about to update {{count}} addresses',
    confirmationTitle: 'Confirm address updates',
    formError: 'Please complete the required fields before submitting',
    noTeamId:
      'This case does not contain a valid team ID and cannot have its address updated',
    recipient: 'Recipient',
    updateTitle: '{{fullName}} address',
    updateTitleWithShipment: '{{fullName}} address - {{shipment}}',
    okText: 'Update',
    okText_select_one: 'Update {{count}} address',
    okText_select_other: 'Update {{count}} addresses',
    okText_confirm: 'Confirm',
    originalAddress: 'Original address',
    updatedAddress: 'Updated address',
    method: 'Shipping method',
    notAllowed: "Oops — you're not allowed to perform this action",
    name: {
      label: 'Delivery recipient',
      placeholder: 'Full name',
      error: 'Please enter recipient name',
    },
    unit: {
      label: 'Unit or suite',
      placeholder: 'Enter unit or suite number',
      error: 'Please enter unit or suite number',
    },
    street: {
      label: 'Street address',
      placeholder: 'Enter street address',
      error: 'Please enter street address',
    },
    city: {
      label: 'City',
      placeholder: 'Enter city',
      error: 'Please enter city',
    },
    state: {
      label: 'State',
      placeholder: 'Enter state',
      error: 'Please enter state',
    },
    zip: {
      label: 'Zip code',
      placeholder: 'Enter zip code',
      error: 'Please enter zip code',
    },
    country: {
      label: 'Country',
      placeholder: 'Select',
      error: 'Please select country',
    },
  },
  reimbursement: {
    modalTitle: 'Request chargeback reimbursement',
    helpText: `Complete form to request reimbursement for an eligible chargeback. <helpArticleLink>Learn about what's covered</helpArticleLink>.<br />
     For buy online pick up in-store orders, enter "BOPIS" in the tracking number field.
     For orders fulfilled digitally, enter "Digital Goods" in the tracking number field.`,
    okText: 'Update',
    trackingNumber: {
      label: 'Tracking number',
      placeholder: 'Enter tracking number, BOPIS or Digital Goods',
      error: 'Please enter tracking number, BOPIS or Digital Goods',
    },
    shipper: {
      label: 'Carrier',
      placeholder: 'Select',
      error: 'Please select a carrier',
    },
    chargebackRefId: {
      label: 'Chargeback case number',
      placeholder: 'Enter reference',
      helpText:
        'Unique case ID assigned by the processor to each chargeback in order to effectively monitor its progress within the chargeback process',
      error: 'Please enter chargeback case number',
    },
    issuerReportedDate: {
      label: 'Chargeback date',
      placeholder: 'M/D/YYYY',
      helpText:
        "Actual date when the chargeback was received as indicated in the chargeback document or the chargeback case within the processor's portal",
      error: 'Please select chargeback date',
    },
    processorReasonDescription: {
      label: 'Chargeback reason',
      placeholder: 'Select',
      helpText:
        'Referred to as the "Reason", "Chargeback Description" or "Chargeback Code" on the chargeback notice',
      error: 'Please select chargeback reason',
    },
    chargebackAmount: {
      label: 'Chargeback amount',
      helpText:
        'Referred to as the "Chargeback Amount" or "Disputed Amount" on the chargeback notice',
      error: 'Please enter chargeback amount',
    },
    chargebackFees: {
      label: 'Chargeback fees',
      helpText:
        'Fees attached to each chargeback covering administrative costs of processing a chargeback. There is a $30 limit on chargeback fees.',
      error: 'Please enter chargeback fees',
    },
    chargebackNotice: {
      label: 'Chargeback notice',
      helpTitle: 'Click or drag file to here to upload',
      helpText:
        'To submit chargeback claim, provide proof of shipment documentation and chargeback notification document.',
      error: 'Please include required documentation',
    },
    comments: {
      label: 'Comments',
    },
  },
  submitGuarantee: {
    text: 'Submit for guarantee',
    tooltip: 'Submit for guarantee',
  },
  reSubmitGuarantee: {
    text: 'Resubmit for guarantee',
    tooltip: 'Resubmit for guarantee',
    modalTitle: 'Why are you requesting that we re-review this case?',
    helpText: 'Please include as much detail as possible to help our analysts',
    additionalFiles: 'Additional files',
    uploadText: 'Attach file(s)',
    okText: 'Resubmit for review',
    reReviewNoteWrapper: '<strong>Case re-review reason </strong><br/>{{note}}',
    fileDiv:
      '<div><span><a title="{{name}}" target="_blank noopener noreferrer" href="{{-url}}">{{name}}</a></span></div>',
    fileWrapper:
      '<div class="note-with-files files-wrap"><strong>File attached:</strong>{{-fileDiv}}</div>',
  },
  submittedOn: 'Submitted on',
  dateTimeFormat: 'YYYY-MM-DD [at] HH:MM',
  cancelGuarantee: {
    text: 'Cancel guarantee',
    tooltip: 'Cancel guarantee request',
    helpText:
      'If you no longer need an order guaranteed you can choose to cancel guarantee. You will be not charged for a canceled guarantee.',
    cancelText: 'Keep guarantee',
    okText: 'Cancel guarantee',
    modalTitle: 'Cancel guarantee?',
    apiSuccess: 'Success! The guarantee has been canceled for this order.',
    apiFailure: 'Apologies — try canceling the guarantee again',
    validation: {
      tooOld:
        "Order can't be canceled because it was reviewed more than {{days}} days ago",
      hasClaim: "Order can't be canceled because a claim is tied to the order",
      notSubmitted: 'Only orders submitted for guarantee can be canceled',
      alreadyCanceled: 'Guarantee on this order has already been canceled',
      declined: "Guarantee can't be canceled for a declined order",
      notAllowed: "Oops — you're not allowed to perform this action",
    },
  },
  closeCase: 'Close case',
  openCase: 'Open case',
  claimReimbursement: 'Request reimbursement',
}
