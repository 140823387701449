import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { message } from 'antd'
import axios, { AxiosPromise } from 'axios'
import { useStoreActions } from 'store'
import { i18nInstance } from '@signifyd/components'
import {
  CaseNote,
  createCaseNote,
  createChargeback,
  CreateChargebackResponse,
  CreateClaimError,
} from '@signifyd/http'
import {
  ACTION_CONTEXT,
  ReimbursementUpdate,
} from 'core/hooks/caseActions/useClaimReimbursement/types'
import { parseAPIErrorMsg } from 'core/utils/parseAPIErrorMsg'

export interface CreateClaimsPayload {
  caseId: number
  reimbursementDetails: ReimbursementUpdate
}

const claimTranslationPrefix = 'store.claim'

export const useCreateClaim = (
  context: ACTION_CONTEXT
): UseMutationResult<number, CreateClaimError, CreateClaimsPayload> => {
  const { refreshCurrentCase } = useStoreActions((store) => store.currentCase)
  const { getAndUpdateSearchResult } = useStoreActions((store) => store.search)

  const addNoteCall = (
    caseId: number,
    reimbursementDetails: ReimbursementUpdate
  ): AxiosPromise<CaseNote> => {
    let note = `Chargeback Submitted\nTracking Number: ${reimbursementDetails.trackingNumber}\nReason: ${reimbursementDetails.processorReasonDescription}\nAmount: ${reimbursementDetails.chargebackAmount.amount}`

    if (reimbursementDetails.comments) {
      note += `\nComments: ${reimbursementDetails.comments}`
    }

    return createCaseNote(caseId, note)
  }

  const createClaimCall = (
    caseId: number,
    reimbursementDetails: ReimbursementUpdate
  ): AxiosPromise<CreateChargebackResponse> =>
    createChargeback(caseId, {
      chargebacks: [
        {
          amount: {
            amount: reimbursementDetails.chargebackAmount?.amount ?? 0,
            currencyCode:
              reimbursementDetails.chargebackAmount?.currencyCode ?? '',
          },
          chargebackFees: {
            amount: reimbursementDetails.chargebackFees?.amount ?? 0,
            currencyCode:
              reimbursementDetails.chargebackFees?.currencyCode ?? '',
          },
          chargebackRefId: reimbursementDetails.chargebackRefId,
          issuerReportedDate: reimbursementDetails.issuerReportedDate,
          investigationId: caseId,
          signifydCreateSource: 'CUSTOMER_CONSOLE',
          reason: {
            processorReasonDescription:
              reimbursementDetails.processorReasonDescription,
          },
        },
      ],
      trackingInfo: [
        {
          trackingNumber: reimbursementDetails.trackingNumber,
          shipper: reimbursementDetails.shipper,
        },
      ],
    })

  return useMutation({
    mutationFn: async ({ caseId, reimbursementDetails }) => {
      try {
        await createClaimCall(caseId, reimbursementDetails)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 400) {
            message.error(parseAPIErrorMsg(error))
          }

          return Promise.reject(error.response?.data)
        }

        message.error(i18nInstance.t(`${claimTranslationPrefix}.apiFailure`))
      }

      try {
        await addNoteCall(caseId, reimbursementDetails)
      } catch {
        message.error(i18nInstance.t(`${claimTranslationPrefix}.apiFailure`))
      }

      message.success(i18nInstance.t(`${claimTranslationPrefix}.apiSuccess`))

      return caseId
    },
    onSuccess(caseId) {
      if (context === ACTION_CONTEXT.CaseReview) {
        refreshCurrentCase()
      }

      if (context === ACTION_CONTEXT.CaseSearch) {
        getAndUpdateSearchResult(caseId)
      }
    },
  })
}
