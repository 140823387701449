import { partition } from 'lodash'
import {
  AncillaryPurchase,
  InvestigationInfo,
  Product,
  Shipment,
} from '@signifyd/http'
import { toSentenceCase, toTitleCase } from '@signifyd/utils'
import { isTransportAirline } from 'core/utils/isTransportAirline/isTransportAirline'
import { OrderProduct } from './OrderSummary'

export const getMatchingShipmentMethod = (
  product: Product,
  shipments: Array<Shipment>
): string | undefined => {
  if (!product.shipmentId) {
    return undefined
  }

  // Return first matching shipment method. Product:Shipment should be a 1:1 relationship.
  return toTitleCase(
    shipments.find(
      (shipment) =>
        product.shipmentId === shipment.shipmentId && shipment.shippingMethod
    )?.shippingMethod
  )
}

export const getAncillaryPurchases = (
  investigation: InvestigationInfo
): Array<AncillaryPurchase> => {
  const purchases = investigation.products
    .map((product) => product.travel)
    .flatMap((travel) => travel?.passengers)
    .flatMap((passenger) => passenger?.ancillaryPurchases)
    .filter(Boolean) as Array<AncillaryPurchase>

  const ancillaryPurchases = purchases.reduce(
    (acc: Record<string, AncillaryPurchase>, ancillaryPurchase) => {
      const itemKey = `${ancillaryPurchase?.ancillaryPurchaseType}-${ancillaryPurchase?.itemPrice}`
      const ancillaryPurchaseQuantity = ancillaryPurchase.itemQuantity ?? 1

      const currentAncillary = acc[itemKey]

      if (currentAncillary?.itemQuantity) {
        currentAncillary.itemQuantity += ancillaryPurchaseQuantity
      } else {
        acc[itemKey] = {
          itemName: toSentenceCase(ancillaryPurchase.ancillaryPurchaseType),
          itemPrice: ancillaryPurchase.itemPrice,
          itemQuantity: ancillaryPurchaseQuantity,
        }
      }

      return acc
    },
    {}
  )

  return Object.values(ancillaryPurchases)
}

const formatToOrderProductDetails = (
  products: Array<AncillaryPurchase | Product>,
  shipments: Array<Shipment>
): Array<OrderProduct> => {
  return products.map((product) => {
    const { itemPrice, itemName, itemQuantity } = product

    const shippingMethod =
      'shipmentId' in product
        ? getMatchingShipmentMethod(product as Product, shipments)
        : undefined

    const url = 'itemUrl' in product ? product.itemUrl : undefined

    return {
      itemUrl: url,
      itemPrice,
      itemQuantity,
      itemName,
      shippingMethod,
    }
  })
}

export const getSortedProducts = (
  caseDetails: InvestigationInfo,
  descendingOrder: boolean
): Array<OrderProduct> => {
  const { products, shipments } = caseDetails

  const isAirlineOrder = isTransportAirline(products)

  const ancillaryPurchases = isAirlineOrder
    ? getAncillaryPurchases(caseDetails)
    : []

  const combinedProducts = [...products, ...ancillaryPurchases]

  const [emptyPriceProducts, priceProducts] = partition(
    combinedProducts,
    (product: AncillaryPurchase | Product) => !product.itemPrice
  )

  const sortedProducts = [...priceProducts].sort((a, b) => {
    const firstItem = a?.itemPrice ? +a.itemPrice : 0
    const secondItem = b?.itemPrice ? +b.itemPrice : 0

    return descendingOrder ? secondItem - firstItem : firstItem - secondItem
  })

  return formatToOrderProductDetails(
    [...sortedProducts, ...emptyPriceProducts],
    shipments
  )
}
