import { i18nInstance } from '@signifyd/components'
import { googleSearchLink } from '../DeepLinkConstants'
import { DeepLinkFactory, MENU_ICON } from '../DeepLinkDropdown.types'

const getDeepLinksAddress: DeepLinkFactory = ({ seller: { address } }) => [
  {
    icon: MENU_ICON.GoogleSearchIcon,
    text: i18nInstance.t(
      'pages.caseReview.sellerDetails.deepLinks.addressSearch'
    ),
    url: googleSearchLink + address.fullAddress,
  },
  {
    icon: MENU_ICON.GoogleMapIcon,
    text: i18nInstance.t(
      'pages.caseReview.sellerDetails.deepLinks.addressMapView'
    ),
    url: `https://maps.google.com/maps?z=20&t=k&q=${address.fullAddress},${address.countryCode}@${address.latitude}+${address.longitude}`,
  },
  {
    icon: MENU_ICON.WhitepagesIcon,
    text: i18nInstance.t(
      'pages.caseReview.sellerDetails.deepLinks.addressSearch'
    ),
    url: `http://www.whitepages.com/search/FindNearby?utf8=true&street=${address.streetAddress}&where=${address.city} ${address.provinceCode}`,
  },
  {
    icon: MENU_ICON.EkataIcon,
    text: i18nInstance.t(
      'pages.caseReview.sellerDetails.deepLinks.addressSearch'
    ),
    url: `https://app.ekata.com/addresses?street=${address.streetAddress}&where=${address.city} ${address.provinceCode}`,
  },
]

export default getDeepLinksAddress
