import { CloseSquareOutlined, FolderOpenOutlined } from '@ant-design/icons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Case, useIsThirdGen } from '@signifyd/components'
import { INV_CASE_STATUS, INV_STATUS, InvestigationInfo } from '@signifyd/http'
import { getEnableActionState } from 'core/hooks/caseActions/utils'
import { useUpdateCaseStatus } from 'core/queries/useUpdateCaseStatus'
import ActionButton from '../ActionButton'
import ActionIconButton from '../ActionIconButton/ActionIconButton'

interface Props {
  caseDetails: InvestigationInfo
}

export const CaseStatusButton: FC<Props> = ({
  caseDetails: { caseStatus, investigationId },
}) => {
  const { t } = useTranslation()
  const { isLoading, mutate: updateCaseStatus } = useUpdateCaseStatus()

  const isThirdGen = useIsThirdGen()

  const caseIsOpen = caseStatus === INV_CASE_STATUS.OPEN

  if (isThirdGen) {
    return (
      <ActionIconButton
        testId={`caseStatusButton-${caseIsOpen ? 'close' : 'open'}`}
        onClick={() => {
          updateCaseStatus({
            investigationId,
            payload: caseIsOpen ? INV_STATUS.DISMISSED : INV_STATUS.OPEN,
          })
        }}
        actionState={getEnableActionState()}
        isLoading={isLoading}
        Icon={Case}
        tooltip={
          caseIsOpen ? t('caseActions.closeCase') : t('caseActions.openCase')
        }
      />
    )
  }

  return (
    <ActionButton
      onClick={() => {
        updateCaseStatus({
          investigationId,
          payload:
            caseStatus === INV_CASE_STATUS.OPEN
              ? INV_STATUS.DISMISSED
              : INV_STATUS.OPEN,
        })
      }}
      actionState={getEnableActionState()}
      isLoading={isLoading}
      icon={
        caseStatus === INV_CASE_STATUS.OPEN ? (
          <CloseSquareOutlined />
        ) : (
          <FolderOpenOutlined />
        )
      }
      text={
        caseStatus === INV_CASE_STATUS.OPEN
          ? t('caseActions.closeCase')
          : t('caseActions.openCase')
      }
    />
  )
}

export default CaseStatusButton
