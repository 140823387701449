import { Card } from 'antd'
import { FC, ReactNode } from 'react'
import { T4 } from '@signifyd/components'

export interface Props {
  title: string
  children: ReactNode
  extra?: ReactNode
}

export const FormCard: FC<Props> = ({ title, children, extra }) => (
  <Card
    title={<T4>{title}</T4>}
    style={{ margin: '15px 0' }}
    styles={{
      header: { margin: '-14px -10px' },
      body: { margin: '0 -15px', paddingBottom: '8px' },
    }}
    extra={extra}
  >
    {children}
  </Card>
)

export default FormCard
