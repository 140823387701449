import { Descriptions } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PhysicalAddress } from '@signifyd/http'
import CopyWrapper from 'core/components/CopyWrapper'
import { useToggle } from 'core/utils/useToggle'
import ShowAllButton from 'pages/CaseReviewPage/containers/OrderDetails/components/CaseDetails/Locations/ShowAllButton'
import { getShowHideItems } from 'pages/CaseReviewPage/containers/OrderDetails/OrderDetails.utils'
import DeepLinkDropdown from '../components/DeepLinkDropdown'
import {
  MENU_SECTION,
  MENU_SECTION_TYPE,
} from '../components/DeepLinkDropdown/DeepLinkDropdown.types'

const { Item: DescriptionItem } = Descriptions

interface Props {
  eventTicketingAddresses: Array<PhysicalAddress>
}

const EventAddresses: FC<Props> = ({ eventTicketingAddresses }) => {
  const { t } = useTranslation()

  const { value: showAll, toggle: toggleShowAll } = useToggle()

  const { maxCountItems, itemsToShow } = getShowHideItems({
    items: eventTicketingAddresses,
    showAll,
  })

  return (
    <Descriptions colon={false} size="small">
      {itemsToShow.map((address, index) => (
        <DescriptionItem
          key={address.fullAddress}
          label={
            index === 0 &&
            t('pages.caseReview.details.eventAddress', {
              count: eventTicketingAddresses.length,
            })
          }
        >
          <CopyWrapper text={address.fullAddress}>
            <DeepLinkDropdown
              section={MENU_SECTION.eventTicketing}
              type={MENU_SECTION_TYPE.eventAddress}
              value={address.fullAddress ?? ''}
            >
              {address.fullAddress}
            </DeepLinkDropdown>
          </CopyWrapper>
        </DescriptionItem>
      ))}
      {!!maxCountItems && (
        <DescriptionItem>
          <ShowAllButton
            showAll={showAll}
            toggleShowAll={toggleShowAll}
            dataAnalyticsId={`${
              showAll ? 'show-all' : 'show-less'
            }-event-ticketing-addresses`}
          />
        </DescriptionItem>
      )}
    </Descriptions>
  )
}

export default EventAddresses
