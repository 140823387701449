import { Flex } from 'antd'
import { FC } from 'react'
import { spacingMD } from '@signifyd/ant'
import SubTotal from './SubTotal'

export interface SubTotal {
  value: number | string
  title: JSX.Element
  onClick: () => void
  isSelected: boolean
  icon?: JSX.Element
  id: string
}

interface Props {
  subTotals: Array<SubTotal>
}

const AggregateContainer: FC<Props> = ({ subTotals }) => (
  <Flex gap={spacingMD}>
    {subTotals.map(({ icon, title, value, onClick, isSelected, id }) => (
      <SubTotal
        key={id}
        icon={icon}
        title={title}
        value={value}
        isSelected={isSelected}
        onClick={onClick}
        id={id}
      />
    ))}
  </Flex>
)

export default AggregateContainer
