import { CloseOutlined } from '@ant-design/icons'
import { Tooltip, Popconfirm, Typography } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { colorGlitter } from '@signifyd/colors'
import { SigTag } from '@signifyd/components'
import { PredictionList } from '@signifyd/http'

import { joinClassNames } from '@signifyd/utils'
import styles from './ListTag.less'

const MAX_TEXT_CHAR_COUNT = 60

interface ListItemProps {
  item: PredictionList
  deleteFromList: (item: PredictionList) => void
  analyticsId: string
}

const ListTag: FC<ListItemProps> = ({ item, deleteFromList, analyticsId }) => {
  const { t } = useTranslation()

  const [showPopover, setShowPopover] = useState(false)

  const shouldTruncate = item.name.length > MAX_TEXT_CHAR_COUNT
  const text = shouldTruncate
    ? `${item.name.slice(0, MAX_TEXT_CHAR_COUNT)}...`
    : item.name

  return (
    <SigTag
      color={colorGlitter}
      type="primary"
      className={styles.listTag}
      data-test-id={`listTag-${item.id}`}
    >
      <Tooltip title={shouldTruncate ? item.name : ''} placement="top">
        {text}
      </Tooltip>
      <Popconfirm
        overlayClassName={joinClassNames(['sig-popconfirm', styles.popconfirm])}
        overlayStyle={{ maxWidth: '292px' }}
        placement="bottomRight"
        title={
          <>
            <Typography className={styles.confirmDeleteTitle}>
              {t('orderAttributeLists.confirmDeleteTitle')}
            </Typography>
            <Typography className={styles.confirmDeleteText}>
              {t('orderAttributeLists.confirmDeleteContent')}
            </Typography>
          </>
        }
        onConfirm={() => deleteFromList(item)}
        open={showPopover}
        onOpenChange={(open) => setShowPopover(open)}
        okText={
          <span data-test-id="confirmRemoveFromListButton">
            {t('orderAttributeLists.confirmDeleteOkButton')}
          </span>
        }
        icon={null}
      >
        <Tooltip
          title={
            <span data-test-id="removeFromListTooltip">
              {t('orderAttributeLists.removeFromListTooltip')}
            </span>
          }
          placement="topRight"
          arrow={{ pointAtCenter: true }}
        >
          <CloseOutlined
            data-test-id="removeFromListButton"
            data-analytics-id={`remove-from-list-for-${analyticsId}`}
          />
        </Tooltip>
      </Popconfirm>
    </SigTag>
  )
}

export default ListTag
