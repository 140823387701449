import { Skeleton } from 'antd'
import { FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { ExternalLink } from '@signifyd/components'
import { PAYOUT_METHOD } from '@signifyd/http'
import { ChargebackSettingsPayload } from 'core/queries/useChargebackSettings'
import { usePayoutMethod } from 'core/queries/usePayoutMethod'
import CaseClaimsBannerContent from '../CaseClaimsBannerContent'
import ReimbursementPolicy from '../ReimbursementPolicy'

interface Props {
  teamId: number
  settings?: ChargebackSettingsPayload
}

export const ApprovedClaimContent: FC<Props> = ({ teamId, settings }) => {
  const { t } = useTranslation()

  const { data, isLoading: payoutMethodLoading } = usePayoutMethod(teamId)

  const payoutMethod = data?.payoutMethod

  const isNetBilling = payoutMethod === PAYOUT_METHOD.NET_BILLING

  const isPostRepresentment = settings?.isPostRepresentment

  if (payoutMethodLoading) {
    return <Skeleton active paragraph={{ rows: 1 }} />
  }

  if (isPostRepresentment) {
    return (
      <CaseClaimsBannerContent>
        <Trans
          i18nKey="claimsBanner.postRepresentment.content"
          components={{
            helpArticleLink: (
              <ExternalLink
                target="_blank"
                url="https://community.signifyd.com/support/s/article/reimbursement"
              >
                here
              </ExternalLink>
            ),
          }}
        />
      </CaseClaimsBannerContent>
    )
  }
  if (isNetBilling) {
    return (
      <>
        {t('claimsBanner.approved.isNetBilling')} <ReimbursementPolicy />
      </>
    )
  }

  return <>{t('claimsBanner.approved.default')}</>
}

export default ApprovedClaimContent
