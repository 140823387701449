import { Button, Descriptions, Tag, Tooltip } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ExternalLink } from '@signifyd/components'
import { InvestigationInfo } from '@signifyd/http'
import { toTitleCase } from '@signifyd/utils'
import NoInformation from 'core/components/NoInformation'
import isEventTicketing from 'core/utils/isEventTicketing'
import { useToggle } from 'core/utils/useToggle'
import styles from './OrderSummary.less'
import { getSortedProducts } from './OrderSummary.utils'
import ProductDetails from './ProductDetails'
import ProductTitleDetails from './ProductDetails/ProductTitleDetails'

const { Item: DescriptionItem } = Descriptions
const maxItems = 2

export interface OrderProduct {
  itemName?: string
  itemPrice?: string | number
  itemQuantity?: number
  itemUrl?: string
  shippingMethod?: string
}

interface ProductNameProps {
  product: OrderProduct
}

const ProductName: FC<ProductNameProps> = ({ product }) => {
  if (product.itemUrl) {
    const { itemName = '', itemUrl } = product

    return (
      <Tooltip title={itemName} placement="topLeft">
        <ExternalLink url={itemUrl} target="_blank" className={styles.itemName}>
          {itemName}
        </ExternalLink>
      </Tooltip>
    )
  }

  return (
    <Tooltip title={product.itemName} placement="topLeft">
      <span className={styles.itemName}>{product.itemName}</span>
    </Tooltip>
  )
}

interface Props {
  caseDetails: InvestigationInfo
}

export const OrderSummary: FC<Props> = ({ caseDetails }) => {
  const { t } = useTranslation()
  const [descendingOrder, setDescendingOrder] = useState(true)

  const { orderChannel, receivedBy } = caseDetails

  const sortedProducts = getSortedProducts(caseDetails, descendingOrder)
  const moreThanMaxCount = sortedProducts.length - maxItems
  const isEventTicketingOrder = isEventTicketing(caseDetails.products)

  const { value: showAll, toggle: toggleShowAll } = useToggle()

  const productsToShow = showAll
    ? sortedProducts
    : sortedProducts.slice(0, maxItems)
  const hasMatchingShipment = sortedProducts.some(
    (product) => product.shippingMethod
  )

  const sectionName = t('caseSummary.order.title')
  const agentName = t('caseSummary.order.agent', {
    name: receivedBy,
  })

  if (isEventTicketingOrder) {
    return null
  }

  return (
    <>
      <Descriptions
        title={
          <span>
            {sectionName}
            {orderChannel && <Tag>{toTitleCase(orderChannel)}</Tag>}
            {receivedBy && (
              <Tooltip title={agentName}>
                <Tag className={styles.agentTag}>{agentName}</Tag>
              </Tooltip>
            )}
          </span>
        }
        colon={false}
        size="small"
      >
        {sortedProducts?.length && (
          <>
            <DescriptionItem
              label={t('caseSummary.order.name')}
              key={t('caseSummary.order.name')}
            >
              <ProductTitleDetails
                setDescending={setDescendingOrder}
                descending={descendingOrder}
                showShippingColumn={hasMatchingShipment}
              />
            </DescriptionItem>

            {productsToShow.map((product, index) => (
              <DescriptionItem
                label={<ProductName product={product} />}
                key={`${product.itemName}-${index}`}
              >
                <ProductDetails
                  product={product}
                  showShippingColumn={hasMatchingShipment}
                />
              </DescriptionItem>
            ))}
          </>
        )}
      </Descriptions>

      {!sortedProducts?.length && <NoInformation />}

      {moreThanMaxCount > 0 && (
        <Button
          data-analytics-id={
            showAll ? 'product-collapse-button' : 'product-expand-button'
          }
          style={{ padding: 0 }}
          onClick={toggleShowAll}
          data-test-id="expandProductsButton"
          type="link"
        >
          {showAll
            ? t('caseSummary.order.showLess')
            : t('caseSummary.order.showMore', { count: moreThanMaxCount })}
        </Button>
      )}
    </>
  )
}

export default OrderSummary
