import { Descriptions, DescriptionsProps, Flex } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { spacingXS } from '@signifyd/ant'
import { ExternalLink } from '@signifyd/components'
import { ReturnProduct } from '@signifyd/http'
import { formatPrice } from '../ReturnDetails.utils'

interface Props {
  returnedItem: ReturnProduct
  refundCurrency?: string | null
}

const ReturnedItemThirdGen: FC<Props> = ({ returnedItem, refundCurrency }) => {
  const { t } = useTranslation()

  const item: Record<string, DescriptionsProps['items']> = {
    returnItem: [
      {
        key: `returnItem-${returnedItem.itemId}`,
        label: t('pages.caseReview.returnDetails.descriptions.returnItem'),
        children: (
          <>
            {returnedItem.itemUrl ? (
              <ExternalLink target="_blank" url={returnedItem.itemUrl}>
                {returnedItem.itemName}
              </ExternalLink>
            ) : (
              returnedItem.itemName
            )}
          </>
        ),
      },
    ],
    reason: [
      {
        key: `reason-${returnedItem.itemId}`,
        label: t('pages.caseReview.returnDetails.descriptions.reason'),
        children: t(`returnAttemptReason.${returnedItem.reason}`),
      },
    ],
    itemId: [
      {
        key: `itemId-${returnedItem.itemId}`,
        label: t('pages.caseReview.returnDetails.descriptions.itemId'),
        children: returnedItem.itemId,
      },
    ],
    itemPrice: [
      {
        key: `itemPrice-${returnedItem.itemId}`,
        label: t('pages.caseReview.returnDetails.descriptions.itemPrice'),
        children: formatPrice(returnedItem.itemPrice, refundCurrency),
      },
    ],
  }

  return (
    <Flex justify="space-between">
      <Flex vertical wrap gap={spacingXS}>
        <Descriptions colon={false} size="small" items={item.returnItem} />
        <Descriptions colon={false} size="small" items={item.reason} />
      </Flex>
      <Flex vertical wrap gap={spacingXS}>
        <Descriptions colon={false} size="small" items={item.itemId} />
        <Descriptions colon={false} size="small" items={item.itemPrice} />
      </Flex>
    </Flex>
  )
}

export default ReturnedItemThirdGen
