import { InfoCircleFilled } from '@ant-design/icons'
import { Divider } from 'antd'
import { FC } from 'react'
import { Text } from '@signifyd/components'
import {
  REPRESENTMENT_STATUS,
  REPRESENTMENT_STATUS_REASON,
  REPRESENTMENT_OUTCOME,
  REPRESENTMENT_TYPE,
} from '@signifyd/http'
import getRepresentmentDetails from './RepresentmentDetails.utils'

export interface RepresentmentDetailsProps {
  status?: REPRESENTMENT_STATUS
  statusReason?: REPRESENTMENT_STATUS_REASON
  outcome?: REPRESENTMENT_OUTCOME
  type?: REPRESENTMENT_TYPE
}

export const RepresentmentDetails: FC<RepresentmentDetailsProps> = ({
  statusReason,
  status,
  type,
  outcome,
}) => {
  const content = getRepresentmentDetails(status, statusReason, outcome, type)

  if (!content) {
    return null
  }

  return (
    <>
      <Divider style={{ margin: '13px 0' }} />
      <Text data-test-id="representmentDetailsText">
        <InfoCircleFilled /> {content}
      </Text>
    </>
  )
}

export default RepresentmentDetails
