import { Flex } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { spacingSM } from '@signifyd/ant'
import { ErrorBoundary } from '@signifyd/components'
import { ShowMoreContainerThirdGen } from 'pages/CaseReviewPage/components/ShowMoreContainer/ShowMoreContainerThirdGen'
import styles from './SellerDetails.less'
import { RuntimeSeller } from './SellerDetails.types'
import SellerInformation from './SellerInformation'
import SellerRecord from './SellerRecord'

interface Props {
  sellers: Array<RuntimeSeller>
  currency: string
}

export const SellerDetailsThirdGen: FC<Props> = ({ sellers, currency }) => {
  const { t } = useTranslation()

  if (!sellers.length) {
    return null
  }

  return (
    <ErrorBoundary message={t('errorBoundaries.sellerDetails')}>
      <ShowMoreContainerThirdGen
        data={sellers}
        cardTitle={t('pages.caseReview.sellerDetails.sellerDetails')}
        idPrefix="sellerDetails"
        defaultToShow={5}
        renderItem={(seller) => (
          <Flex
            key={seller.runtimeId}
            justify="space-between"
            className={styles.container}
            gap={spacingSM}
            wrap
          >
            <Flex flex={1}>
              <SellerInformation seller={seller} />
            </Flex>

            <Flex flex={1}>
              <SellerRecord seller={seller} currency={currency} />
            </Flex>
          </Flex>
        )}
      />
    </ErrorBoundary>
  )
}

export default SellerDetailsThirdGen
