import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import moment from 'moment-timezone'
import { i18nInstance, Text } from '@signifyd/components'
import { AncillaryPurchase, Passenger } from '@signifyd/http'
import TextOverflowWrapper from 'core/components/TextOverflowWrapper'
import { ColumnProps } from 'antd/lib/table'
import styles from './TravelDetails.less'
import { getAncillaryDetails } from './travelDetails.utils'

const { t } = i18nInstance

export const getTravelColumns = (): Array<ColumnProps<Passenger>> => {
  const PASSENGER_CHAR_LIMIT = 46
  const PASSENGER_MAX_WIDTH = 275

  return [
    {
      title: t('airline.ticketNumber'),
      key: 'passengerId',
      dataIndex: 'passengerId',
      width: 150,
      fixed: 'left',
    },
    {
      title: t('airline.passengerName'),
      key: 'passengerName',
      fixed: 'left',
      render: ({ passengerFirstName, passengerLastName }) => {
        const passengerFullName =
          `${passengerFirstName} ${passengerLastName}`.trim()

        return (
          <TextOverflowWrapper
            maxWidth={PASSENGER_MAX_WIDTH}
            title={
              passengerFullName.length > PASSENGER_CHAR_LIMIT
                ? passengerFullName
                : undefined
            }
          >
            {passengerFullName}
          </TextOverflowWrapper>
        )
      },
    },
    {
      title: t('airline.email'),
      key: 'passengerEmail',
      dataIndex: 'passengerEmail',
    },
    {
      title: t('airline.ticketStatus'),
      key: 'ticketStatus',
      dataIndex: 'ticketStatus',
    },
    {
      title: t('airline.ancillaries'),
      key: 'ancillaryPurchases',
      dataIndex: 'ancillaryPurchases',
      render: (ancillaryPurchases: Array<AncillaryPurchase>) => {
        const { ancillaryCount, ancillaryText } =
          getAncillaryDetails(ancillaryPurchases)

        if (!ancillaryText) {
          return ancillaryCount
        }

        return (
          <>
            {ancillaryCount}{' '}
            <Tooltip
              overlayClassName={styles.tooltipText}
              arrow={{ pointAtCenter: true }}
              title={
                <Text size="sm" className={styles.tooltipText}>
                  {t('airline.ancillaryPurchases', {
                    ancillaries: ancillaryText,
                  })}
                </Text>
              }
            >
              <QuestionCircleOutlined
                data-test-id={`tooltip-${ancillaryText}`}
                className={styles.tooltipIcon}
              />
            </Tooltip>
          </>
        )
      },
    },
    {
      title: t('airline.membershipLast4'),
      key: 'membershipLastFour',
      dataIndex: 'membershipLastFour',
      width: 160,
    },
    {
      title: t('airline.idDocument'),
      key: 'idDocumentType',
      dataIndex: 'idDocumentType',
    },
    {
      title: t('airline.idOrigin'),
      key: 'idDocumentCountry',
      dataIndex: 'idDocumentCountry',
      width: 140,
    },
    {
      title: t('airline.idLast4'),
      key: 'idLastFour',
      dataIndex: 'idLastFour',
      width: 100,
    },
    {
      title: t('airline.idExpiration'),
      key: 'idExpiryDate',
      dataIndex: 'idExpiryDate',
      width: 130,
      render: (idExpiryDate) => {
        if (!idExpiryDate) {
          return null
        }

        return moment(idExpiryDate).format('MM/YYYY')
      },
    },
  ]
}
