import { Collapse, Descriptions, DescriptionsProps, Divider, Flex } from 'antd'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'store'
import { spacingMD, spacingXS } from '@signifyd/ant'
import { colorMarengo } from '@signifyd/colors'
import {
  Space,
  ChevronDown,
  ChevronRight,
  ReturnTagThirdGen,
  TextThirdGen,
  TitleThirdGen,
} from '@signifyd/components'
import FormattedDate from 'core/components/FormattedDate'
import NotFoundPagePlaceholder from 'pages/CaseReviewPage/components/NotFoundPagePlaceholder'
import ReturnedItem from './ReturnedItem'
import { formatPrice } from '../ReturnDetails.utils'
import styles from './ReturnDetails.less'

const { Panel } = Collapse

const ReturnDetailsThirdGen: FC = () => {
  const { t } = useTranslation()
  const caseDetails = useStoreState((state) => state.currentCase.details)

  const returns = caseDetails?.returns
  if (!returns?.length) {
    return (
      <NotFoundPagePlaceholder
        title={t('pages.caseReview.returnDetails.notFoundTitle')}
      />
    )
  }

  return (
    <Flex vertical gap={spacingMD} data-test-id="returnDetailsDisplay">
      {returns.map((returnData) => {
        const returnedItems = returnData.returnedItems ?? []

        const checkpointAction = returnData.decision?.checkpointAction

        const refundCurrency =
          returnData.refund?.currency ?? caseDetails?.currency

        const returnItems: Record<string, DescriptionsProps['items']> = {
          requestDate: [
            {
              key: 'requestDate',
              label: t(
                'pages.caseReview.returnDetails.descriptions.requestDate'
              ),
              children: <FormattedDate date={returnData.createdAt} />,
            },
          ],
          returnAction: [
            {
              key: 'returnAction',
              label: t(
                'pages.caseReview.returnDetails.descriptions.returnAction'
              ),
              children:
                checkpointAction && t(`checkpointAction.${checkpointAction}`),
            },
          ],
          refundMethod: [
            {
              key: 'refundMethod',
              label: t(
                'pages.caseReview.returnDetails.descriptions.refundMethod'
              ),
              children: (
                <>
                  {returnData.refund?.method &&
                    t(`refundMethod.${returnData.refund.method}`)}
                </>
              ),
            },
          ],
          totalRefundMethod: [
            {
              key: 'totalRefundMethod',
              label: t(
                'pages.caseReview.returnDetails.descriptions.totalRefundAmount'
              ),
              children: (
                <>
                  {returnData.refund?.amount &&
                    formatPrice(returnData.refund?.amount, refundCurrency)}
                </>
              ),
            },
          ],
        }

        return (
          <Fragment key={returnData.returnId}>
            <Collapse
              expandIcon={({ isActive }) =>
                isActive ? (
                  <ChevronDown fill={colorMarengo} />
                ) : (
                  <ChevronRight fill={colorMarengo} />
                )
              }
            >
              <Panel
                header={
                  <TitleThirdGen level={3}>
                    {t(`pages.caseReview.returnDetails.panelHeader`, {
                      id: returnData.returnId,
                    })}
                  </TitleThirdGen>
                }
                key={returnData.returnId}
                extra={
                  checkpointAction && <ReturnTagThirdGen returns={returns} />
                }
              >
                <div className={styles.container}>
                  <TextThirdGen weight="semibold">
                    {t(`pages.caseReview.returnDetails.returnDescription`, {
                      count: returnedItems.length,
                    })}
                  </TextThirdGen>
                  <Space size="lg" />
                  <Flex justify="space-between">
                    <Flex vertical wrap gap={spacingXS}>
                      <Descriptions
                        colon={false}
                        size="small"
                        items={returnItems.requestDate}
                      />

                      {checkpointAction && (
                        <Descriptions
                          colon={false}
                          size="small"
                          items={returnItems.returnAction}
                        />
                      )}
                    </Flex>
                    <Flex justify="space-between">
                      <Flex vertical wrap gap={spacingXS}>
                        <Descriptions
                          colon={false}
                          size="small"
                          items={returnItems.refundMethod}
                        />
                        <Descriptions
                          colon={false}
                          size="small"
                          items={returnItems.totalRefundMethod}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Space size="lg" />
                  <Divider />
                  <Space size="lg" />
                  {returnedItems.map((returnedItem) => (
                    <Fragment
                      key={`${returnedItem.itemId}-${returnData.returnId}`}
                    >
                      <ReturnedItem
                        returnedItem={returnedItem}
                        refundCurrency={refundCurrency}
                      />
                      <Space size="md" />
                    </Fragment>
                  ))}
                </div>
              </Panel>
            </Collapse>
          </Fragment>
        )
      })}
      <Space size="md" />
    </Flex>
  )
}

export default ReturnDetailsThirdGen
