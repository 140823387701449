import { Card, Row, Col } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorBoundary } from '@signifyd/components'
import { InvestigationInfo } from '@signifyd/http'
import { CardHeader } from 'pages/CaseReviewPage/components/CardHeader/CardHeader'
import { CaseEntriesResolvedState } from 'store/currentCase/types/case.entries.types'
import styles from './CaseDetails.less'
import Device from './Device'
import EmailAccounts from './EmailAccounts'
import Locations from './Locations'
import Network from './Network'
import Organization from './Organization'
import People from './People'
import Phone from './Phone'

interface Props {
  caseEntries: CaseEntriesResolvedState
  caseDetails: InvestigationInfo | null
}

export const CaseDetails: FC<Props> = ({ caseEntries, caseDetails }) => {
  const { t } = useTranslation()

  const hasDetails = !!caseEntries && !!caseDetails

  return (
    <Card
      title={<CardHeader title={t('pages.caseReview.details.caseDetails')} />}
      data-test-id="caseDetails"
      data-analytics-id="case-details"
      size="small"
    >
      <ErrorBoundary
        className={styles.errorBoundary}
        message={t('errorBoundaries.caseDetails')}
      >
        {hasDetails && (
          <Row gutter={[32, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Locations caseDetails={caseDetails} />
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <People
                caseEntries={caseEntries}
                accountHolderTaxInfo={{
                  accountHolderTaxId: caseDetails.accountHolderTaxId,
                  accountHolderTaxIdCountry:
                    caseDetails.accountHolderTaxIdCountry,
                }}
                caseDetails={caseDetails}
              />
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Network caseEntries={caseEntries} caseDetails={caseDetails} />
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <EmailAccounts
                caseEntries={caseEntries}
                caseDetails={caseDetails}
              />
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Organization caseEntries={caseEntries} />
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Phone caseEntries={caseEntries} caseDetails={caseDetails} />
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Device caseDetails={caseDetails} />
            </Col>
          </Row>
        )}
      </ErrorBoundary>
    </Card>
  )
}

export default CaseDetails
