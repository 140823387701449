import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { message } from 'antd'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import { useStoreActions } from 'store'
import { i18nInstance } from '@signifyd/components'
import {
  INV_GUARANTEE_DISPOSITION,
  InvestigationInfo,
  replaceGuaranteeDisposition,
} from '@signifyd/http'
import { GET_CASE_ENTRIES_QUERY_KEY } from 'core/hooks/useCaseEntries'
import {
  ActionState,
  getDisableActionState,
  getEnableActionState,
} from '../hooks/caseActions/utils'

const cancelGuaranteeTranslationPrefix =
  'caseActions.cancelGuarantee.validation'

export interface CancelGuaranteePayload {
  caseDetails: InvestigationInfo
}

export const canCancelGuarantee = (
  caseDetails: InvestigationInfo,
  hasReviewerRole: boolean
): ActionState => {
  const { t } = i18nInstance

  if (hasReviewerRole) {
    return getDisableActionState(
      t(`${cancelGuaranteeTranslationPrefix}.notAllowed`)
    )
  }

  // 2. Disable if case was reviewed more than 30 days ago
  if (
    moment(caseDetails.guaranteeReviewedAt).isBefore(
      moment().subtract(30, 'days')
    )
  ) {
    return getDisableActionState(
      t(`${cancelGuaranteeTranslationPrefix}.tooOld`, { days: 30 })
    )
  }

  // 3. Disable if case is declined
  if (caseDetails.guaranteeDisposition === INV_GUARANTEE_DISPOSITION.DECLINED) {
    return getDisableActionState(
      t(`${cancelGuaranteeTranslationPrefix}.declined`)
    )
  }

  // 4. Disable if order has a claim against it
  if (caseDetails.claims.length) {
    return getDisableActionState(
      t(`${cancelGuaranteeTranslationPrefix}.hasClaim`)
    )
  }

  // 5. Disable if not yet submitted for guarantee
  if (!caseDetails.guaranteeDisposition) {
    return getDisableActionState(
      t(`${cancelGuaranteeTranslationPrefix}.notSubmitted`)
    )
  }

  // 6. Disable if guarantee is already canceled
  if (caseDetails.guaranteeDisposition === INV_GUARANTEE_DISPOSITION.CANCELED) {
    return getDisableActionState(
      t(`${cancelGuaranteeTranslationPrefix}.alreadyCanceled`)
    )
  }

  // If we're here, action can be seen and used
  return getEnableActionState()
}

export const useCancelGuarantee = (): UseMutationResult<
  CancelGuaranteePayload,
  void,
  CancelGuaranteePayload,
  CancelGuaranteePayload
> => {
  const queryClient = useQueryClient()
  const { t } = useTranslation(undefined, {
    keyPrefix: 'caseActions.cancelGuarantee',
  })
  const { refreshCurrentCase } = useStoreActions((store) => store.currentCase)

  return useMutation({
    mutationFn: async ({ caseDetails }) => {
      await replaceGuaranteeDisposition(
        caseDetails.investigationId,
        INV_GUARANTEE_DISPOSITION.CANCELED
      )

      refreshCurrentCase()

      return {
        caseDetails,
      }
    },
    onSuccess: async (_res, _var, context) => {
      await queryClient.invalidateQueries([
        GET_CASE_ENTRIES_QUERY_KEY,
        context?.caseDetails.investigationId,
      ])
      message.success(t('apiSuccess'))
    },
    onError: async () => {
      message.error(t('apiFailure'))
    },
  })
}
