import { Collapse, Skeleton } from 'antd'
import moment from 'moment-timezone'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { colorMarengo } from '@signifyd/colors'
import { ChevronDown, ChevronUp, Fetch } from '@signifyd/components'
import { DATE_FORMAT } from 'core/constants'
import { getMinMaxDate } from 'core/utils/date.utils'
import { getSelectedQuickDateFilter } from 'core/utils/quickDateFilter/quickDateFilter'
import Title from 'antd/lib/typography/Title'
import { FilterState } from 'store/search'
import useSearchAggregates from '../../../queries/useSearchAggregates'
import { dateOptions } from '../../HeadlineDatePicker/HeadlineDatePicker'
import { getSubTotals } from './aggregate.data'
import AggregateView from './AggregateView'

const { Panel } = Collapse

interface Props {
  filters: FilterState
  updateFilters: (filters: FilterState) => void
  activeKey: string | Array<string>
  setActiveKey: (activeKey: string | Array<string>) => void
  teamId: Array<number>
}

export const aggregatesPanelKey = 'aggregates'

const AggregatePanelThirdGen: FC<Props> = ({
  filters,
  updateFilters,
  activeKey,
  setActiveKey,
  teamId,
}) => {
  const { t } = useTranslation()
  const { normalizedPurchaseCreatedAt: filterDates } = filters
  const { minDate, maxDate } = getMinMaxDate(filterDates)

  const difference = dateOptions.find((date) =>
    getSelectedQuickDateFilter(filterDates, date)
  )

  const dateLocaleConfig = difference
    ? { count: difference }
    : {
        range: `${moment(minDate).format(DATE_FORMAT.date)} - ${moment(
          maxDate
        ).format(DATE_FORMAT.date)}`,
        interpolation: { escapeValue: false },
      }

  const getExpandIcon = (isActive: boolean): JSX.Element => {
    return isActive ? (
      <ChevronDown
        fill={colorMarengo}
        data-test-id="toggleShowHideAggregates"
        data-analytics-id="expand-aggregates-panel"
      />
    ) : (
      <ChevronUp
        fill={colorMarengo}
        data-test-id="toggleShowHideAggregates"
        data-analytics-id="close-aggregates-panel"
      />
    )
  }

  return (
    <Collapse
      bordered={false}
      activeKey={activeKey}
      onChange={setActiveKey}
      accordion={false}
      destroyInactivePanel={false}
      expandIcon={({ isActive }) => getExpandIcon(!!isActive)}
    >
      <Panel
        style={{
          border: 0,
        }}
        key={aggregatesPanelKey}
        header={
          <Title level={2}>{t('aggregates.summary', dateLocaleConfig)}</Title>
        }
      >
        <Fetch
          useFetchFunction={useSearchAggregates}
          fetchFunctionArgs={[filterDates, teamId]}
          renderComponent={(data) => {
            const ordersSubTotals = getSubTotals(data, filters)

            return (
              <AggregateView
                filters={filters}
                subTotals={ordersSubTotals}
                updateFilters={updateFilters}
              />
            )
          }}
          LoadingComponent={<Skeleton />}
        />
      </Panel>
    </Collapse>
  )
}

export default AggregatePanelThirdGen
