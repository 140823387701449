import { i18nInstance } from '@signifyd/components'
import { googleSearchLink } from '../DeepLinkConstants'
import { DeepLinkFactory, MENU_ICON } from '../DeepLinkDropdown.types'

const getDeepLinksEventAddress: DeepLinkFactory = ({ caseDetails, value }) => {
  const emptyEventObj = {
    eventVenueName: '',
    eventVenueAddress: {
      fullAddress: '',
      streetAddress: '',
      unit: '',
      city: '',
      provinceCode: '',
      postalCode: '',
      countryCode: '',
    },
  }

  const eventDetails = caseDetails?.products.find((product) => {
    return (
      product.eventTicketing &&
      (value?.includes(product.eventTicketing.eventVenueName) ||
        value?.includes(product.eventTicketing.eventVenueAddress?.fullAddress))
    )
  })?.eventTicketing

  const event = eventDetails || emptyEventObj

  const { fullAddress, streetAddress, city, provinceCode, countryCode } =
    event.eventVenueAddress || emptyEventObj.eventVenueAddress

  const ekataSearchQuery =
    `street=${streetAddress}` + `&where=${city} ${provinceCode}`

  return [
    {
      icon: MENU_ICON.GoogleSearchIcon,
      text: i18nInstance.t('pages.caseReview.details.deepLinks.addressSearch'),
      url: googleSearchLink + fullAddress,
    },
    {
      icon: MENU_ICON.GoogleMapIcon,
      text: i18nInstance.t('pages.caseReview.details.deepLinks.addressMapView'),
      url: `https://maps.google.com/maps?z=20&t=k&q=${fullAddress},${city},${provinceCode},${countryCode}`,
    },
    {
      icon: MENU_ICON.WhitepagesIcon,
      text: i18nInstance.t('pages.caseReview.details.deepLinks.addressSearch'),
      url: `http://www.whitepages.com/search/FindNearby?utf8=true&street=${streetAddress}&where=${city} ${provinceCode}`,
    },
    {
      icon: MENU_ICON.EkataIcon,
      text: i18nInstance.t('pages.caseReview.details.deepLinks.addressSearch'),
      url: `https://app.ekata.com/addresses?${ekataSearchQuery}`,
    },
  ]
}

export default getDeepLinksEventAddress
