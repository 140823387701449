import { Descriptions, DescriptionsProps, Flex } from 'antd'
import { ReactNode } from 'react'
import { spacingMD } from '@signifyd/ant'
import { Space, TitleThirdGen, i18nInstance } from '@signifyd/components'
import { AirlineTripDetail, Leg } from '@signifyd/http'
import FormattedDate from 'core/components/FormattedDate'
import NoInformation from 'core/components/NoInformation'
import { defaultDescriptionProps } from 'core/constants'
import PanelHeader from './PanelHeader'

const { t } = i18nInstance

const formatDates = (portCode?: string, time?: string): ReactNode =>
  portCode &&
  time && (
    <span>
      {portCode} (<FormattedDate date={time} />)
    </span>
  )

const getLegColumns = (leg: Leg): DescriptionsProps['items'] => {
  return [
    {
      label: t('airline.routeCode'),
      key: 'routeCode',
      children: leg.routeCode,
    },
    {
      label: t('airline.departure'),
      key: 'departurePortCode',
      children: formatDates(leg.departurePortCode, leg.departureTime),
    },
    {
      label: t('airline.arrival'),
      key: 'arrivalPortCode',
      children: formatDates(leg.arrivalPortCode, leg.arrivalTime),
    },
  ]
}

const AirlineTripDetailsThirdGen = ({
  travelData,
}: {
  travelData?: Array<AirlineTripDetail>
}): JSX.Element => {
  if (!travelData?.length) {
    return (
      <Descriptions
        title={t('airline.tripDetails')}
        colon={false}
        size="small"
        bordered
        items={[
          {
            label: '',
            labelStyle: { display: 'none' },
            children: <NoInformation />,
          },
        ]}
      />
    )
  }

  return (
    <div>
      <TitleThirdGen level={3}>{t('airline.tripDetails')}</TitleThirdGen>
      <Space size="sm" />

      <Flex wrap gap={spacingMD}>
        {travelData.map((trip, index) => (
          <Flex
            align="center"
            wrap
            key={`${trip.arrivalPortCode}-${trip.departurePortCode}-${index}`}
            gap={spacingMD}
          >
            {trip?.legs?.map((leg) => (
              <Descriptions
                key={leg.routeCode}
                {...defaultDescriptionProps}
                items={getLegColumns(leg ?? [])}
                title={<PanelHeader {...trip} />}
              />
            ))}
          </Flex>
        ))}
      </Flex>
    </div>
  )
}

export default AirlineTripDetailsThirdGen
