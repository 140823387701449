import { Card, Row } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'store'
import {
  ErrorBoundary,
  Text,
  T4,
  DataPointFilter,
  Space,
  getCaseSearchUrl,
} from '@signifyd/components'
import { InvestigationInfo, INV_STATUS, USER_FEATURE } from '@signifyd/http'
import useHasSecondaryMatches from 'core/hooks/useHasSecondaryMatches'
import { ANALYSIS_STATE } from 'store/currentCase/types/case.analysis.types'
import styles from './CaseIntelligence.less'
import CaseIntelligencePanel from './CaseIntelligencePanel'
import WarningPanel from './WarningPanel'

interface Props {
  isLarge: boolean
  caseDetails: InvestigationInfo
}

export const CaseIntelligence: FC<Props> = ({ isLarge, caseDetails }) => {
  const { t } = useTranslation()
  const hasSecondaryMatches = useHasSecondaryMatches()

  const { investigationStatus, signifydScore } = caseDetails
  const { currentUser, currentTeams } = useStoreState((state) => state.user)

  const analysisState = useStoreState(
    (state) => state.currentCase.analysisState
  )

  const hasPowersearch = !!currentUser?.features[USER_FEATURE.POWER_SEARCH]

  const showData = analysisState === ANALYSIS_STATE.PROCESSED

  const showScore =
    showData && investigationStatus !== INV_STATUS.HELD && signifydScore >= 0

  const title = (
    <Row justify="space-between">
      <T4 className={styles.title}>{t('caseIntelligence.title')}</T4>
      {showScore && (
        <Text size="lg" data-test-id="score" weight="semibold">
          {t('caseIntelligence.score', { score: Math.trunc(signifydScore) })}
        </Text>
      )}
    </Row>
  )

  const handleViewConnectedOrders = (
    selectedDataPoints: Array<string>
  ): void => {
    const formattedDataPoints = selectedDataPoints.map((selectedDataPoint) =>
      selectedDataPoint.toUpperCase().replace(' ', '_')
    )

    const caseSearchLink = `${getCaseSearchUrl()}?similarityInvestigationId=${
      caseDetails.investigationId
    }&similarityAttributes=${formattedDataPoints.join('&similarityAttributes=')}`

    window.open(caseSearchLink)
  }

  return (
    <Card
      title={title}
      data-test-id="caseIntelligence"
      data-analytics-id="case-intelligence"
      size="small"
      style={{ height: !showData ? '172px' : 'inherit' }}
    >
      <ErrorBoundary message={t('errorBoundaries.intelligence')}>
        <>
          {showData && (
            <CaseIntelligencePanel
              isLarge={isLarge}
              caseDetails={caseDetails}
            />
          )}

          {!showData && <WarningPanel analysisState={analysisState} />}

          {showData && hasSecondaryMatches && hasPowersearch && (
            <>
              <Space size="sm" />
              <DataPointFilter
                columnSpan={8}
                investigation={caseDetails}
                onViewConnectedOrders={handleViewConnectedOrders}
                teamId={currentTeams?.map((id) => id.toString())}
              />
            </>
          )}
        </>
      </ErrorBoundary>
    </Card>
  )
}

export default CaseIntelligence
