import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Row } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { ANALYSIS_STATE } from 'store/currentCase/types/case.analysis.types'
import styles from './WarningPanel.less'

interface Props {
  analysisState: ANALYSIS_STATE
}

export const WarningPanel: FC<Props> = ({ analysisState }) => {
  const { t } = useTranslation()

  const getWarningPanelText = (): string => {
    if (analysisState === ANALYSIS_STATE.PROCESSING) {
      return t('caseIntelligence.warning.processing')
    }

    if (analysisState === ANALYSIS_STATE.POLICY_ONLY) {
      return t('caseIntelligence.warning.policyOnly')
    }

    return t('caseIntelligence.warning.error')
  }

  const iconType =
    analysisState === ANALYSIS_STATE.POLICY_ONLY
      ? 'info-circle'
      : 'exclamation-circle'

  return (
    <>
      <Row justify="center">
        <LegacyIcon className={styles.warningIcon} type={iconType} />
      </Row>
      <Row justify="center">
        <Text className={styles.warningText}>{getWarningPanelText()}</Text>
      </Row>
    </>
  )
}

export default WarningPanel
