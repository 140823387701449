import { FC } from 'react'
import { colorTurquoise } from '@signifyd/colors'
import styles from './Arrow.less'

const Arrow: FC = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.arrow}
    >
      <path
        d="M19.4505 13.4588L9.76534 5.89411C9.74003 5.87419 9.70962 5.86181 9.67759 5.85839C9.64556 5.85497 9.61322 5.86066 9.58428 5.87479C9.55534 5.88892 9.53097 5.91094 9.51397 5.9383C9.49697 5.96566 9.48804 5.99726 9.48819 6.02946V7.69021C9.48819 7.79548 9.53761 7.89646 9.61925 7.96091L17.3536 14.0002L9.61925 20.0394C9.53546 20.1039 9.48819 20.2049 9.48819 20.3101V21.9709C9.48819 22.1148 9.65362 22.1943 9.76534 22.1062L19.4505 14.5416C19.5328 14.4774 19.5994 14.3952 19.6452 14.3014C19.691 14.2076 19.7148 14.1046 19.7148 14.0002C19.7148 13.8958 19.691 13.7927 19.6452 13.6989C19.5994 13.6051 19.5328 13.523 19.4505 13.4588Z"
        fill={colorTurquoise}
      />
      <rect
        x="0.5"
        y="0.5"
        width="27"
        height="27"
        rx="3.5"
        stroke={colorTurquoise}
      />
    </svg>
  )
}

export default Arrow
