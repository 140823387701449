import quickSearch from './quickSearch.store'
import actionListeners from './results.action.listeners'
import resultsModel from './results.store'
import searchModel, { defaultSearchState } from './search.store'
import { SearchStoreModel } from './types'

export * from './types/index'

const searchStoreModel: SearchStoreModel = {
  ...searchModel,
  ...resultsModel,
  ...quickSearch,
  ...actionListeners,
}
export { searchStoreModel, defaultSearchState }
