import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { ValidationRule } from '@ant-design/compatible/lib/form/Form'
import { Col, Input, List, Row, Select, Typography } from 'antd'
import { PureComponent, ReactNode } from 'react'
import { Translation } from 'react-i18next'
import { Space } from '@signifyd/components'
import { PhysicalAddress } from '@signifyd/http'
import { toTitleCase } from '@signifyd/utils'
import { Address } from 'store/caseActions/types/updateAddress.types'
import countryList from './countryList'
import formConfig from './form.config'
import styles from './UpdateAddressModal.less'

export interface Props extends FormComponentProps {
  addresses: Array<Address>
  setAddresses: (addressesUpdate: Array<Address>) => void
  setHasErrors: (hasErrors: boolean) => void
}

const { Title } = Typography
const { Item: FormItem } = Form

const countryOptions = countryList.map(({ code, name }) => (
  <Select.Option key={code} value={code} title={name}>
    {name}
  </Select.Option>
))

const getRule = (required: boolean, message: any): ValidationRule => {
  return { required, type: 'string', message, whitespace: true }
}

class UpdateAddressForm extends PureComponent<Props> {
  render(): ReactNode {
    const { addresses } = this.props
    const { getFieldDecorator } = this.props.form

    return (
      <Translation>
        {(t) => (
          <Form
            layout="vertical"
            className={styles.addressForm}
            data-test-id="update-address-form"
          >
            <Space size={20} />
            <List itemLayout="vertical">
              {addresses.map(
                (address, addressIndex) =>
                  address.newAddress && (
                    <List.Item key={address.newAddress.address.fullAddress}>
                      <>
                        <Title level={3} className={styles.titlePadding}>
                          {t('caseActions.updateAddress.updateAddress', {
                            value: address.shipment?.shippingMethod
                              ? toTitleCase(address.shipment?.shippingMethod)
                              : address.oldAddress.fullName,
                          })}
                        </Title>
                        {formConfig(address.newAddress!).map(
                          (inputs, formIndex) => (
                            <Row key={formIndex} data-test-id="formRow">
                              {inputs.map((input, index) => (
                                <Col
                                  span={input.size}
                                  offset={index > 0 ? 1 : undefined}
                                  key={input.updateKey}
                                  data-test-id="formCol"
                                >
                                  <FormItem
                                    label={t(
                                      `caseActions.updateAddress.${input.translationKey}.label`
                                    )}
                                    className={styles.formItem}
                                  >
                                    {getFieldDecorator(
                                      `${addressIndex}-${input.updateKey}`,
                                      {
                                        rules: [
                                          getRule(
                                            input.required,
                                            t(
                                              `caseActions.updateAddress.${input.translationKey}.error`
                                            )
                                          ),
                                        ],
                                        initialValue: input.initialValue,
                                      }
                                    )(
                                      input.updateKey === 'countryCode' ? (
                                        <Select
                                          data-test-id={`input-${input.updateKey}-${addressIndex}`}
                                          showSearch
                                          placeholder={t(
                                            'caseActions.updateAddress.country.placeholder'
                                          )}
                                          optionFilterProp="title"
                                        >
                                          {countryOptions}
                                        </Select>
                                      ) : (
                                        <Input
                                          data-test-id={`input-${input.updateKey}-${addressIndex}`}
                                          placeholder={t(
                                            `caseActions.updateAddress.${input.translationKey}.placeholder` as const
                                          )}
                                        />
                                      )
                                    )}
                                  </FormItem>
                                </Col>
                              ))}
                            </Row>
                          )
                        )}
                      </>
                    </List.Item>
                  )
              )}
            </List>
          </Form>
        )}
      </Translation>
    )
  }
}

interface FieldValues {
  dirty: boolean
  errors?: string
  name: string
  touched?: boolean
  validating?: boolean
  value: string
}

export default Form.create<Props>({
  name: 'updateAddress',
  onFieldsChange: (props, fields, allFields) => {
    const [firstField] = Object.values(fields) as Array<FieldValues>
    const [id, fieldName] = firstField.name.split('-')
    const index = Number(id)

    if (fieldName === 'fullName') {
      props.addresses[index].newAddress!.fullName = firstField.value
    } else {
      const addressKey = fieldName as keyof PhysicalAddress

      props.addresses[index].newAddress!.address[addressKey] = firstField.value
    }

    props.setAddresses(props.addresses)

    props.setHasErrors(
      Object.values(allFields).some(({ errors }: any) => !!errors?.length)
    )
  },
})(UpdateAddressForm)
