import { FC, useState } from 'react'
import { useStoreActions, useStoreState } from 'store'
import { colorTurquoise } from '@signifyd/colors'
import { SigLogo } from '@signifyd/components'
import styles from './OnboardingScreen.less'
import StartTrialScreen from './StartTrialScreen'
import WelcomeScreen from './WelcomeScreen'

interface Props {
  username: string
}

const OnboardingScreen: FC<Props> = ({ username, children }) => {
  const [slide, setSlide] = useState(0)
  const userPrefs = useStoreState((state) => state.user.uiPrefs)
  const updateUserUIPrefs = useStoreActions(
    (action) => action.user.updateUserUIPrefs
  )

  const startTrialFn = async (): Promise<void> => {
    updateUserUIPrefs({
      username,
      pref: {
        SHOW_ONBOARD_MSG: 'false',
      },
    })
  }

  if (userPrefs?.SHOW_ONBOARD_MSG !== 'true') {
    return <>{children}</>
  }

  return (
    <div className={styles.screen}>
      <div className={styles.logo}>
        <SigLogo iconColor={colorTurquoise} />
      </div>

      {slide === 0 && <WelcomeScreen setSlide={setSlide} />}
      {slide === 1 && (
        <StartTrialScreen setSlide={setSlide} startTrialFn={startTrialFn} />
      )}
    </div>
  )
}

export default OnboardingScreen
