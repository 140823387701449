import { i18nInstance } from '@signifyd/components'
import {
  REPRESENTMENT_STATUS,
  REPRESENTMENT_STATUS_REASON,
  REPRESENTMENT_OUTCOME,
  REPRESENTMENT_TYPE,
} from '@signifyd/http'
import ChallengedNoOutcome from './ChallengedNoOutcome'
import NotChallenged from './NotChallenged'
import SecondChargeback from './SecondChargeback'

interface Conditions {
  status?: REPRESENTMENT_STATUS
  statusReason?: REPRESENTMENT_STATUS_REASON
  outcome?: REPRESENTMENT_OUTCOME
  type?: REPRESENTMENT_TYPE
}

interface Data {
  content: JSX.Element | string
}

interface DetailsConfiguration {
  conditions: Conditions
  data: Data
}

const getRepresentmentDetails = (
  status?: REPRESENTMENT_STATUS,
  statusReason?: REPRESENTMENT_STATUS_REASON,
  outcome?: REPRESENTMENT_OUTCOME,
  type?: REPRESENTMENT_TYPE
): JSX.Element | string | undefined => {
  const detailsConfigurations: Array<DetailsConfiguration> = [
    {
      conditions: {
        status: REPRESENTMENT_STATUS.DISPUTED,
        type: REPRESENTMENT_TYPE.REVERSAL,
      },
      data: {
        content: i18nInstance.t(
          'claimsBanner.representmentDetails.underBankReview'
        ),
      },
    },
    {
      conditions: {
        status: REPRESENTMENT_STATUS.DISPUTED,
        type: REPRESENTMENT_TYPE.CBR,
      },
      data: {
        content: i18nInstance.t(
          'claimsBanner.representmentDetails.underBankReview'
        ),
      },
    },
    {
      conditions: {
        status: REPRESENTMENT_STATUS.ABANDONED,
        statusReason: REPRESENTMENT_STATUS_REASON.NUKED,
        type: REPRESENTMENT_TYPE.CBR,
      },
      data: {
        content: <NotChallenged />,
      },
    },
    {
      conditions: {
        status: REPRESENTMENT_STATUS.ABANDONED,
        statusReason: REPRESENTMENT_STATUS_REASON.MERCHANT_REPRESENTMENT,
        type: REPRESENTMENT_TYPE.CBR,
      },
      data: {
        content: <NotChallenged />,
      },
    },
    {
      conditions: {
        status: REPRESENTMENT_STATUS.ABANDONED,
        statusReason: REPRESENTMENT_STATUS_REASON.VALID_CHARGEBACK,
        type: REPRESENTMENT_TYPE.CBR,
      },
      data: {
        content: i18nInstance.t(
          'claimsBanner.representmentDetails.notChallengedValidFraud'
        ),
      },
    },
    {
      conditions: {
        status: REPRESENTMENT_STATUS.ABANDONED,
        statusReason: REPRESENTMENT_STATUS_REASON.NO_ACCESS,
        type: REPRESENTMENT_TYPE.CBR,
      },
      data: {
        content: <NotChallenged />,
      },
    },
    {
      conditions: {
        status: REPRESENTMENT_STATUS.ABANDONED,
        statusReason: REPRESENTMENT_STATUS_REASON.CLAIM_DECLINED,
        type: REPRESENTMENT_TYPE.CBR,
      },
      data: {
        content: <NotChallenged />,
      },
    },
    {
      conditions: {
        status: REPRESENTMENT_STATUS.ABANDONED,
        statusReason: REPRESENTMENT_STATUS_REASON.EXPIRED,
        type: REPRESENTMENT_TYPE.CBR,
      },
      data: {
        content: <NotChallenged />,
      },
    },
    {
      conditions: {
        status: REPRESENTMENT_STATUS.ABANDONED,
        statusReason: REPRESENTMENT_STATUS_REASON.INELIGIBLE_CLAIM,
        type: REPRESENTMENT_TYPE.CBR,
      },
      data: {
        content: <NotChallenged />,
      },
    },
    {
      conditions: {
        status: REPRESENTMENT_STATUS.ABANDONED,
        statusReason: REPRESENTMENT_STATUS_REASON.MERCHANT_ACCEPTED,
        type: REPRESENTMENT_TYPE.CBR,
      },
      data: {
        content: <NotChallenged />,
      },
    },
    {
      conditions: {
        status: REPRESENTMENT_STATUS.ABANDONED,
        statusReason: REPRESENTMENT_STATUS_REASON.PRE_ARB,
        type: REPRESENTMENT_TYPE.CBR,
      },
      data: {
        content: <SecondChargeback />,
      },
    },
    {
      conditions: {
        status: REPRESENTMENT_STATUS.ABANDONED,
        statusReason: REPRESENTMENT_STATUS_REASON.BACKFILL,
        type: REPRESENTMENT_TYPE.CBR,
      },
      data: {
        content: <NotChallenged />,
      },
    },
    {
      conditions: {
        status: REPRESENTMENT_STATUS.ABANDONED,
        statusReason: REPRESENTMENT_STATUS_REASON.MISSED,
        type: REPRESENTMENT_TYPE.CBR,
      },
      data: {
        content: <NotChallenged />,
      },
    },
    {
      conditions: {
        status: REPRESENTMENT_STATUS.ABANDONED,
        statusReason: REPRESENTMENT_STATUS_REASON.RETURNED,
        type: REPRESENTMENT_TYPE.CBR,
      },
      data: {
        content: i18nInstance.t(
          'claimsBanner.representmentDetails.notChallengedReturned'
        ),
      },
    },
    {
      conditions: {
        status: REPRESENTMENT_STATUS.ABANDONED,
        statusReason: REPRESENTMENT_STATUS_REASON.OPT_OUT,
        type: REPRESENTMENT_TYPE.CBR,
      },
      data: {
        content: <NotChallenged />,
      },
    },
    {
      conditions: {
        status: REPRESENTMENT_STATUS.ABANDONED,
        statusReason: REPRESENTMENT_STATUS_REASON.UNFULFILLED,
        type: REPRESENTMENT_TYPE.CBR,
      },
      data: {
        content: i18nInstance.t(
          'claimsBanner.representmentDetails.notChallengedUnfulfilled'
        ),
      },
    },
    {
      conditions: {
        status: REPRESENTMENT_STATUS.ABANDONED,
        statusReason: REPRESENTMENT_STATUS_REASON.DUPLICATE,
        type: REPRESENTMENT_TYPE.CBR,
      },
      data: {
        content: <NotChallenged />,
      },
    },
    {
      conditions: {
        status: REPRESENTMENT_STATUS.CLOSED,
        outcome: REPRESENTMENT_OUTCOME.NONE,
      },
      data: {
        content: <ChallengedNoOutcome />,
      },
    },
    {
      conditions: {
        status: REPRESENTMENT_STATUS.CLOSED,
        outcome: REPRESENTMENT_OUTCOME.WON,
      },
      data: {
        content: i18nInstance.t(
          'claimsBanner.representmentDetails.chargebackWon'
        ),
      },
    },
    {
      conditions: {
        status: REPRESENTMENT_STATUS.CLOSED,
        outcome: REPRESENTMENT_OUTCOME.LOST,
      },
      data: {
        content: i18nInstance.t(
          'claimsBanner.representmentDetails.chargebackLost'
        ),
      },
    },
  ]

  const conditions: Conditions = {
    status,
    statusReason,
    outcome,
    type,
  }

  const matches = (source: Conditions, match: Conditions): boolean => {
    const propertiesToMatch = Object.keys(match) as Array<keyof Conditions>

    return propertiesToMatch.every(
      (property) => source[property] === match[property]
    )
  }

  return detailsConfigurations.find((config) =>
    matches(conditions, config.conditions)
  )?.data.content
}

export default getRepresentmentDetails
