import { Flex } from 'antd'
import { isNil } from 'lodash'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'store'
import { spacingXL } from '@signifyd/ant'
import { Text, TextThirdGen, useIsThirdGen } from '@signifyd/components'
import { SEARCH_FIELD } from '@signifyd/http'
import { QUICKVIEW_KEYS } from 'store/search'
import AdvancedFiltersItem from './AdvancedFiltersItem'
import ClaimStatusItem from './ClaimStatusItem'
import GuaranteeStatusItem from './GuaranteeStatusItem'
import OrderAmountItem from './OrderAmountItem'
import RecommendationStatusItem from './RecommendationStatusItem'
import ScoreItem from './ScoreItem'
import styles from './SearchSummary.less'
import ShippingMethodItem from './ShippingMethodItem'
import SummaryItem from './SummaryItem'

interface Props {
  placeholderText: string
}

const SearchSummary: FC<Props> = ({ placeholderText }: Props) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()
  const searchTerm = useStoreState((state) => state.search.searchTerm)
  const selectedQuickView = useStoreState(
    (state) => state.search.selectedQuickView
  )
  const selectedSavedFilter = useStoreState(
    (state) => state.search.selectedSavedFilter
  )

  const isQuickViewSearch = selectedQuickView || selectedSavedFilter

  const filters = useStoreState((state) => state.search.filters)
  const {
    guaranteeDisposition,
    claimStatus,
    claimDisposition,
    signifydScore,
    orderTotalAmount,
    caseStatus,
    caseType,
    investigationReviewDisposition,
    authorizationGatewayStatus,
    isTestInvestigation,
    guaranteeRecommendedAction,
    shippingMethod,
    returnDecisionCheckpointAction,
    recommendedDecisionDisposition,
  } = filters

  const claimStatusCombined = [...claimStatus, ...claimDisposition]

  const maxMinFilters = [
    SEARCH_FIELD.orderTotalAmount,
    SEARCH_FIELD.signifydScore,
  ]

  const hasFilters = Object.entries(filters).some(([key, value]) => {
    if (maxMinFilters.includes(key as SEARCH_FIELD)) {
      if (!value || typeof value === 'string') {
        return false
      }

      if ('max' in value && 'min' in value) {
        return value.max !== null && value.min !== null
      }
    }

    return Array.isArray(value) ? value.length > 0 : false
  })

  const hidePlaceHolder = hasFilters || isQuickViewSearch

  return (
    <Flex flex={1} align="center" gap={spacingXL}>
      {selectedQuickView && (
        <SummaryItem
          label={t('search.summary.label.quickView')}
          value={t(
            `search.quickSearch.${selectedQuickView.title as QUICKVIEW_KEYS}`
          )}
        />
      )}
      {selectedSavedFilter && (
        <SummaryItem
          label={t('search.summary.label.savedFilter')}
          value={selectedSavedFilter.title}
        />
      )}

      {isThirdGen && !hidePlaceHolder && (
        <TextThirdGen className={styles.placeHolderText}>
          {placeholderText}
        </TextThirdGen>
      )}

      {!isThirdGen && !hidePlaceHolder && (
        <Text className={styles.placeHolderText}>{placeholderText}</Text>
      )}

      {!isQuickViewSearch && (
        <>
          {searchTerm && (
            <SummaryItem
              label={t('search.summary.label.keyword')}
              value={searchTerm}
            />
          )}

          {!!guaranteeDisposition?.length && (
            <GuaranteeStatusItem values={guaranteeDisposition} />
          )}

          {!!recommendedDecisionDisposition?.length && (
            <RecommendationStatusItem values={recommendedDecisionDisposition} />
          )}

          {!!claimStatusCombined?.length && (
            <ClaimStatusItem values={claimStatusCombined} />
          )}

          {(orderTotalAmount?.min || orderTotalAmount?.max) && (
            <OrderAmountItem values={orderTotalAmount} />
          )}

          {(!isNil(signifydScore.min) || !isNil(signifydScore.max)) && (
            <ScoreItem values={signifydScore} />
          )}

          {!!shippingMethod?.length && (
            <ShippingMethodItem values={shippingMethod} />
          )}

          {!!guaranteeRecommendedAction?.length && (
            <AdvancedFiltersItem
              filterKey={SEARCH_FIELD.guaranteeRecommendedAction}
              values={guaranteeRecommendedAction}
            />
          )}

          {!!returnDecisionCheckpointAction?.length && (
            <AdvancedFiltersItem
              filterKey={SEARCH_FIELD.returnDecisionCheckpointAction}
              values={returnDecisionCheckpointAction}
            />
          )}

          {!!caseStatus?.length && (
            <AdvancedFiltersItem
              filterKey={SEARCH_FIELD.caseStatus}
              values={caseStatus}
            />
          )}

          {!!caseType?.length && (
            <AdvancedFiltersItem
              filterKey={SEARCH_FIELD.caseType}
              values={caseType}
            />
          )}

          {!!investigationReviewDisposition?.length && (
            <AdvancedFiltersItem
              filterKey={SEARCH_FIELD.investigationReviewDisposition}
              values={investigationReviewDisposition}
            />
          )}

          {!!authorizationGatewayStatus?.length && (
            <AdvancedFiltersItem
              filterKey={SEARCH_FIELD.authorizationGatewayStatus}
              values={authorizationGatewayStatus}
            />
          )}

          {!!isTestInvestigation.length && (
            <AdvancedFiltersItem
              filterKey={SEARCH_FIELD.isTestInvestigation}
              values={isTestInvestigation}
            />
          )}
        </>
      )}
    </Flex>
  )
}

export default SearchSummary
