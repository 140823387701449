import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useStoreActions } from 'store'
import { createGuaranteeDisposition, InvestigationInfo } from '@signifyd/http'

export const useSubmitGuarantee = (): UseMutationResult<
  void,
  void,
  Pick<InvestigationInfo, 'investigationId'>
> => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'store.caseActions.submitGuarantee',
  })
  const { refreshCurrentCase } = useStoreActions((store) => store.currentCase)

  return useMutation({
    mutationFn: async ({ investigationId }) => {
      await createGuaranteeDisposition(investigationId)
    },
    onSuccess: async () => {
      refreshCurrentCase()
      await message.success(t('apiSuccess'))
    },
    onError: async () => {
      await message.error(t('apiFailure'))
    },
  })
}
