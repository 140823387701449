import { PaperClipOutlined } from '@ant-design/icons'
import { Input, Upload } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AttachFileButton,
  AttachFilesForUploadHook,
  Space,
  Text,
} from '@signifyd/components'
import {
  ALLOWED_UPLOAD_FILE_TYPES_NOTE_ATTACHMENT,
  maxNoteLength,
} from 'core/constants'

const { TextArea } = Input

interface Props {
  note: string
  setNote: (value: string) => void
  fileAttacher: AttachFilesForUploadHook
}

const ResubmitGuaranteeModalContent: FC<Props> = ({
  note,
  setNote,
  fileAttacher,
}) => {
  const { t } = useTranslation()
  const { onAttachFiles, onRemoveFile, files } = fileAttacher

  return (
    <>
      <div data-test-id="resubmitGuaranteeModalInput">
        <Text type="secondary">
          {t('caseActions.reSubmitGuarantee.helpText')}
        </Text>
        <TextArea
          onChange={(e) => setNote(e.target.value)}
          value={note}
          autoSize={{ minRows: 4 }}
          maxLength={maxNoteLength}
          data-test-id="resubmitGuarantee-textArea"
        />
        <Space size={16} />
        <Text type="secondary">
          {t('caseActions.reSubmitGuarantee.additionalFiles')}
        </Text>
        <Space size={4} />
        <AttachFileButton
          onAttach={onAttachFiles}
          accept={ALLOWED_UPLOAD_FILE_TYPES_NOTE_ATTACHMENT}
          dataAnalyticsId="resubmit-guarantee-modal-content-file-button"
          dataTestId="resubmitGuaranteeModalContentFileButton"
        >
          <PaperClipOutlined />
          {t('caseActions.reSubmitGuarantee.uploadText')}
        </AttachFileButton>
        <Upload
          onRemove={onRemoveFile}
          showUploadList={{
            showPreviewIcon: false,
            showDownloadIcon: false,
          }}
          fileList={files}
        />
      </div>
    </>
  )
}

export default ResubmitGuaranteeModalContent
