import { Button, Flex, Popconfirm, Typography } from 'antd'
import { isEqual, cloneDeep } from 'lodash'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { spacingXS } from '@signifyd/ant'
import { colorGold } from '@signifyd/colors'
import { CircleExclamation, TextThirdGen } from '@signifyd/components'
import styles from './ThirdGenTableColumnSelectDrawer.less'
import { ColumnConfig, getDefaultColumnConfigs } from '../columns'

interface Props {
  columns: Array<ColumnConfig>
  onSetColumns: (columns: Array<ColumnConfig>) => void
  clearChanges: () => void
  applyChanges: () => void
  hasChanges: boolean
  handleConditionallyClear: () => void
  hasAirlineOrders?: boolean
}

const { Title } = Typography

const TableColumnSelectFooter: FC<Props> = ({
  columns,
  onSetColumns,
  clearChanges,
  applyChanges,
  hasChanges,
  handleConditionallyClear,
  hasAirlineOrders,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.results' })

  const isDefaultSet = useMemo(() => {
    return isEqual(columns, getDefaultColumnConfigs())
  }, [columns])

  return (
    <Flex align="center" justify="space-between">
      <Button
        data-test-id="resetColumnsButton"
        onClick={() => {
          onSetColumns(cloneDeep(getDefaultColumnConfigs(hasAirlineOrders)))
        }}
        type="link"
        disabled={isDefaultSet}
      >
        {t('columnSelect.close.reset')}
      </Button>
      <Flex gap={spacingXS}>
        <Popconfirm
          overlayClassName="sig-popconfirm"
          disabled={hasChanges}
          title={null}
          icon={null}
          description={
            <Flex gap={spacingXS}>
              <CircleExclamation
                className={styles.popconfirmIcon}
                fill={colorGold}
              />
              <Flex vertical gap={spacingXS}>
                <Title level={3}>{t('columnSelect.close.confirmTitle')}</Title>
                <TextThirdGen>
                  {t('columnSelect.close.confirmText')}
                </TextThirdGen>
              </Flex>
            </Flex>
          }
          onConfirm={clearChanges}
          okText={t('columnSelect.close.confirmClose')}
          cancelText={t('columnSelect.close.no')}
        >
          <Button
            data-test-id="cancelButtonBottom"
            onClick={handleConditionallyClear}
            type="text"
          >
            {t('columnSelect.cancel')}
          </Button>
        </Popconfirm>
        <Button
          onClick={applyChanges}
          disabled={hasChanges}
          data-test-id="okApplyButton"
          type="primary"
        >
          {t('columnSelect.apply')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default TableColumnSelectFooter
