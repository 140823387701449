import { getCaseSearchUrl, i18nInstance } from '@signifyd/components'
import { googleSearchLink, linkedInSearchLink } from '../DeepLinkConstants'
import {
  MENU_SECTION_TYPE,
  MENU_ICON,
  MenuItem,
  PeopleDeepLinkProps,
  DeepLinkFactory,
} from '../DeepLinkDropdown.types'

const getDeepLinksPeople: DeepLinkFactory<PeopleDeepLinkProps> = ({
  caseEntries,
  type,
  hasPowersearch,
}): Array<MenuItem> => {
  const { data, filter } =
    type === MENU_SECTION_TYPE.cardHolder
      ? { data: caseEntries.cardHolder, filter: 'cardHolderName' }
      : { data: caseEntries.deliveryRecipient, filter: 'recipientFullName' }

  const {
    cardHolder,
    accountHolderPhone,
    cardHolderAddress,
    confirmationPhone,
    deliveryRecipient,
    deliveryRecipientAddress,
    confirmationEmail,
    purchaseIP,
  } = caseEntries

  const encodedSigSearchURL = `${getCaseSearchUrl()}?${filter}=${
    data?.entityName
  }`

  // build a Google Search URL with Full Name/City/Region
  const googleFullNameCityRegionSearchURL =
    type === MENU_SECTION_TYPE.cardHolder
      ? `${googleSearchLink}${data?.entityName} ${cardHolderAddress?.details?.cityName} ${cardHolderAddress?.details?.regionAlpha}`
      : `${googleSearchLink}${data?.entityName} ${deliveryRecipientAddress?.details?.cityName} ${deliveryRecipientAddress?.details?.regionAlpha}`

  // build a Google Search URL with Last Name/City/Region
  const googleLastNameCityRegionSearchURL =
    type === MENU_SECTION_TYPE.cardHolder
      ? `${googleSearchLink}${data?.details?.familyName} ${cardHolderAddress?.details?.cityName} ${cardHolderAddress?.details?.regionAlpha}`
      : `${googleSearchLink}${data?.details?.familyName} ${deliveryRecipientAddress?.details?.cityName} ${deliveryRecipientAddress?.details?.regionAlpha}`

  // build a Linkedin URL
  const linkedinURL = linkedInSearchLink + String(data?.entityName)

  // build a Ekata `indentity_check` URL
  const ekataIdentityCheckURL = (): string | undefined => {
    // If we have no values to work with -> return undefined
    if (
      !cardHolder &&
      !accountHolderPhone &&
      !cardHolderAddress &&
      !confirmationPhone &&
      !deliveryRecipient &&
      !deliveryRecipientAddress &&
      !confirmationEmail &&
      !purchaseIP
    ) {
      return undefined
    }

    // else let's construct a url
    const baseUrl = 'https://app.ekata.com/identity_checks'
    const addressArr = []

    if (cardHolder?.entityName) {
      addressArr.push(`billing_name=${cardHolder.entityName}`)
    }

    const phoneNumber =
      accountHolderPhone?.entityName ?? confirmationPhone?.entityName

    if (phoneNumber) {
      addressArr.push(`phone=${phoneNumber}`)
    }

    if (cardHolderAddress?.details) {
      const { street, cityName, regionAlpha, countryIsoCode, unit } =
        cardHolderAddress.details

      if (street) {
        addressArr.push(`billing_address_street_line_1=${street}`)
      }

      if (unit) {
        addressArr.push(`billing_address_street_line_2=${unit}`)
      }

      if (cityName) {
        addressArr.push(`billing_address_city=${cityName}`)
      }

      if (regionAlpha) {
        addressArr.push(`billing_address_state_code=${regionAlpha}`)
      }
      if (countryIsoCode) {
        addressArr.push(`billing_address_country_code=${countryIsoCode}`)
      }
    }

    if (deliveryRecipient?.entityName) {
      addressArr.push(`shipping_name=${deliveryRecipient.entityName}`)
    }

    if (deliveryRecipientAddress?.details) {
      const { street, cityName, regionAlpha, countryIsoCode, unit } =
        deliveryRecipientAddress.details

      if (street) {
        addressArr.push(`shipping_address_street_line_1=${street}`)
      }
      if (unit) {
        addressArr.push(`shipping_address_street_line_2=${unit}`)
      }
      if (cityName) {
        addressArr.push(`shipping_address_city=${cityName}`)
      }
      if (regionAlpha) {
        addressArr.push(`shipping_address_state_code=${regionAlpha}`)
      }
      if (countryIsoCode) {
        addressArr.push(`shipping_address_country_code=${countryIsoCode}`)
      }
    }

    if (confirmationEmail?.details?.emailAddress) {
      addressArr.push(`email_address=${confirmationEmail.details.emailAddress}`)
    }

    if (purchaseIP?.details?.ipAddress) {
      addressArr.push(`ip_address=${purchaseIP.details.ipAddress}`)
    }

    return `${baseUrl}?${addressArr.join('&')}`
  }

  return [
    hasPowersearch && {
      icon: MENU_ICON.SigLogoSmall,
      text: i18nInstance.t('pages.caseReview.details.deepLinks.sigSearch'),
      url: encodedSigSearchURL,
    },
    {
      icon: MENU_ICON.GoogleSearchIcon,
      text: i18nInstance.t(
        'pages.caseReview.details.deepLinks.googleFullNameAddressSearch'
      ),
      url: googleFullNameCityRegionSearchURL,
    },
    {
      icon: MENU_ICON.GoogleSearchIcon,
      text: i18nInstance.t(
        'pages.caseReview.details.deepLinks.googleLastNameAddressSearch'
      ),
      url: googleLastNameCityRegionSearchURL,
    },
    {
      icon: MENU_ICON.EkataIcon,
      text: i18nInstance.t(
        'pages.caseReview.details.deepLinks.ekataIdentityCheck'
      ),
      url: ekataIdentityCheckURL() || '',
    },
    {
      icon: MENU_ICON.LinkedinIcon,
      text: i18nInstance.t(
        'pages.caseReview.details.deepLinks.linkedinFullNameSearch'
      ),
      url: linkedinURL,
    },
  ].filter((deepLink): deepLink is MenuItem => !!deepLink)
}

export default getDeepLinksPeople
