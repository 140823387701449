import moment, { Moment } from 'moment-timezone'
import { FC, memo, RefObject, useState } from 'react'
import { useStoreState } from 'store'
import { DatePicker, RangePickerValue } from '@signifyd/components'
import { SEARCH_FIELD } from '@signifyd/http'
import {
  getMaxFlexibleSearchIsoDate,
  getMinFlexibleSearchIsoDate,
} from '@signifyd/utils'
import { DATE_FORMAT } from 'core/constants'
import {
  getDateTimezone,
  getDefaultDateRange,
  getPastStartDate,
} from 'core/utils/date.utils'
import { FilterState, RangeFilter } from 'store/search'

const dateFormat = DATE_FORMAT.date

const { RangePicker } = DatePicker

interface Props {
  value: RangeFilter<string>
  updateFilterFn: (update: Partial<FilterState>) => void
  scrollRef?: RefObject<HTMLDivElement>
  className?: string
  separator?: string
}

const DateRangeFilter: FC<Props> = ({
  value,
  updateFilterFn,
  scrollRef,
  className,
}) => {
  const [pickerOpen, setPickerOpen] = useState(false)
  const timeZone = useStoreState((state) => state.user.timeZone.name)

  const twoYearsAgoMoment = getPastStartDate({
    subtractInterval: 'year',
    subtractValue: 2,
  })

  const defaultDateRange = getDefaultDateRange()

  const maxPastDate = moment(twoYearsAgoMoment).subtract(1, 'day')
  const disabledDatesTwoYearsAgo = (currentDate?: Moment | null): boolean => {
    return currentDate ? currentDate < maxPastDate : false
  }

  const minDate = value.min || defaultDateRange.min
  const maxDate = value.max || defaultDateRange.max

  const minValue = getDateTimezone({ date: minDate, timeZone })
  const maxValue = getDateTimezone({ date: maxDate, timeZone })

  const handleChange = (value: RangePickerValue): void => {
    if (!value) {
      return
    }
    const [min, max] = value

    updateFilterFn({
      [SEARCH_FIELD.normalizedPurchaseCreatedAt]: {
        min: min ? getMinFlexibleSearchIsoDate(min) : defaultDateRange.min,
        max: max ? getMaxFlexibleSearchIsoDate(max) : defaultDateRange.max,
      },
    })
    setPickerOpen(false)
  }

  return (
    <RangePicker
      style={{ width: '280px', paddingRight: 'auto 8px' }}
      className={className}
      onChange={handleChange}
      format={dateFormat}
      disabledDate={disabledDatesTwoYearsAgo}
      value={[minValue, maxValue]}
      allowClear
      open={pickerOpen}
      onOpenChange={setPickerOpen}
      data-test="search-date-range"
      getPopupContainer={(triggerNode: Element) => {
        return scrollRef?.current ?? (triggerNode.parentNode as HTMLElement)
      }}
    />
  )
}

export default memo(DateRangeFilter)
