import { FC } from 'react'
import { useNavigate } from 'react-router'
import { useStoreActions } from 'store'
import { SEARCH_FIELD } from '@signifyd/http'
import DateRangeFilter from 'core/components/SearchAndFilter/FiltersContainer/DateRangeFilter'
import { getSelectedQuickDateFilter } from 'core/utils/quickDateFilter/quickDateFilter'
import { FilterState } from 'store/search'
import DatePickerTag from './DatePickerTag'
import styles from './HeadlineDatePicker.less'

interface Props {
  filters: FilterState
  updateFilters: (filters: FilterState) => void
}

export const dateOptions: Array<number> = [30, 14, 7, 1]

const HeadlineDatePicker: FC<Props> = ({ filters, updateFilters }) => {
  const { normalizedPurchaseCreatedAt: dates } = filters

  const { searchCases } = useStoreActions((actions) => actions.search)
  const navigate = useNavigate()

  return (
    <div className={styles.container}>
      {dateOptions.map((dayCount) => {
        const isSelected = getSelectedQuickDateFilter(dates, dayCount)

        return (
          <DatePickerTag
            dayCount={dayCount}
            filters={filters}
            isSelected={isSelected}
            updateFilters={updateFilters}
            key={dayCount}
          />
        )
      })}

      <DateRangeFilter
        value={dates}
        updateFilterFn={(newFilters) => {
          updateFilters({
            ...filters,
            ...{
              [SEARCH_FIELD.normalizedPurchaseCreatedAt]:
                newFilters.normalizedPurchaseCreatedAt,
            },
          } as FilterState)
          searchCases({
            quickSearchKey: undefined,
            saveAs: '',
            savedFilterKey: undefined,
            navigate,
          })
        }}
      />
    </div>
  )
}

export default HeadlineDatePicker
