import { Drawer } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClockRotateLeft } from '@signifyd/components'
import ActionIconButton from 'pages/CaseReviewPage/components/ActionIconButton/ActionIconButton'
import CaseActivityThirdGen from '../CaseActivityThirdGen'

const CaseActivityDrawerThirdGen: FC = () => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <ActionIconButton
        Icon={ClockRotateLeft}
        testId="orderActivityButton"
        onClick={() => setIsOpen(true)}
        tooltip={t('caseActivity.drawerTitle')}
      />
      <Drawer
        title={t('caseActivity.drawerTitle')}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        width={480}
      >
        <CaseActivityThirdGen />
      </Drawer>
    </>
  )
}

export default CaseActivityDrawerThirdGen
