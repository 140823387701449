import { Descriptions } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { InvestigationInfo, PREDICTION_LIST_TYPE } from '@signifyd/http'
import CopyWrapper from 'core/components/CopyWrapper'
import NoInformation from 'core/components/NoInformation'
import OrderAttributeLists from 'core/components/OrderAttributeLists'
import SeederLabel from 'pages/CaseReviewPage/components/SeederLabel'
import { CaseEntriesResolvedState } from 'store/currentCase/types/case.entries.types'
import { PhoneIntelligence } from './PhoneIntelligence'
import DeepLinkDropdown from '../components/DeepLinkDropdown'
import {
  MENU_SECTION,
  MENU_SECTION_TYPE,
} from '../components/DeepLinkDropdown/DeepLinkDropdown.types'

const { Item: DescriptionItem } = Descriptions

export interface PhoneProps {
  caseDetails: InvestigationInfo
  caseEntries: CaseEntriesResolvedState
}

const Phone: FC<PhoneProps> = ({ caseEntries, caseDetails }) => {
  const { t } = useTranslation()

  const { confirmationPhone, accountHolderPhone } = caseEntries
  const { recipients, userAccountPhone } = caseDetails

  const recipientConfirmationPhone = recipients?.[0]?.confirmationPhone

  const noInfo = !confirmationPhone && !userAccountPhone
  const sectionName = t('pages.caseReview.details.phone')

  const phonesAreIdentical = recipientConfirmationPhone === userAccountPhone

  return (
    <>
      <Descriptions title={sectionName} colon={false} size="small">
        {recipientConfirmationPhone && (
          <DescriptionItem
            label={
              <SeederLabel
                label={t('pages.caseReview.details.confirmationPhone')}
              />
            }
          >
            <CopyWrapper text={recipientConfirmationPhone}>
              <DeepLinkDropdown
                section={MENU_SECTION.phone}
                type={MENU_SECTION_TYPE.confirmationPhone}
              >
                {recipientConfirmationPhone}
              </DeepLinkDropdown>
            </CopyWrapper>
          </DescriptionItem>
        )}
      </Descriptions>

      {!phonesAreIdentical &&
        confirmationPhone?.details?.wppReversePhoneJson && (
          <PhoneIntelligence
            ekata={confirmationPhone.details.wppReversePhoneJson}
          />
        )}

      {recipientConfirmationPhone && (
        <OrderAttributeLists
          type={PREDICTION_LIST_TYPE.PHONE_NUMBER}
          value={recipientConfirmationPhone}
          analyticsId="confirmation-phone"
        />
      )}

      {userAccountPhone && (
        <>
          <Descriptions colon={false} size="small">
            <DescriptionItem
              label={
                <SeederLabel
                  label={t('pages.caseReview.details.accountHolderPhone')}
                />
              }
            >
              <CopyWrapper text={userAccountPhone}>
                <DeepLinkDropdown
                  section={MENU_SECTION.phone}
                  type={MENU_SECTION_TYPE.accountPhone}
                >
                  {userAccountPhone}
                </DeepLinkDropdown>
              </CopyWrapper>
            </DescriptionItem>
          </Descriptions>

          {accountHolderPhone?.details?.wppReversePhoneJson && (
            <PhoneIntelligence
              ekata={accountHolderPhone?.details?.wppReversePhoneJson}
            />
          )}

          <OrderAttributeLists
            type={PREDICTION_LIST_TYPE.PHONE_NUMBER}
            value={userAccountPhone}
            analyticsId="account-phone"
          />
        </>
      )}

      {noInfo && <NoInformation />}
    </>
  )
}

export default Phone
