import { Tooltip } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TextThirdGen } from '@signifyd/components'
import StatusTagThirdGen from '@signifyd/components/src/thirdGenComponents/StatusTagThirdGen'
import { Decision } from '@signifyd/http'
import ReturnPolicy from 'pages/SearchResultsPage/components/SearchResultsTable/ReturnPolicy'
import { DisplayData } from './getReturnTagCheckpointData'
import styles from './ReturnTagThirdGen.less'

interface Props {
  decision: Decision
  displayData: DisplayData
}

const ReturnWithPolicyThirdGen: FC<Props> = ({ decision, displayData }) => {
  const { t } = useTranslation()
  const { checkpointAction, checkpointActionPolicy, policies } = decision

  return (
    <Tooltip
      title={
        <div className={styles.tooltip}>
          <TextThirdGen>
            {t(`returnTag.tooltip.returnSetTo.${checkpointAction}`)}
          </TextThirdGen>

          <ReturnPolicy
            policies={policies}
            checkpointActionPolicy={checkpointActionPolicy}
          />
        </div>
      }
    >
      <StatusTagThirdGen
        data-test-id="returnWithPolicyTag"
        className={styles[displayData.className]}
        icon={displayData.icon}
      >
        {displayData.label}
      </StatusTagThirdGen>
    </Tooltip>
  )
}

export default ReturnWithPolicyThirdGen
