import { Spin } from 'antd'
import { FC, useState } from 'react'
import { useStoreActions } from 'store'
import { useFileAttacher } from '@signifyd/components'
import { maxNoteLength } from 'core/constants'
import NoteInput from './NoteInput'
import NoteModal from './NoteModal'

interface Props {
  caseId: number
  onModalOpenChange: (isOpen: boolean) => void
  isModalOpen: boolean
}

export const ThirdGenNoteInput: FC<Props> = ({
  caseId,
  onModalOpenChange,
  isModalOpen,
}) => {
  // Local State
  const [note, setNote] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)

  const noteTooLong = note.length > maxNoteLength
  const isSubmitDisabled = !note.trim() || noteTooLong

  const fileAttacher = useFileAttacher()
  const { files, clearFiles } = fileAttacher

  // Store State / Actions
  const { addNoteWithFiles } = useStoreActions((actions) => actions.currentCase)

  const onSubmitNote = async (): Promise<void> => {
    if (!note) {
      return
    }

    setIsProcessing(true)
    onModalOpenChange(false)

    await addNoteWithFiles({ caseId, text: note, files })

    clearFiles()
    setIsProcessing(false)
    setNote('')
  }

  return (
    <Spin spinning={isProcessing} delay={250}>
      <NoteInput
        value={note}
        isSubmitDisabled={isSubmitDisabled}
        onChange={setNote}
        onSubmitNote={onSubmitNote}
        maxNoteLength={maxNoteLength}
        onShowModal={() => onModalOpenChange(true)}
        fileAttacher={fileAttacher}
      />

      <NoteModal
        maxLength={maxNoteLength}
        visible={isModalOpen}
        note={note}
        onChange={setNote}
        isSubmitDisabled={isSubmitDisabled}
        onSubmit={onSubmitNote}
        onCancel={() => onModalOpenChange(false)}
        attachedFilesData={fileAttacher}
      />
    </Spin>
  )
}

export default ThirdGenNoteInput
