import { FC } from 'react'
import { TextThirdGen } from '@signifyd/components'
import { CaseEntriesItem, CaseEntryLocationDetails } from '@signifyd/http'

interface Props {
  ipGeo: CaseEntriesItem<CaseEntryLocationDetails> | undefined
}

const IPGeo: FC<Props> = ({ ipGeo }) => {
  return <TextThirdGen>{ipGeo?.entityName}</TextThirdGen>
}

export default IPGeo
