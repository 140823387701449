import { Card, Flex, Table } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { spacingLG } from '@signifyd/ant'
import { ErrorBoundary, TitleThirdGen } from '@signifyd/components'
import { InvestigationInfo, Passenger } from '@signifyd/http'
import { getTravelColumns } from '../travelColumns.config'
import AirlineTripDetailsThirdGen from './AirlineTripDetails/AirlineTripDetailsThirdGen'

interface Props {
  investigation: InvestigationInfo
}

const getPassengers = (investigation: InvestigationInfo): Array<Passenger> => {
  const passengersMap = new Map<string, Passenger>()

  investigation.products.forEach((product) => {
    const rawPassengers = product.travel?.passengers ?? []

    rawPassengers.forEach((passenger) => {
      if (!passenger.passengerId) {
        return
      }

      const existingPassenger = passengersMap.get(passenger.passengerId)

      if (existingPassenger) {
        const ancillaries = [
          ...(existingPassenger.ancillaryPurchases ?? []),
          ...(passenger.ancillaryPurchases ?? []),
        ]

        existingPassenger.ancillaryPurchases = ancillaries
      } else {
        passengersMap.set(passenger.passengerId, passenger)
      }
    })
  })

  return [...passengersMap.values()]
}

export const TravelDetailsThirdGen: FC<Props> = ({ investigation }) => {
  const { t } = useTranslation()

  const passengersTableData = getPassengers(investigation)

  const travelData = investigation.products
    .flatMap((product) => product.travel?.airlineTripDetail ?? [])
    .filter(Boolean)

  const columns = getTravelColumns()

  return (
    <Card
      title={
        <TitleThirdGen level={2}>{t('airline.travelDetails')}</TitleThirdGen>
      }
      data-test-id="travelDetails"
      data-analytics-id="travel-details"
    >
      <Flex gap={spacingLG} vertical>
        <ErrorBoundary message={t('errorBoundaries.travelDetails')}>
          <TitleThirdGen level={3}>{t('airline.passengers')}</TitleThirdGen>

          <Table
            rowKey="passengerId"
            dataSource={passengersTableData}
            columns={columns}
            scroll={{ x: true }}
            pagination={false}
            size="small"
            bordered
          />
        </ErrorBoundary>
        <ErrorBoundary message={t('errorBoundaries.airlineTripDetails')}>
          <AirlineTripDetailsThirdGen travelData={travelData} />
        </ErrorBoundary>
      </Flex>
    </Card>
  )
}

export default TravelDetailsThirdGen
