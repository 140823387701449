import { LockFilled } from '@ant-design/icons'
import { Button, Flex, Tooltip } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { SEARCH_FIELD } from '@signifyd/http'
import styles from './TableColumnListItem.less'

interface Props {
  isLocked: boolean
  isHidden: boolean
  title: SEARCH_FIELD
  onToggleHidden: () => void
}

const TableColumnListItem: FC<Props> = ({
  isLocked,
  title,
  isHidden,
  onToggleHidden,
}) => {
  const { t } = useTranslation()
  const columnTitle = t(`pages.results.columnSelect.keys.${title}`)

  const lockedItem = (
    <Tooltip
      placement="top"
      arrow={{ pointAtCenter: true }}
      title={<div>{t('pages.results.columnSelect.tooltip')}</div>}
    >
      <Flex align="center">
        <LockFilled data-test-id="column-locked" className={styles.lockIcon} />
        <Text className={styles.lockedItem}>{columnTitle}</Text>
      </Flex>
    </Tooltip>
  )

  const item = (
    <Flex justify="space-between" align="center">
      <Text>{columnTitle}</Text>
      <Button
        type="link"
        data-test-id={`${title}-drawer-button-${isHidden ? 'show' : 'hide'}`}
        onClick={onToggleHidden}
      >
        {t(`pages.results.columnSelect.${isHidden ? 'show' : 'hide'}Column`)}
      </Button>
    </Flex>
  )

  return (
    <div className={styles.itemContainer}>{isLocked ? lockedItem : item}</div>
  )
}

export default TableColumnListItem
