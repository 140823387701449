import { Flex } from 'antd'
import { FC } from 'react'
import { spacingSM } from '@signifyd/ant'
import { TextThirdGen, ThirdGenSeederIcon } from '@signifyd/components'
import AdditionalValues from 'core/components/AdditionalValues'
import styles from './EnhancedSeederLabel.less'

export interface EnhancedSeederLabelProps {
  label: string
  values?: Array<string>
  dataTestId?: string
}

const EnhancedSeederLabel: FC<EnhancedSeederLabelProps> = ({
  label,
  values = [],
  dataTestId,
}) => {
  const sortedValues = values.sort()

  return (
    <Flex gap={spacingSM}>
      <span data-test-id={dataTestId}>
        {label}
        {sortedValues.length > 0 && (
          <TextThirdGen data-test-id="enhancedSeederValues">
            ({sortedValues.slice(0, 2).join(', ')}
            <AdditionalValues values={sortedValues} />)
          </TextThirdGen>
        )}
      </span>
      <div data-test-id="seederLabelIcon">
        <ThirdGenSeederIcon className={styles.icon} />
      </div>
    </Flex>
  )
}

export default EnhancedSeederLabel
