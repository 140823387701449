import { Card, Row, Col } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { spacingMD, spacingXL } from '@signifyd/ant'
import { ErrorBoundary, Space, TitleThirdGen } from '@signifyd/components'
import { CaseOrder, InvestigationInfo } from '@signifyd/http'
import { isTransportAirline } from 'core/utils/isTransportAirline/isTransportAirline'
import { CaseEntriesResolvedState } from 'store/currentCase/types/case.entries.types'
import GoogleMap from '../../components/GoogleMap'
import AccountSummaryThirdGen from './AccountSummary/AccountSummaryThirdGen'
import styles from './CaseSummary.less'
import OrderSummaryThirdGen from './OrderSummaryThirdGen'
import PaymentSummaryThirdGen from './PaymentSummaryThirdGen'
import ShippingSummaryThirdGen from './ShippingSummaryThirdGen'

export interface CaseSummaryProps {
  orderDetails: CaseOrder | null
  caseDetails: InvestigationInfo
  caseEntries: CaseEntriesResolvedState
}

const FULL_ROW = 24
const HALF_ROW = 12

export const CaseSummaryThirdGen: FC<CaseSummaryProps> = ({
  orderDetails,
  caseDetails,
  caseEntries,
}) => {
  const { t } = useTranslation()
  const { physicalLocations } = caseEntries

  const isAirline = isTransportAirline(caseDetails.products)

  if (!orderDetails) {
    return null
  }

  return (
    <Card
      title={<TitleThirdGen level={2}>{t('caseSummary.title')}</TitleThirdGen>}
    >
      <Row gutter={[spacingXL, spacingXL]}>
        <Col md={{ span: FULL_ROW }} xl={{ span: HALF_ROW }}>
          <Row gutter={[spacingMD, spacingMD]}>
            <Col sm={{ span: FULL_ROW }} xl={{ span: HALF_ROW }}>
              <ErrorBoundary
                className={styles.errorBoundary}
                message={t('errorBoundaries.paymentSummary')}
              >
                <PaymentSummaryThirdGen
                  orderDetails={orderDetails}
                  caseDetails={caseDetails}
                />
              </ErrorBoundary>
            </Col>
            <Col sm={{ span: FULL_ROW }} xl={{ span: HALF_ROW }}>
              <AccountSummaryThirdGen
                orderDetails={orderDetails}
                caseDetails={caseDetails}
              />
            </Col>
            <Col span={FULL_ROW}>
              <ErrorBoundary
                className={styles.errorBoundary}
                message={t('errorBoundaries.orderSummary')}
              >
                <OrderSummaryThirdGen caseDetails={caseDetails} />
              </ErrorBoundary>
            </Col>
            {!isAirline && (
              <Col span={FULL_ROW}>
                <ErrorBoundary
                  className={styles.errorBoundary}
                  message={t('errorBoundaries.shippingSummary')}
                >
                  <ShippingSummaryThirdGen caseDetails={caseDetails} />
                </ErrorBoundary>
              </Col>
            )}
          </Row>
        </Col>

        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <ErrorBoundary
            className={styles.errorBoundary}
            message={t('errorBoundaries.locationDetails')}
          >
            <div className={styles.GoogleMapContainer}>
              <GoogleMap
                physicalLocations={physicalLocations}
                showResetButton={isAirline}
              />
              {!isAirline && (
                <a href="#locations" target="_self">
                  {t('caseSummary.order.viewAllLocations')}
                </a>
              )}
            </div>
          </ErrorBoundary>
        </Col>
      </Row>
      <Space size={24} />
    </Card>
  )
}

export default CaseSummaryThirdGen
