import { Select } from 'antd'
import { FC, forwardRef } from 'react'
import { SelectProps } from 'antd/lib/select'

interface Props extends SelectProps {
  options: Array<{ key: string; value: string }>
  name: string
}
interface PropsWithRef extends Props {
  forwardedRef: any
}

const KeyValueDropdown: FC<PropsWithRef> = ({
  options,
  name,
  forwardedRef,
  ...props
}) => (
  <Select
    data-analytics-id={`${name}-select`}
    data-test-id={`dropdown-${name}`}
    {...props}
    ref={forwardedRef}
    options={options.map(({ key, value }) => ({
      key,
      label: value,
      value: key,
      'data-analytics-id': `${name}-selected-${key}`,
      'data-test-id': `selected-${name}-${key}`,
    }))}
  />
)

export default forwardRef((props: Props, ref) => {
  return <KeyValueDropdown {...props} forwardedRef={ref} />
})
