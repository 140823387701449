import { Col, Flex, Tag } from 'antd'
import { startCase, toLower } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useStoreState } from 'store'
import { colorEmeraldLight40 } from '@signifyd/colors'
import { Text } from '@signifyd/components'
import { Event } from '@signifyd/http'
import { convertToTimestamp, tagColors } from '../event.utils'
import styles from './EventRecord.less'

interface Props {
  event: Event
  selectedEvent?: Event | null
  setSelectedEvent: (event: Event | null) => void
  showTags: boolean
  isComparingEvents: boolean
}

const EventRecord: FC<Props> = ({
  event,
  selectedEvent,
  setSelectedEvent,
  showTags,
  isComparingEvents,
}) => {
  const timeZone = useStoreState((state) => state.user.timeZone.name)
  const [isSelected, setIsSelected] = useState(false)

  useEffect(() => {
    const selectedEventFound =
      event.investigationEventId.uuid ===
      selectedEvent?.investigationEventId?.uuid

    setIsSelected(selectedEventFound)
  }, [event.investigationEventId.uuid, selectedEvent])

  const isNotComparingEvents = isSelected && !isComparingEvents

  return (
    <Flex gap={5} vertical justify="space-between" wrap="wrap">
      <Col>
        <Tag
          color={isNotComparingEvents && colorEmeraldLight40}
          className={styles.tag}
          onClick={
            !isComparingEvents
              ? () => setSelectedEvent(isSelected ? null : event)
              : undefined
          }
        >
          {event.name}
        </Tag>
      </Col>

      <Col>
        {showTags &&
          event.tags.map((tag) => {
            const tagName = startCase(toLower(tag))

            return (
              <Tag
                key={event.investigationEventId.uuid}
                color={tagColors[tag]}
                data-test-id={`tag-${tagName}-${event.investigationEventId.uuid}`}
              >
                {tagName}
              </Tag>
            )
          })}
        <Text size="sm">{convertToTimestamp(event.processedAt, timeZone)}</Text>
      </Col>
    </Flex>
  )
}

export default EventRecord
