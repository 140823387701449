import { Descriptions } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { InvestigationInfo, PREDICTION_LIST_TYPE } from '@signifyd/http'
import CopyWrapper from 'core/components/CopyWrapper'
import NoInformation from 'core/components/NoInformation'
import OrderAttributeLists from 'core/components/OrderAttributeLists'
import SeederLabel from 'pages/CaseReviewPage/components/SeederLabel'
import { CaseEntriesResolvedState } from 'store/currentCase/types/case.entries.types'
import DeepLinkDropdown from '../components/DeepLinkDropdown'
import {
  MENU_SECTION,
  MENU_SECTION_TYPE,
} from '../components/DeepLinkDropdown/DeepLinkDropdown.types'

const { Item: DescriptionItem } = Descriptions

export interface EmailAccountsProps {
  caseEntries: CaseEntriesResolvedState
  caseDetails: InvestigationInfo
}

const EmailAccounts: FC<EmailAccountsProps> = ({
  caseEntries,
  caseDetails,
}) => {
  const { t } = useTranslation()

  const { confirmationEmail, accountEmail } = caseEntries

  const { recipients, userAccountEmail } = caseDetails

  const rawConfirmationEmail = recipients?.[0]?.confirmationEmail

  const noInfo = !confirmationEmail && !accountEmail
  const sectionName = t('pages.caseReview.details.emailAccounts')

  return (
    <>
      <Descriptions title={sectionName} colon={false} size="small">
        {confirmationEmail?.entityName && (
          <DescriptionItem
            label={
              <SeederLabel
                label={t('pages.caseReview.details.confirmationEmail')}
              />
            }
          >
            <CopyWrapper text={confirmationEmail.entityName}>
              <DeepLinkDropdown
                section={MENU_SECTION.accounts}
                type={MENU_SECTION_TYPE.confirmationEmail}
              >
                {confirmationEmail.entityName}
              </DeepLinkDropdown>
            </CopyWrapper>
          </DescriptionItem>
        )}
      </Descriptions>
      {rawConfirmationEmail && (
        <OrderAttributeLists
          type={PREDICTION_LIST_TYPE.EMAIL}
          value={rawConfirmationEmail}
          analyticsId="confirmation-email"
        />
      )}
      {accountEmail?.entityName && (
        <>
          <Descriptions colon={false} size="small">
            <DescriptionItem
              label={
                <SeederLabel
                  label={t('pages.caseReview.details.accountEmail')}
                />
              }
            >
              <CopyWrapper text={accountEmail.entityName}>
                <DeepLinkDropdown
                  section={MENU_SECTION.accounts}
                  type={MENU_SECTION_TYPE.accountEmail}
                >
                  {accountEmail.entityName}
                </DeepLinkDropdown>
              </CopyWrapper>
            </DescriptionItem>
          </Descriptions>
          <OrderAttributeLists
            type={PREDICTION_LIST_TYPE.EMAIL}
            value={userAccountEmail}
            analyticsId="account-email"
          />
        </>
      )}
      {noInfo && <NoInformation />}
    </>
  )
}

export default EmailAccounts
