import { Flex } from 'antd'
import { useTranslation } from 'react-i18next'
import { spacingSM } from '@signifyd/ant'
import {
  TextThirdGen,
  ThirdGenSeederIcon,
  TitleThirdGen,
} from '@signifyd/components'

interface Props {
  title: string
}

export const CardHeaderThirdGen: React.FC<Props> = ({ title }) => {
  const { t } = useTranslation()

  return (
    <Flex justify="space-between">
      <TitleThirdGen level={2}>{title}</TitleThirdGen>

      <Flex gap={spacingSM}>
        <ThirdGenSeederIcon />
        <TextThirdGen type="secondary">
          {t('pages.caseReview.common.seederInfo')}
        </TextThirdGen>
      </Flex>
    </Flex>
  )
}
