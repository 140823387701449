import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import App from 'App'
import { StoreProvider } from 'easy-peasy'
import React from 'react'
import ReactDOM, { render } from 'react-dom'
import store from 'store'
import UserLoader from 'UserLoader'
import '@signifyd/ant/dist/index.less'
import {
  ErrorBoundary,
  AuthProvider,
  defaultQueryClientConfig,
} from '@signifyd/components'
import 'antd/dist/reset.css'
import styles from './index.less'

export const queryClient = new QueryClient(defaultQueryClientConfig)

if (process.env.NODE_ENV === 'development') {
  import('@axe-core/react').then((axe) => {
    axe.default(React, ReactDOM, 1000, {}, '#root')
  })
}

render(
  <AuthProvider>
    <ErrorBoundary className={styles.errorBoundary}>
      <StoreProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <UserLoader>
            <App />
          </UserLoader>
        </QueryClientProvider>
      </StoreProvider>
    </ErrorBoundary>
  </AuthProvider>,
  document.getElementById('root')
)
