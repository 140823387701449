import { Collapse, CollapseProps } from 'antd'
import { FC, Fragment } from 'react'
import { colorMarengo } from '@signifyd/colors'
import {
  ChevronDown,
  ChevronUp,
  Space,
  TextThirdGen,
} from '@signifyd/components'
import { InvestigationInfo } from '@signifyd/http'
import { useChargebackSettings } from 'core/queries/useChargebackSettings'
import getClaimDisplayData from '../CaseClaimBanner.utils'
import RepresentmentDetails from '../RepresentmentDetails'
import styles from './CaseClaimsBannerThirdGen.less'

interface Props {
  caseDetails: InvestigationInfo | null
}

export const CaseClaimBannerThirdGen: FC<Props> = ({ caseDetails }) => {
  const { data: settings, isLoading: settingsLoading } = useChargebackSettings(
    caseDetails?.teamId
  )

  if (!caseDetails || settingsLoading) {
    return null
  }

  return (
    <>
      {caseDetails.chargebackCases.map(
        ({ claim, representment, chargeback }) => {
          if (!claim) {
            return null
          }

          const claimDetails = getClaimDisplayData({
            claim,
            investigation: caseDetails,
            chargeback,
            settings,
          })

          if (!claimDetails) {
            return null
          }

          const getClaimsDetails: () => CollapseProps['items'] = () => [
            {
              key: claim.claimId,
              label: (
                <TextThirdGen weight="semibold">
                  {claimDetails.title}
                </TextThirdGen>
              ),
              children: (
                <div className={styles.container}>
                  {claimDetails.content}
                  <RepresentmentDetails
                    status={representment?.status}
                    statusReason={representment?.statusReason}
                    outcome={representment?.outcome}
                    type={representment?.type}
                  />
                </div>
              ),
              style: {
                background: claimDetails.color,
                border: 'none',
              },
            },
          ]

          return (
            <Fragment key={claim.claimId}>
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <ChevronUp fill={colorMarengo} />
                  ) : (
                    <ChevronDown fill={colorMarengo} />
                  )
                }
                style={{ background: claimDetails.color }}
                items={getClaimsDetails()}
              />
              <Space size="md" />
            </Fragment>
          )
        }
      )}
    </>
  )
}

export default CaseClaimBannerThirdGen
