import { Flex, Popover } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { spacingXS } from '@signifyd/ant'
import { colorSlate } from '@signifyd/colors'
import { ArrowRightArrowLeftDuoTone, TextThirdGen } from '@signifyd/components'
import StatusTagThirdGen from '@signifyd/components/src/thirdGenComponents/StatusTagThirdGen'
import { Return } from '@signifyd/http'
import { getReturnTagCheckpointData } from './getReturnTagCheckpointData'
import styles from './ReturnTagThirdGen.less'
import ReturnWithPolicyThirdGen from './ReturnWithPolicyThirdGen'

interface Props {
  returns: Array<Return>
}

interface MatchProps {
  key?: string
}

const NoMatch: FC<MatchProps> = ({ key }): JSX.Element => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.results' })

  return (
    <StatusTagThirdGen
      key={key}
      data-test-id="noMatchTag"
      icon={<ArrowRightArrowLeftDuoTone fill={colorSlate} />}
      className={styles.noMatch}
    >
      {t('noMatch')}
    </StatusTagThirdGen>
  )
}

const ReturnTagThirdGen: FC<Props> = ({ returns }) => {
  if (!returns.length) {
    return null
  }

  const [firstReturn, ...rest] = returns

  if (!firstReturn.decision) {
    return <NoMatch />
  }

  const firstDecisionCheeckpointData = getReturnTagCheckpointData(
    firstReturn.decision.checkpointAction
  )

  return (
    <Flex align="center" gap={spacingXS}>
      <ReturnWithPolicyThirdGen
        decision={firstReturn.decision}
        displayData={firstDecisionCheeckpointData}
      />

      <Popover
        content={
          <>
            {rest.map((returnItem) =>
              returnItem.decision ? (
                <ReturnWithPolicyThirdGen
                  key={returnItem.returnId}
                  decision={returnItem.decision}
                  displayData={firstDecisionCheeckpointData}
                />
              ) : (
                <NoMatch key={returnItem.returnId} />
              )
            )}
          </>
        }
      >
        <TextThirdGen
          className={styles[firstDecisionCheeckpointData.className]}
        >
          {rest.length > 0 && `+ ${rest.length}`}
        </TextThirdGen>
      </Popover>
    </Flex>
  )
}

export default ReturnTagThirdGen
