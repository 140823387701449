import Icon from '@ant-design/icons'
import { Row, Col } from 'antd'
import { FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { T1, Text, P } from '@signifyd/components'
import { joinClassNames } from '@signifyd/utils'
import Arrow from './icons/Arrow'
import styles from './OnboardingScreen.less'

interface Props {
  setSlide: (num: number) => void
}

const WelcomeScreen: FC<Props> = ({ setSlide }) => {
  const { t } = useTranslation()

  return (
    <Row className={joinClassNames([styles.screenRow, styles.slide])}>
      <Col span={9} offset={3} className={styles.screenTextWrapper}>
        <T1 className={styles.screenTextHeader}>
          {t('onboardingScreen.WelcomeScreen.welcomeToSignifyd')}
        </T1>
        <P className={styles.screenText}>
          <Trans
            i18nKey="onboardingScreen.WelcomeScreen.aboutUs"
            components={{
              semiBold: (
                <Text className={styles.screenText} weight="semibold" />
              ),
            }}
          />
        </P>

        <div className={styles.actions} data-test-id="welcomeScreenContainer">
          <Icon
            data-test-id="nextPageButton"
            onClick={() => setSlide(1)}
            component={Arrow}
            className={styles.arrowIcon}
          />
        </div>
      </Col>
    </Row>
  )
}

export default WelcomeScreen
