import Icon from '@ant-design/icons'
import { Button, Row, Col } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { T1, Text, P } from '@signifyd/components'
import { joinClassNames } from '@signifyd/utils'
import Arrow from './icons/Arrow'
import styles from './OnboardingScreen.less'

interface Props {
  setSlide: (num: number) => void
  startTrialFn: () => void
}

const StartTrialScreen: FC<Props> = ({ setSlide, startTrialFn }) => {
  const { t } = useTranslation()

  return (
    <Row className={joinClassNames([styles.screenRow, styles.slide])}>
      <Col span={9} offset={3} className={styles.screenTextWrapper}>
        <T1 className={styles.screenTextHeader}>
          {t('onboardingScreen.StartTrialScreen.start14DayTrial')}
        </T1>
        <P className={styles.screenText}>
          {t('onboardingScreen.StartTrialScreen.experience')}&nbsp;
          <Text weight="semibold" className={styles.magentaText}>
            {t('onboardingScreen.StartTrialScreen.noChargebackCoverage')}
          </Text>
        </P>
        <P className={styles.screenText}>
          {t('onboardingScreen.StartTrialScreen.toActivate')}
        </P>
        <div className={styles.actions} data-test-id="startTrialScreen">
          <Icon
            data-test-id="startTrialScreenFirstPage"
            onClick={() => setSlide(0)}
            component={Arrow}
            className={joinClassNames([styles.arrowIcon, styles.rotate])}
          />
          <Button
            type="primary"
            onClick={startTrialFn}
            data-test-id="startTrial"
            data-analytics-id="start-trial"
          >
            {t('onboardingScreen.StartTrialScreen.startTrial')}
          </Button>
        </div>
      </Col>
    </Row>
  )
}

export default StartTrialScreen
