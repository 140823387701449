import moment from 'moment-timezone'
import { i18nInstance } from '@signifyd/components'
import { INV_REVIEW_DISPOSITION, InvestigationInfo } from '@signifyd/http'
import {
  ActionState,
  getDisableActionState,
  getEnableActionState,
  getHideActionState,
} from '../../hooks/caseActions/utils'
import { CASE_CAPABILITIES, hasCapability } from '../../utils/capabilities'
import { getPastStartDate } from '../../utils/date.utils'

const submitGuaranteeTranslationPrefix = 'store.caseActions.submitGuarantee'

export const canSubmitGuarantee = (
  caseDetails: InvestigationInfo,
  isUserAdmin: boolean
): ActionState => {
  const { t } = i18nInstance

  // Enable if there is no guarantee and user is an admin
  if (!caseDetails.guaranteeDisposition && isUserAdmin) {
    return getEnableActionState()
  }

  if (
    // 1. Hide if order has already been submitted for guarantee or if order is not eligible for guarantee
    caseDetails.guaranteeDisposition ||
    caseDetails.guaranteeEligible === 'false'
  ) {
    return getHideActionState()
  }

  // 2. Disable if order is more than a week old
  if (
    moment(caseDetails.normalizedPurchaseCreatedAt).isBefore(
      getPastStartDate({ subtractInterval: 'weeks', subtractValue: 1 })
    )
  ) {
    return getDisableActionState(
      t(`${submitGuaranteeTranslationPrefix}.tooOld`, { days: 7 })
    )
  }
  // 3. Disable if customer has the - REQUIRE_REVIEW_DISPOSITION_FOR_GUARANTEE_SUBMIT - feature (WMTMX)
  if (
    hasCapability(
      caseDetails.teamId,
      caseDetails.customerId,
      CASE_CAPABILITIES.REQUIRE_REVIEW_DISPOSITION_FOR_GUARANTEE_SUBMIT
    ) &&
    caseDetails.investigationReviewDisposition !== INV_REVIEW_DISPOSITION.GOOD
  ) {
    return getDisableActionState(
      t(`${submitGuaranteeTranslationPrefix}.requiresDisposition`)
    )
  }

  // If we're here, action can be seen and used
  return getEnableActionState()
}
