import { Descriptions, Tooltip } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { formatCurrencyNumber } from '@signifyd/utils'
import CopyWrapper from 'core/components/CopyWrapper'
import EmptyWarning from 'core/components/EmptyWarning'
import FormattedDate from 'core/components/FormattedDate'
import SeederLabel from 'pages/CaseReviewPage/components/SeederLabel'
import { RuntimeSeller } from '../SellerDetails.types'
import styles from './SellerRecord.less'

const { Item: DescriptionItem } = Descriptions

interface Props {
  seller: RuntimeSeller
  currency: string
}

const SellerRecord: FC<Props> = ({ seller, currency }) => {
  const { t } = useTranslation()
  const { tags, createdDate, aggregateOrderCount, aggregateOrderAmount } =
    seller

  const noInfo =
    !tags?.length &&
    !createdDate &&
    !aggregateOrderCount &&
    !aggregateOrderAmount

  const tagsList = tags?.join(', ')
  const tagsTooltip = (
    <Text size="md" className={styles.tooltipText} data-test-id="tagsTooltip">
      {tagsList}
    </Text>
  )

  return (
    <>
      <Descriptions
        title={
          <span id="sellerRecord">
            {t('pages.caseReview.sellerDetails.sellerRecord')}
          </span>
        }
        colon={false}
        size="small"
      >
        {!!tags?.length && (
          <DescriptionItem
            label={
              <SeederLabel
                label={t('pages.caseReview.sellerDetails.sellerTag')}
              />
            }
          >
            <Tooltip title={tagsTooltip} mouseEnterDelay={0.5}>
              <div data-test-id="tagsList">
                <CopyWrapper text={tagsList}>{tagsList}</CopyWrapper>
              </div>
            </Tooltip>
          </DescriptionItem>
        )}
        {createdDate && (
          <DescriptionItem
            label={
              <SeederLabel
                label={t('pages.caseReview.sellerDetails.enrollmentDate')}
              />
            }
          >
            <CopyWrapper text={createdDate}>
              <FormattedDate date={createdDate} />
            </CopyWrapper>
          </DescriptionItem>
        )}
        {aggregateOrderCount && (
          <DescriptionItem
            label={
              <SeederLabel
                label={t('pages.caseReview.sellerDetails.aggregateOrderCount')}
              />
            }
          >
            <CopyWrapper text={aggregateOrderCount.toString()}>
              {aggregateOrderCount}
            </CopyWrapper>
          </DescriptionItem>
        )}
        {aggregateOrderAmount && (
          <DescriptionItem
            label={
              <SeederLabel
                label={t('pages.caseReview.sellerDetails.aggregateOrderAmount')}
              />
            }
          >
            <CopyWrapper text={aggregateOrderAmount}>
              {`${currency} ${formatCurrencyNumber(aggregateOrderAmount)}`}
            </CopyWrapper>
          </DescriptionItem>
        )}
      </Descriptions>
      {noInfo && (
        <EmptyWarning
          text={t('pages.caseReview.sellerDetails.noSellerRecord')}
        />
      )}
    </>
  )
}

export default SellerRecord
