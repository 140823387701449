import { i18nInstance } from '@signifyd/components'
import { googleSearchLink, piplSearchLink } from '../DeepLinkConstants'
import {
  DeepLinkFactory,
  MENU_ICON,
  MENU_SECTION_TYPE,
} from '../DeepLinkDropdown.types'

const getDeepLinksPhone: DeepLinkFactory = ({ caseDetails, type }) => {
  const firstRecipient = caseDetails?.recipients?.[0]
  const entityName =
    type === MENU_SECTION_TYPE.accountPhone
      ? caseDetails?.userAccountPhone
      : firstRecipient?.confirmationPhone

  return [
    {
      icon: MENU_ICON.GoogleSearchIcon,
      text: i18nInstance.t(
        'pages.caseReview.details.deepLinks.phoneNumberSearch'
      ),
      url: googleSearchLink + entityName,
    },
    {
      icon: MENU_ICON.WhitepagesIcon,
      text: i18nInstance.t(
        'pages.caseReview.details.deepLinks.phoneNumberSearch'
      ),
      url: `http://www.whitepages.com/phone/${entityName}`,
    },
    {
      icon: MENU_ICON.EkataIcon,
      text: i18nInstance.t(
        'pages.caseReview.details.deepLinks.phoneNumberSearch'
      ),
      url: `https://app.ekata.com/phones?number=${entityName}`,
    },
    {
      icon: MENU_ICON.PiplIcon,
      text: i18nInstance.t(
        'pages.caseReview.details.deepLinks.phoneNumberSearch'
      ),
      url: piplSearchLink + entityName,
    },
  ]
}

export default getDeepLinksPhone
