import { Form } from '@ant-design/compatible'
import { Select } from 'antd'
import { FC, RefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { RECOMMENDED_DECISION_DISPOSITION, SEARCH_FIELD } from '@signifyd/http'
import { FilterState } from 'store/search'

interface Props {
  value: Array<RECOMMENDED_DECISION_DISPOSITION>
  updateFilterFn: (update: Partial<FilterState>) => void
  scrollRef?: RefObject<HTMLDivElement>
}

export const RECOMMENDED_DECISION_DISPOSITION_VALUES = Object.values(
  RECOMMENDED_DECISION_DISPOSITION
).sort()

const RecommendationStatusSelectFilter: FC<Props> = ({
  value,
  updateFilterFn,
  scrollRef,
}) => {
  const { t } = useTranslation()

  const handleChange = (
    value: Array<RECOMMENDED_DECISION_DISPOSITION>
  ): void => {
    updateFilterFn({
      [SEARCH_FIELD.recommendedDecisionDisposition]: value,
    })
  }

  return (
    <Form.Item
      label={t('filters.recommendedAction.label')}
      data-test-id="recommendationStatusFilter"
    >
      <Select
        mode="multiple"
        allowClear
        placeholder={t('filters.placeholder.selectOneOrMore')}
        value={value}
        onChange={handleChange}
        data-test-id="filterRecommendationSelect"
        getPopupContainer={(triggerNode: HTMLElement) => {
          return scrollRef?.current ?? (triggerNode.parentNode as HTMLElement)
        }}
        options={RECOMMENDED_DECISION_DISPOSITION_VALUES.map((option) => ({
          key: option,
          label: t(`filters.recommendedDecisionDisposition.${option}`),
          value: option,
          'data-analytics-id': `filter-recommendation-selected-${option}`,
          'data-test-id': `filterRecommendationSelectOption-${option}`,
        }))}
      />
    </Form.Item>
  )
}

export default RecommendationStatusSelectFilter
