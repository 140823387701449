import { DownloadOutlined } from '@ant-design/icons'
import { Flex, Tooltip, message } from 'antd'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'store'
import { spacingXS } from '@signifyd/ant'
import { colorLicorice } from '@signifyd/colors'
import {
  CircleExclamation,
  TextThirdGen,
  useTokenDownload,
} from '@signifyd/components'
import {
  DOWNLOAD_COLUMN,
  INV_SEARCH_VIEW,
  SEARCH_FIELD,
  getExportToken,
} from '@signifyd/http'
import ResponsiveIconButton from 'core/components/ResponsiveIconButton'
import { antSort2SigSort } from 'core/utils/antSort2SigSort'
import { getEndOfDay, getPastStartDate } from 'core/utils/date.utils'
import invSearchQueryBuilder from 'core/utils/invSearchQueryBuilder'
import useUserHasAirlineOrders from 'pages/SearchResultsPage/queries/useUserHasAirlineOrders'
import styles from './SearchResultsTable.less'
import ThirdGenTableColumnSelectDrawer from '../TableColumnSelectDrawer/thirdGen/ThirdGenTableColumnSelectDrawer'

interface Props {
  resultsTotal: number
  hasAirlineOrders: boolean
  hasEventTicketingOrders?: boolean
}

const MAX_RESULTS = 10_000

const TableTitleThirdGen: FC<Props> = ({
  hasAirlineOrders,
  resultsTotal,
  hasEventTicketingOrders,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.results' })
  const { currentPage, filters, pageSize, searchTerm, sort, totalResults } =
    useStoreState((store) => store.search)
  const { currentTeams, currentUser } = useStoreState((store) => store.user)

  const {
    data: userHasAirlineOrders,
    isLoading: areDownloadDependenciesLoading,
  } = useUserHasAirlineOrders()

  const searchQuery = useMemo(() => {
    const dateFilters = {
      ...filters[SEARCH_FIELD.normalizedPurchaseCreatedAt],
    }

    const defaultDateRange = {
      min: getPastStartDate({
        subtractInterval: 'weeks',
        subtractValue: 2,
      }),
      max: getEndOfDay(),
    }

    const dateFiltersWithDefaults = {
      ...dateFilters,
      min: dateFilters.min ?? defaultDateRange.min,
      max: dateFilters.max ?? defaultDateRange.max,
    }

    const downloadColumn = userHasAirlineOrders
      ? DOWNLOAD_COLUMN.ORDERS_AIRLINE
      : DOWNLOAD_COLUMN.ORDERS

    return invSearchQueryBuilder({
      searchTerm,
      filters: {
        ...filters,
        [SEARCH_FIELD.normalizedPurchaseCreatedAt]: dateFiltersWithDefaults,
        [SEARCH_FIELD.teamId]: currentTeams,
      },
      sort: antSort2SigSort(sort),
      pagination: { currentPage, size: pageSize },
      view: INV_SEARCH_VIEW.INVESTIGATION_SUMMARY,
      columns: [downloadColumn],
    })
  }, [
    currentPage,
    currentTeams,
    filters,
    pageSize,
    searchTerm,
    sort,
    userHasAirlineOrders,
  ])

  const {
    downloadSearchResults,
    isBackgroundDownloadActive,
    isDownloadPending,
  } = useTokenDownload({
    getTokenFunction: getExportToken,
    getTokenPayload: searchQuery,
    baseDownloadUrl: `${BASE_URL}/v2/investigations/searchExport/${currentUser?.userId}`,
    onError: () => message.error(t('downloadButton.failure')),
  })

  const isDownloadDisabled =
    isBackgroundDownloadActive ||
    isDownloadPending ||
    areDownloadDependenciesLoading ||
    !totalResults

  const ButtonWrapper: FC = ({ children }) => {
    if (isDownloadDisabled) {
      return (
        <Tooltip
          placement="left"
          title={t('downloadButton.unavailable')}
          className={styles.downloadButton}
        >
          <div data-test-id="downloadButtonTooltip">{children}</div>
        </Tooltip>
      )
    }

    return <>{children}</>
  }

  const exceedMaxResults = resultsTotal >= MAX_RESULTS

  return (
    <Flex align="center" justify="space-between" gap={spacingXS}>
      <Flex align="center" gap={spacingXS}>
        <TextThirdGen size="sm" data-test-id="tableTitle">
          {t('table.title', { count: resultsTotal })}
        </TextThirdGen>
        {exceedMaxResults && (
          <Tooltip
            trigger={['click', 'hover']}
            placement="top"
            title={t('exceedMaxResultsTooltip')}
          >
            <div
              data-test-id="maxResultsTooltip"
              className={styles.tooltipIconContainer}
            >
              <CircleExclamation fill={colorLicorice} />
            </div>
          </Tooltip>
        )}
      </Flex>
      <Flex>
        <ThirdGenTableColumnSelectDrawer
          hasAirlineOrders={hasAirlineOrders}
          hasEventTicketingOrders={hasEventTicketingOrders}
        />
        <ButtonWrapper>
          <ResponsiveIconButton
            data-analytics-id="download-button"
            data-test-id="downloadButton"
            buttonClass={styles.downloadButton}
            text={t('downloadButton.title')}
            Icon={<DownloadOutlined />}
            onClick={downloadSearchResults}
            disabled={isDownloadDisabled}
            loading={isDownloadPending}
          />
        </ButtonWrapper>
      </Flex>
    </Flex>
  )
}

export default TableTitleThirdGen
