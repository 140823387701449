import { Alert, Descriptions, Divider, Flex, Typography } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { spacingMD } from '@signifyd/ant'
import { Space, TextThirdGen } from '@signifyd/components'
import { toTitleCase } from '@signifyd/utils'
import AddressFieldThirdGen from 'pages/CaseReviewPage/components/AddressField/AddressFieldThirdGen'
import { Address } from 'store/caseActions/types/updateAddress.types'
import styles from './UpdateAddressModal.less'
import { MENU_SECTION_TYPE } from '../OrderDetails/components/CaseDetails/components/DeepLinkDropdown/DeepLinkDropdown.types'

export interface Props {
  addresses: Array<Address>
}

const { Title } = Typography

const UpdateAddressConfirmationThirdGen: FC<Props> = ({ addresses }) => {
  const { t } = useTranslation()

  const formatAddressTitle = (recipient: Address): string => {
    if (recipient.shipment?.shippingMethod) {
      return t('caseActions.updateAddress.updateTitleWithShipment', {
        fullName: recipient.newAddress!.fullName,
        shipment: toTitleCase(recipient.shipment?.shippingMethod),
      })
    }

    return t('caseActions.updateAddress.updateTitle', {
      fullName: recipient.newAddress!.fullName,
    })
  }

  const addressesToUpdate = addresses.filter(
    (recipient) => recipient.newAddress
  )

  return (
    <div data-test-id="updateAddressConfirmation">
      <div>
        <Title level={3} className={styles.titlePadding}>
          {t('caseActions.updateAddress.confirmationTitle')}
        </Title>
        <Alert
          type="info"
          message={t('caseActions.updateAddress.confirmation', {
            count: addressesToUpdate.length,
          })}
          showIcon
        />
      </div>

      {addressesToUpdate.map((recipient) => (
        <>
          <Space size={spacingMD} />
          <Divider />
          <Space size={spacingMD} />

          <Descriptions
            key={recipient.newAddress!.address.fullAddress}
            title={
              <Flex vertical>
                {formatAddressTitle(recipient)}
                <Space size={spacingMD} />
              </Flex>
            }
            colon={false}
            data-test-id={`update-address-confirmation-${
              recipient.newAddress!.address.fullAddress
            }`}
            column={1}
          >
            <Descriptions.Item
              label={
                <TextThirdGen weight="semibold">
                  {t('caseActions.updateAddress.originalAddress')}
                </TextThirdGen>
              }
            >
              <AddressFieldThirdGen
                type={MENU_SECTION_TYPE.deliveryAddress}
                address={recipient.oldAddress.address}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <TextThirdGen weight="semibold">
                  {t('caseActions.updateAddress.updatedAddress')}
                </TextThirdGen>
              }
            >
              <AddressFieldThirdGen
                type={MENU_SECTION_TYPE.deliveryAddress}
                address={recipient.newAddress!.address}
              />
            </Descriptions.Item>
          </Descriptions>
        </>
      ))}
    </div>
  )
}

export default UpdateAddressConfirmationThirdGen
