import { EyeInvisibleOutlined } from '@ant-design/icons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { P, Text } from '@signifyd/components'
import styles from './TableColumnSelectDrawer.less'

const HiddenColumnsEmpty: FC = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.hiddenEmpty}>
      <EyeInvisibleOutlined className={styles.emptyIcon} />
      <Text className={styles.header} size="lg">
        {t('pages.results.columnSelect.empty.header')}
      </Text>
      <P>{t('pages.results.columnSelect.empty.value')}</P>
    </div>
  )
}

export default HiddenColumnsEmpty
