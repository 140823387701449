import Icon, { CloseOutlined } from '@ant-design/icons'
import { Drawer, Flex, Popconfirm } from 'antd'
import { isEqual, partition } from 'lodash'
import { memo, useState, useEffect, ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreActions, useStoreState } from 'store'
import { TableColumnIcon, Text, T4, Space } from '@signifyd/components'
import ResponsiveIconButton from 'core/components/ResponsiveIconButton'
import { defaultSortState } from 'store/search/search.store'
import { ColumnConfig, getDrawerColumns } from './columns'
import HiddenColumnsEmpty from './HiddenColumnsEmpty'
import TableColumnListItem from './TableColumnListItem'
import styles from './TableColumnSelectDrawer.less'
import TableColumnSelectFooter from './TableColumnSelectFooter'

interface Props {
  hasAirlineOrders?: boolean
  hasEventTicketingOrders?: boolean
}

const TableColumnSelectDrawer = ({
  hasAirlineOrders,
  hasEventTicketingOrders,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  const { sort } = useStoreState((state) => state.search)
  const { setSortOrder } = useStoreActions((actions) => actions.search)

  const { hiddenColumns } = useStoreState((state) => state.user)
  const { updateUserUIState } = useStoreActions((actions) => actions.user)

  const [drawerVisible, setDrawerVisible] = useState(false)
  const [columns, setColumns] = useState<Array<ColumnConfig>>(
    getDrawerColumns(
      !!hasAirlineOrders,
      hiddenColumns,
      !!hasEventTicketingOrders
    )
  )

  useEffect(() => {
    setColumns(
      getDrawerColumns(
        !!hasAirlineOrders,
        hiddenColumns,
        !!hasEventTicketingOrders
      )
    )
  }, [hiddenColumns, hasAirlineOrders, hasEventTicketingOrders])

  const hasChanges = useMemo(
    () =>
      isEqual(
        getDrawerColumns(
          !!hasAirlineOrders,
          hiddenColumns,
          !!hasEventTicketingOrders
        ),
        columns
      ),
    [hasAirlineOrders, hiddenColumns, hasEventTicketingOrders, columns]
  )

  const displayColumnNames = (
    displayColumns: Array<ColumnConfig>
  ): Array<ReactElement> =>
    displayColumns.map((column) => (
      <TableColumnListItem
        title={column.key}
        isLocked={column.isLocked}
        isHidden={column.isHidden}
        onToggleHidden={() => {
          const newColumns = [...columns]

          newColumns[columns.indexOf(column)].isHidden = !column.isHidden
          setColumns(newColumns)
        }}
        key={column.key}
      />
    ))

  const applyChanges = (): void => {
    const newHiddenColumns = columns
      .filter((column) => column.isHidden)
      .map((column) => column.key)

    updateUserUIState({ searchHiddenColumns: newHiddenColumns })

    // if hiding currently sorted column then reset sort order
    if (newHiddenColumns.includes(sort.by)) {
      setSortOrder(defaultSortState)
    }

    setDrawerVisible(false)
  }

  const conditionallyClear = (): void => {
    if (hasChanges) {
      setDrawerVisible(false)
    }
  }

  const clearChanges = (): void => {
    setColumns(
      getDrawerColumns(
        !!hasAirlineOrders,
        hiddenColumns,
        !!hasEventTicketingOrders
      )
    )
    setDrawerVisible(false)
  }

  const [excludes, includes] = partition(columns, (column) => column.isHidden)

  return (
    <>
      <ResponsiveIconButton
        data-test-id="open-columns-icon"
        data-analytics-id="open-column-config"
        text={
          excludes.length === 0
            ? t('pages.results.columnSelect.drawerTitle')
            : t('pages.results.columnSelect.drawerTitleHidden', {
                count: excludes.length,
              })
        }
        onClick={() => setDrawerVisible(true)}
        Icon={<Icon component={TableColumnIcon} />}
      />
      <Drawer
        styles={{
          body: { paddingRight: '32px', paddingTop: '20px' },
          header: { paddingRight: '32px' },
        }}
        data-test-id="column-select-drawer"
        title={
          <Flex justify="space-between">
            <Text className={styles.drawerTitle} weight="semibold">
              {t('pages.results.columnSelect.drawerTitle')}
            </Text>
            <Popconfirm
              overlayClassName="sig-popconfirm"
              data-test-id="drawer-close-confirm-top"
              icon={null}
              title={
                <>
                  <T4>{t('pages.results.columnSelect.close.confirmTitle')}</T4>
                  <Text type="secondary">
                    {t('pages.results.columnSelect.close.confirmText')}
                  </Text>
                </>
              }
              onConfirm={clearChanges}
              disabled={hasChanges}
              placement="topRight"
              okText={t('pages.results.columnSelect.close.confirmClose')}
              cancelText={t('pages.results.columnSelect.close.no')}
            >
              <CloseOutlined onClick={conditionallyClear} />
            </Popconfirm>
          </Flex>
        }
        width={520}
        open={drawerVisible}
        closable={false}
        footer={
          <TableColumnSelectFooter
            columns={columns}
            setColumns={setColumns}
            clearChanges={clearChanges}
            applyChanges={applyChanges}
            hasChanges={hasChanges}
            conditionallyClear={conditionallyClear}
            hasAirlineOrders={hasAirlineOrders}
          />
        }
      >
        {/* Visible Columns */}
        <T4>{t('pages.results.columnSelect.visibleColumns')}</T4>

        <Flex
          vertical
          justify="space-between"
          className={styles.columnDrawer}
          gap={8}
        >
          {displayColumnNames(includes)}
        </Flex>

        {/* Hidden Columns */}
        <Space size="md" />
        <T4>{t('pages.results.columnSelect.hiddenColumns')}</T4>

        {!excludes.length ? (
          <HiddenColumnsEmpty />
        ) : (
          <Flex
            vertical
            justify="space-between"
            className={styles.columnDrawer}
            gap={8}
          >
            {displayColumnNames(excludes)}
          </Flex>
        )}

        <Space size={60} />
      </Drawer>
    </>
  )
}

export default memo(TableColumnSelectDrawer)
