import { isEmpty } from 'lodash'
import {
  InvestigationInfo,
  Shipment,
  PhysicalAddress,
  Recipient,
} from '@signifyd/http'
import { MENU_SECTION_TYPE } from '../components/DeepLinkDropdown/DeepLinkDropdown.types'

export interface UniqueAddress {
  address: PhysicalAddress
  normalizedAddress?: string
  shipments: Array<Shipment>
  type: MENU_SECTION_TYPE.billingAddress | MENU_SECTION_TYPE.deliveryAddress
}

const getShipmentsById = (
  shipmentIds: Array<string | null>,
  shipments: Array<Shipment>
): Array<Shipment> =>
  shipments.filter(
    (shipment) =>
      shipment.shipmentId && shipmentIds.includes(shipment.shipmentId)
  )

const getUniqueAddresses = (
  recipients: Array<Recipient>
): Map<string, Array<Recipient>> => {
  const addressPhysical = new Map<string, Array<Recipient>>()

  recipients.forEach((recipient) =>
    addressPhysical.get(recipient.address.fullAddress)
      ? addressPhysical.get(recipient.address.fullAddress)?.push(recipient)
      : addressPhysical.set(recipient.address.fullAddress, [recipient])
  )

  return addressPhysical
}

export const findNormalized = (
  normalizedRecipients: Array<Recipient> | undefined,
  address: PhysicalAddress
): string | undefined =>
  normalizedRecipients?.find(
    (recipient) => recipient.address?.fullAddress === address.fullAddress
  )?.address.fullAddress

export const processUniqueAddresses = (
  caseDetails: InvestigationInfo
): Array<UniqueAddress> => {
  const uniqueAddresses: Array<UniqueAddress> = []
  const {
    shipments,
    recipients,
    billingAddress,
    normalizedBillingAddress,
    normalizedRecipients,
  } = caseDetails
  const addressMap = getUniqueAddresses(recipients)

  if (billingAddress && !isEmpty(billingAddress.fullAddress)) {
    uniqueAddresses.push({
      address: billingAddress,
      normalizedAddress: normalizedBillingAddress?.fullAddress,
      shipments: [],
      type: MENU_SECTION_TYPE.billingAddress,
    })
  }

  addressMap.forEach((recipients) => {
    const firstRecipient = recipients[0]
    if (firstRecipient && !isEmpty(firstRecipient.address.fullAddress)) {
      const { address } = firstRecipient

      uniqueAddresses.push({
        address,
        normalizedAddress: findNormalized(normalizedRecipients, address),
        shipments: getShipmentsById(
          recipients.map((recipient) => recipient.shipmentId),
          shipments
        ),
        type: MENU_SECTION_TYPE.deliveryAddress,
      })
    }
  })

  return uniqueAddresses
}
