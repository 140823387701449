import moment from 'moment-timezone'
import { i18nInstance } from '@signifyd/components'
import { Product, SEARCH_FIELD } from '@signifyd/http'
import { formatCurrencyNumber } from '@signifyd/utils'
import TextOverflowWrapper from 'core/components/TextOverflowWrapper'
import { DATE_FORMAT } from 'core/constants'
import TicketDetails from 'pages/CaseReviewPage/containers/OrderDetails/components/EventDetails/TicketDetails'
import { ColumnProps } from 'antd/lib/table'

const getFormattedDateTime = (dateTime: string): string => {
  return moment(dateTime).format(DATE_FORMAT.dateTime)
}

export const getEventDetailsColumnConfig = (
  currency: string
): Array<ColumnProps<Product>> => {
  const columnsConfigs: Record<string, ColumnProps<Product>> = {
    confirmationNumber: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.eventId}>
          {i18nInstance.t('eventTicketing.tableHeader.confirmationNumber')}
        </TextOverflowWrapper>
      ),
      render(_t, { itemId }) {
        return <TextOverflowWrapper>{itemId}</TextOverflowWrapper>
      },
      key: SEARCH_FIELD.eventId,
    },
    eventName: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.eventName}>
          {i18nInstance.t('eventTicketing.tableHeader.eventName')}
        </TextOverflowWrapper>
      ),
      render(_t, { itemName }) {
        return <TextOverflowWrapper>{itemName}</TextOverflowWrapper>
      },
      key: SEARCH_FIELD.eventName,
    },
    eventType: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.eventType}>
          {i18nInstance.t('eventTicketing.tableHeader.eventType')}
        </TextOverflowWrapper>
      ),
      render(_t, { eventTicketing }) {
        if (!eventTicketing) {
          return
        }

        return (
          <TextOverflowWrapper>
            {i18nInstance.t(
              `eventTicketing.eventTypes.${eventTicketing.eventType}`
            )}
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.eventType,
    },
    eventVenue: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={SEARCH_FIELD.eventVenueName}>
          {i18nInstance.t('eventTicketing.tableHeader.eventVenue')}
        </TextOverflowWrapper>
      ),
      render(_t, { eventTicketing }) {
        return (
          <TextOverflowWrapper>
            {eventTicketing?.eventVenueName}
          </TextOverflowWrapper>
        )
      },
      key: SEARCH_FIELD.eventVenueName,
    },
    eventTicketingDetails: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={'eventTicketingDetails'}>
          {i18nInstance.t('eventTicketing.tableHeader.ticketDetails')}
        </TextOverflowWrapper>
      ),
      render(_t, { eventTicketing }) {
        if (!eventTicketing) {
          return
        }

        return (
          <TextOverflowWrapper>
            <TicketDetails
              items={eventTicketing.eventTicketingDetails.map((detail) => ({
                ...detail,
                currency,
              }))}
            />
          </TextOverflowWrapper>
        )
      },
      key: 'eventTicketingDetails',
    },
    totalPrice: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={'totalPrice'}>
          {i18nInstance.t('eventTicketing.tableHeader.totalPrice')}
        </TextOverflowWrapper>
      ),
      render(_t, { eventTicketing }) {
        if (!eventTicketing) {
          return
        }

        return (
          <TextOverflowWrapper>
            {eventTicketing?.eventPrice &&
              `${currency} ${formatCurrencyNumber(eventTicketing.eventPrice)}`}
          </TextOverflowWrapper>
        )
      },
      key: 'totalPrice',
    },
    eventStart: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={'eventStart'}>
          {i18nInstance.t('eventTicketing.tableHeader.eventStart')}
        </TextOverflowWrapper>
      ),
      render(_t, { eventTicketing }) {
        if (!eventTicketing) {
          return
        }

        return (
          <TextOverflowWrapper>
            {eventTicketing?.eventStartDateTime &&
              getFormattedDateTime(eventTicketing.eventStartDateTime)}
          </TextOverflowWrapper>
        )
      },
      key: 'eventStart',
    },
    eventEnd: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={'eventEnd'}>
          {i18nInstance.t('eventTicketing.tableHeader.eventEnd')}
        </TextOverflowWrapper>
      ),
      render(_t, { eventTicketing }) {
        if (!eventTicketing) {
          return
        }

        return (
          <TextOverflowWrapper>
            {eventTicketing?.eventEndDateTime &&
              getFormattedDateTime(eventTicketing.eventEndDateTime)}
          </TextOverflowWrapper>
        )
      },
      key: 'eventEnd',
    },
    saleDate: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={'saleDate'}>
          {i18nInstance.t('eventTicketing.tableHeader.saleDate')}
        </TextOverflowWrapper>
      ),
      render(_t, { eventTicketing }) {
        if (!eventTicketing) {
          return
        }

        return (
          <TextOverflowWrapper>
            {eventTicketing?.eventSaleDateTime &&
              getFormattedDateTime(eventTicketing.eventSaleDateTime)}
          </TextOverflowWrapper>
        )
      },
      key: 'saleDate',
    },
    eventRisk: {
      width: '5%',
      title: (
        <TextOverflowWrapper data-test-id={'eventRisk'}>
          {i18nInstance.t('eventTicketing.tableHeader.eventRisk')}
        </TextOverflowWrapper>
      ),
      render(_t, { eventTicketing }) {
        if (!eventTicketing?.eventRisk) {
          return
        }

        return (
          <TextOverflowWrapper>
            {i18nInstance.t(
              `eventTicketing.eventRisks.${eventTicketing.eventRisk}`
            )}
          </TextOverflowWrapper>
        )
      },
      key: 'eventRisk',
    },
  }

  const columns: Array<ColumnProps<Product>> = [
    columnsConfigs.confirmationNumber,
    columnsConfigs.eventName,
    columnsConfigs.eventType,
    columnsConfigs.eventVenue,
    columnsConfigs.eventTicketingDetails,
    columnsConfigs.totalPrice,
    columnsConfigs.eventStart,
    columnsConfigs.eventEnd,
    columnsConfigs.saleDate,
    columnsConfigs.eventRisk,
  ]

  return columns
}
