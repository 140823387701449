import { Card, Row, Col } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { spacingSM } from '@signifyd/ant'
import { ErrorBoundary } from '@signifyd/components'
import { InvestigationInfo } from '@signifyd/http'
import { CardHeaderThirdGen } from 'pages/CaseReviewPage/components/CardHeader/CardHeaderThirdGen'
import { CaseEntriesResolvedState } from 'store/currentCase/types/case.entries.types'
import styles from './CaseDetails.less'
import Device from './Device'
import EmailAccounts from './EmailAccounts'
import LocationsThirdGen from './LocationsThirdGen'
import Network from './Network'
import Organization from './Organization'
import People from './People'
import Phone from './Phone'

const columnSpans = { md: { span: 24 }, lg: { span: 12 }, xxl: { span: 8 } }

interface Props {
  caseEntries: CaseEntriesResolvedState
  caseDetails: InvestigationInfo | null
}

export const CaseDetailsThirdGen: FC<Props> = ({
  caseEntries,
  caseDetails,
}) => {
  const { t } = useTranslation()

  const hasDetails = !!caseEntries && !!caseDetails

  return (
    <Card
      title={
        <CardHeaderThirdGen title={t('pages.caseReview.details.caseDetails')} />
      }
      data-test-id="caseDetails"
      data-analytics-id="case-details"
    >
      <ErrorBoundary
        className={styles.errorBoundary}
        message={t('errorBoundaries.caseDetails')}
      >
        {hasDetails && (
          <Row gutter={[spacingSM, spacingSM]}>
            <Col {...columnSpans} id="locations">
              <LocationsThirdGen caseDetails={caseDetails} />
            </Col>
            <Col {...columnSpans}>
              <People
                caseEntries={caseEntries}
                accountHolderTaxInfo={{
                  accountHolderTaxId: caseDetails.accountHolderTaxId,
                  accountHolderTaxIdCountry:
                    caseDetails.accountHolderTaxIdCountry,
                }}
                caseDetails={caseDetails}
              />
            </Col>

            <Col {...columnSpans}>
              <EmailAccounts
                caseEntries={caseEntries}
                caseDetails={caseDetails}
              />
            </Col>

            <Col {...columnSpans}>
              <Network caseEntries={caseEntries} caseDetails={caseDetails} />
            </Col>
            <Col {...columnSpans}>
              <Phone caseEntries={caseEntries} caseDetails={caseDetails} />
            </Col>
            <Col {...columnSpans}>
              <Organization caseEntries={caseEntries} />
            </Col>
            <Col {...columnSpans}>
              <Device caseDetails={caseDetails} />
            </Col>
          </Row>
        )}
      </ErrorBoundary>
    </Card>
  )
}

export default CaseDetailsThirdGen
