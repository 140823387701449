import { Action, Thunk } from 'easy-peasy'
import { StoreModel } from 'store'
import { CaseAnalysis } from '@signifyd/http'
import { CaseStoreModel } from '.'

export enum ANALYSIS_STATE {
  POLICY_ONLY = 'POLICY_ONLY',
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  ERROR = 'ERROR',
}
export interface CaseAnalysisState {
  // State
  analysisData: CaseAnalysis | null
  analysisState: ANALYSIS_STATE
}

export interface CaseAnalysisModel extends CaseAnalysisState {
  // Getter / Setter
  setCaseAnalysis: Action<CaseStoreModel, CaseAnalysisState>
  clearCaseAnalysis: Action<CaseStoreModel, void>

  // Thunks
  getCaseAnalysis: Thunk<CaseStoreModel, number, StoreModel>
}
