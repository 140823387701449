import { createStore, createTypedHooks, State } from 'easy-peasy'
import { caseActionModel, CaseActionModel } from './caseActions'
import { caseStoreModel, CaseStoreModel } from './currentCase'
import { searchStoreModel, SearchStoreModel } from './search'
import { userModel, UserModel } from './user'

export interface StoreModel {
  user: UserModel
  search: SearchStoreModel
  currentCase: CaseStoreModel
  caseActions: CaseActionModel
}

export type StoreState = State<StoreModel>

const store = createStore<StoreModel>({
  user: userModel,
  search: searchStoreModel,
  currentCase: caseStoreModel,
  caseActions: caseActionModel,
})

export const { useStoreActions, useStoreDispatch, useStoreState } =
  createTypedHooks<StoreModel>()

export default store
