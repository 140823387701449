import { Flex, Typography } from 'antd'
import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useStoreActions, useStoreState } from 'store'
import { StringParam, useQueryParams } from 'use-query-params'
import {
  ErrorBoundary,
  P,
  Space,
  T2,
  TextThirdGen,
  useIsThirdGen,
} from '@signifyd/components'
import { USER_ROLE } from '@signifyd/http'
import NotFound from 'core/components/NotFound'
import PageWrapper from 'core/components/PageWrapper'
import SearchAndFilter from 'core/components/SearchAndFilter'
import TeamSelectModal from 'core/components/TeamSelectModal'
import { useSearchFilters } from 'core/hooks'
import { encodeUrlHash } from 'core/utils/urlEncoder'
import useUserHasEventTicketingOrders from 'pages/SearchResultsPage/queries/useUserHasEventTicketingOrders'
import { RangeFilter, SearchFilterState } from 'store/search'
import {
  defaultSearchFilters,
  defaultSortState,
} from 'store/search/search.store'
import AggregatePanel from './components/Aggregates'
import { aggregatesPanelKey } from './components/Aggregates/AggregatePanel'
import AggregatePanelThirdGen from './components/Aggregates/thirdGen/AggregatePanelThirdGen'
import HeadlineDatePicker from './components/HeadlineDatePicker/HeadlineDatePicker'
import SearchResultTable from './components/SearchResultsTable'
import useUserHasAirlineOrders from './queries/useUserHasAirlineOrders'
import styles from './SearchResultsPage.less'

export const externalParams = {
  normalizedPurchaseCreatedAt: StringParam,
  returnRecommendedActionRuleId: StringParam,
  recommendedActionRuleId: StringParam,
  guaranteeRecommendedActionRuleId: StringParam,
}

const getNormalisedPurchaseCreatedDate = (
  queryDate?: string | null
): RangeFilter<string> => {
  if (queryDate) {
    const [min, max] = queryDate.split(',')

    return { min, max }
  }

  return defaultSearchFilters.normalizedPurchaseCreatedAt
}

const { Title } = Typography

export const SearchResultsPage: FC = () => {
  const { t } = useTranslation()
  const { searchId = '' } = useParams()
  const [query] = useQueryParams(externalParams)

  const navigate = useNavigate()
  const [activeKey, setActiveKey] = useState<string | Array<string>>([
    aggregatesPanelKey,
  ])

  const currentUser = useStoreState((state) => state.user.currentUser)
  const isAdmin = currentUser?.roles.includes(USER_ROLE.ADMIN) ?? false

  const { data: hasAirlineOrders, isLoading: isOrderCheckLoading } =
    useUserHasAirlineOrders()

  const { filters, updateFilters, searchTerm, updateSearchTerm } =
    useSearchFilters({ searchId })

  // Store State
  const { populateAndSearch } = useStoreActions((actions) => actions.search)
  const searchResults = useStoreState((state) => state.search.results)
  const searchResultsLoading = useStoreState((state) => state.search.searching)
  const selectedTeams = useStoreState((action) => action.user.currentTeams)

  const {
    data: { hasEventTicketingOrders } = {},
    isLoading: isEventTicketingLoading,
  } = useUserHasEventTicketingOrders(selectedTeams, isAdmin)

  const loading =
    searchResultsLoading || isOrderCheckLoading || isEventTicketingLoading

  const generation = useIsThirdGen() ? 'thirdGen' : 'secondGen'
  const isThirdGen = useIsThirdGen()

  // Component State
  const searchRef = useRef<HTMLDivElement>(null)

  // If we have external params, combine and encode the url then refresh
  useEffect(() => {
    if (query && !searchId) {
      const encodedHash = encodeUrlHash<SearchFilterState>({
        searchTerm,
        filters: {
          ...defaultSearchFilters,
          ...query,
          normalizedPurchaseCreatedAt: getNormalisedPurchaseCreatedDate(
            query.normalizedPurchaseCreatedAt
          ),
        },
        sort: defaultSortState,
      })

      const newSearchPath = `/orders/search/${encodedHash}`
      navigate(newSearchPath, { replace: true })
    }
  }, [searchId, query, navigate, searchTerm])

  useEffect(() => {
    if (!isOrderCheckLoading && searchId) {
      populateAndSearch(decodeURI(searchId))
    }
  }, [searchId, populateAndSearch, query, isOrderCheckLoading])

  const componentsByGen = {
    secondGen: {
      PageTitle: <T2>{t('pages.results.title')}</T2>,
      Panel: AggregatePanel,
    },
    thirdGen: {
      PageTitle: <Title level={1}>{t('pages.results.title')}</Title>,
      Panel: AggregatePanelThirdGen,
    },
  }

  const { PageTitle, Panel } = componentsByGen[generation]

  const spaceSizing = isThirdGen ? 'md' : 'lg'

  return (
    <>
      <Flex justify="space-between" align="center" className={styles.titleBar}>
        {PageTitle}
        <ErrorBoundary message={t('errorBoundaries.teamSelect')}>
          <div className={styles.dateTeamContainer}>
            <HeadlineDatePicker
              filters={filters}
              updateFilters={updateFilters}
            />
            <TeamSelectModal />
          </div>
        </ErrorBoundary>
      </Flex>
      {generation === 'thirdGen' ? <Space size="md" /> : <></>}
      <PageWrapper>
        <ErrorBoundary message={t('errorBoundaries.aggregatePanel')}>
          <Panel
            filters={filters}
            updateFilters={updateFilters}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            teamId={selectedTeams}
          />
        </ErrorBoundary>

        <Space size={spaceSizing} />

        <div ref={searchRef}>
          <SearchAndFilter
            defaultOpen={!isThirdGen}
            filters={filters}
            updateFilters={updateFilters}
            searchTerm={searchTerm}
            updateSearchTerm={updateSearchTerm}
            hasAirlineOrders={!!hasAirlineOrders}
            setActiveKey={setActiveKey}
            hasEventTicketingOrders={!!hasEventTicketingOrders}
          />
        </div>

        <Space size={spaceSizing} />

        <div className={styles.content}>
          {(loading || !!searchResults?.length) && (
            <ErrorBoundary message={t('errorBoundaries.searchResults')}>
              <SearchResultTable
                loading={loading}
                searchResults={searchResults}
                hasAirlineOrders={!!hasAirlineOrders}
                hasEventTicketingOrders={!!hasEventTicketingOrders}
              />
            </ErrorBoundary>
          )}
          {!loading && !searchResults.length && (
            <NotFound title={t('pages.results.noOrders.title')}>
              {isThirdGen ? (
                <>
                  <TextThirdGen paragraph>
                    {t('pages.results.noOrders.description')}
                  </TextThirdGen>
                  <TextThirdGen paragraph>
                    {t('pages.results.noOrders.description1')}
                  </TextThirdGen>
                </>
              ) : (
                <>
                  <P>
                    {t('pages.results.noOrders.subTitle1')}
                    <br />
                    {t('pages.results.noOrders.subTitle2')}
                  </P>
                  <P>{t('pages.results.noOrders.subTitle3')}</P>
                </>
              )}
            </NotFound>
          )}
        </div>
      </PageWrapper>
    </>
  )
}

export default SearchResultsPage
