import cx from 'classnames'
import { FC } from 'react'
import styles from './PageWrapper.less'

interface Props {
  backgroundColor?: string
  className?: string
}

const PageWrapper: FC<Props> = ({
  children,
  className,
  backgroundColor = 'transparent',
}) => (
  <div style={{ backgroundColor }}>
    <div className={cx([styles.pageWrapper, className])}>{children}</div>
  </div>
)

export default PageWrapper
