import { Row } from 'antd'
import { get, isEmpty } from 'lodash'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CaseOrder } from '@signifyd/http'
import { toTitleCase } from '@signifyd/utils'
import { formatToLowerCase } from 'core/utils/textTransforms'
import SummaryIcon from '../SummaryIcon'

const UNKNOWN_REGEX = /^unknown$/i

export const getPaymentValue = (
  order: CaseOrder,
  path: string
): string | null => {
  const value = get(order, path)

  return isEmpty(value) || UNKNOWN_REGEX.test(value) ? null : value
}

interface Props {
  orderDetails: CaseOrder
}

export const PaymentSummaryIcons: FC<Props> = ({ orderDetails }) => {
  const { t } = useTranslation()

  const cardBrand =
    getPaymentValue(orderDetails, 'paymentMethod.cardBrand') ||
    getPaymentValue(orderDetails, 'paymentMethod.name') ||
    getPaymentValue(orderDetails, 'paymentMethod.entityName')
  const paymentCardType = getPaymentValue(
    orderDetails,
    'paymentMethod.cardType'
  )
  const paymentType = getPaymentValue(orderDetails, 'paymentMethod.type')
  const paymentGateway = getPaymentValue(orderDetails, 'paymentGateway')

  return (
    <Row>
      {/* Card Brand */}
      {!!cardBrand && (
        <SummaryIcon type={cardBrand} tooltip={toTitleCase(cardBrand)} />
      )}
      {/* Payment Card Type */}
      {!!paymentCardType && (
        <SummaryIcon
          type={paymentCardType}
          tooltip={t('caseSummary.payment.paymentCardTip', {
            text: formatToLowerCase(paymentCardType),
          })}
        />
      )}
      {/* Payment Type */}
      {!!paymentType && (
        <SummaryIcon
          type={paymentType}
          tooltip={t('caseSummary.payment.paymentTypeTip', {
            text: formatToLowerCase(paymentType),
          })}
        />
      )}
      {/* Payment Gateway */}
      {!!paymentGateway && (
        <SummaryIcon
          type={paymentGateway}
          tooltip={t('caseSummary.payment.paymentGatewayTip', {
            text: toTitleCase(paymentGateway),
          })}
        />
      )}
      {/* No Payment Type */}
      {!paymentType && !paymentCardType && (
        <SummaryIcon
          type="no-brand"
          tooltip={t('caseSummary.payment.unknownPaymentType')}
        />
      )}
    </Row>
  )
}
export default PaymentSummaryIcons
