import { Divider } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'store'
import { Space, Text } from '@signifyd/components'
import { CaseNote, InvestigationInfo } from '@signifyd/http'
import { ACTION_CONTEXT } from 'core/hooks/caseActions/useClaimReimbursement/types'
import { usePrefetchAdminSettings } from 'core/hooks/useAdminSettings'
import CancelGuaranteeButton from 'pages/CaseReviewPage/components/CancelGuaranteeButton'
import CaseStatusButton from 'pages/CaseReviewPage/components/CaseStatusButton'
import ClaimReimbursementButton from 'pages/CaseReviewPage/components/ClaimReimbursementButton'
import FeedbackDropdown from 'pages/CaseReviewPage/components/FeedbackDropdown'
import GuaranteeButton from 'pages/CaseReviewPage/components/GuaranteeButton'
import ResubmitGuaranteeButton from 'pages/CaseReviewPage/components/ResubmitGuaranteeButton'
import UpdateAddressButton from 'pages/CaseReviewPage/components/UpdateAddressButton'

import styles from './CaseActions.less'

interface Props {
  caseDetails: InvestigationInfo
  notes: Array<CaseNote>
}

export const CaseActions: FC<Props> = ({ caseDetails }) => {
  const { t } = useTranslation()
  const canSeeGuaranteeActions = useStoreState(
    (state) => state.caseActions.canSeeGuaranteeActions
  )

  usePrefetchAdminSettings()

  return (
    <div className={styles.actionPanel}>
      <Space size={16} />

      {/* Feedback section */}
      <Text
        weight="semibold"
        size="md"
        className={styles.title}
        data-analytics-id="feedback-header"
      >
        {t('caseActions.headers.feedback')}
      </Text>

      <FeedbackDropdown caseDetails={caseDetails} />
      <Space size={16} />

      {canSeeGuaranteeActions && (
        <div className={styles.actionContainer}>
          <Text weight="semibold" size="md" className={styles.title}>
            {t('caseActions.headers.order')}
          </Text>

          {/* Submit for Guarantee button */}
          <GuaranteeButton caseDetails={caseDetails} />

          {/* Re-Guarantee button */}
          <ResubmitGuaranteeButton caseDetails={caseDetails} />

          {/* Update address button */}
          <UpdateAddressButton caseDetails={caseDetails} />

          {/* Cancel Guarantee button */}
          <CancelGuaranteeButton caseDetails={caseDetails} />

          {/* Submit Claim request */}
          <ClaimReimbursementButton
            caseDetails={caseDetails}
            context={ACTION_CONTEXT.CaseReview}
          />
        </div>
      )}

      <Divider className={styles.divider} />

      {/*  Open / Close Case */}
      <CaseStatusButton caseDetails={caseDetails} />
    </div>
  )
}

export default CaseActions
