import Icon from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { T4, SeederIcon } from '@signifyd/components'
import styles from './CardHeader.less'

interface Props {
  title: string
}

export const CardHeader: React.FC<Props> = ({ title }) => {
  const { t } = useTranslation()

  return (
    <T4 className={styles.cardHeader}>
      {title}
      <span className={styles.seederInfo}>
        <Icon component={SeederIcon} />
        {t('pages.caseReview.common.seederInfo')}
      </span>
    </T4>
  )
}
