import { FC, useEffect } from 'react'
import { useStoreActions, useStoreState } from 'store'
import {
  PageSpinnerThirdGen,
  FeatureFlagController,
  thirdGenTreatmentKey,
  FeatureFlagConfig,
  AppAnalytics,
} from '@signifyd/components'
import { ordersTokens } from '@signifyd/sig-keys'

export const featureFlagConfig: Array<FeatureFlagConfig> = [
  { key: 'has-secondary-matches', label: 'Secondary Matches' },
  {
    key: thirdGenTreatmentKey,
    label: 'Third Gen',
  },
]

const UserLoader: FC = ({ children }) => {
  const { getCurrentUser } = useStoreActions((store) => store.user)
  const { currentUser, userConfigs } = useStoreState((store) => store.user)

  useEffect(() => {
    getCurrentUser()
  }, [getCurrentUser])

  if (!currentUser || !userConfigs) {
    return <PageSpinnerThirdGen />
  }

  return (
    <>
      <AppAnalytics
        user={currentUser}
        logRocketId={ordersTokens.logRocket}
        heapAppId={ordersTokens.heapAppId}
        enabled={process.env.BUILD_ENV === 'production'}
      />
      <FeatureFlagController
        user={currentUser}
        LoadingComponent={<PageSpinnerThirdGen />}
        featureFlagConfig={featureFlagConfig}
      >
        {children}
      </FeatureFlagController>
    </>
  )
}

export default UserLoader
