import { Descriptions, Flex } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { spacingSM } from '@signifyd/ant'
import EmptyWarning from 'core/components/EmptyWarning'
import SeederLabel from 'pages/CaseReviewPage/components/SeederLabel'
import {
  RuntimeSeller,
  SellerInformationMap,
} from 'pages/CaseReviewPage/containers/OrderDetails/components/SellerDetailsThirdGen/SellerDetails.types'
import { DescriptionsItemType } from 'antd/es/descriptions'
import styles from '../SellerDetails.less'
import SellerField from './components/SellerField'

interface Props {
  seller: RuntimeSeller
}

const SellerInformation: FC<Props> = ({ seller }) => {
  const { t } = useTranslation()
  const { sellerId, parentEntity, name, address, email, phone } = seller

  const noInfo =
    !sellerId &&
    !parentEntity &&
    !name &&
    !address?.fullAddress &&
    !email &&
    !phone

  const sellerMap: Array<SellerInformationMap> = [
    {
      key: 'sellerId',
      label: t('pages.caseReview.sellerDetails.sellerId'),
    },
    {
      key: 'parentEntity',
      label: t('pages.caseReview.sellerDetails.parentEntity'),
    },
    {
      key: 'name',
      label: t('pages.caseReview.sellerDetails.sellerName'),
    },
    {
      key: 'address',
      label: t('pages.caseReview.sellerDetails.sellerAddress'),
    },
    {
      key: 'email',
      label: t('pages.caseReview.sellerDetails.sellerEmail'),
    },
    {
      key: 'phone',
      label: t('pages.caseReview.sellerDetails.sellerPhoneNumber'),
    },
  ]

  const sellerItems = sellerMap
    .map(({ key, label }) => {
      if (!seller[key] || (key === 'address' && !seller.address.fullAddress)) {
        return null
      }

      return {
        key,
        label: <SeederLabel dataTestId={`seeder-${label}`} label={label} />,
        children: <SellerField seller={seller} field={key} />,
      }
    })
    .filter((item) => !!item) as Array<DescriptionsItemType>

  const sectionName = t('pages.caseReview.sellerDetails.sellerInformation')

  const noItems = [
    {
      key: 'noInfo',
      label: '',
      labelStyle: { display: 'none' },
      children: (
        <EmptyWarning
          text={t('pages.caseReview.sellerDetails.noSellerInformation')}
        />
      ),
    },
  ]

  const items = !noInfo ? sellerItems : noItems

  return (
    <Descriptions
      title={
        <Flex align="center" gap={spacingSM}>
          {sectionName}
        </Flex>
      }
      items={items}
      colon={false}
      size="small"
      bordered
      column={1}
      className={styles.container}
    />
  )
}

export default SellerInformation
