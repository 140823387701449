import { Card, Table } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorBoundary, T4, Text } from '@signifyd/components'
import { InvestigationInfo, Passenger } from '@signifyd/http'
import AirlineTripDetails from './AirlineTripDetails/AirlineTripDetails'
import { getTravelColumns } from './travelColumns.config'

interface Props {
  investigation: InvestigationInfo
}

const getPassengers = (investigation: InvestigationInfo): Array<Passenger> => {
  const passengersMap = new Map<string, Passenger>()

  investigation.products.forEach((product) => {
    const rawPassengers = product.travel?.passengers ?? []

    rawPassengers.forEach((passenger) => {
      if (!passenger.passengerId) {
        return
      }

      const existingPassenger = passengersMap.get(passenger.passengerId)

      if (existingPassenger) {
        const ancillaries = [
          ...(existingPassenger.ancillaryPurchases ?? []),
          ...(passenger.ancillaryPurchases ?? []),
        ]

        existingPassenger.ancillaryPurchases = ancillaries
      } else {
        passengersMap.set(passenger.passengerId, passenger)
      }
    })
  })

  return [...passengersMap.values()]
}

export const TravelDetails: FC<Props> = ({ investigation }) => {
  const { t } = useTranslation()

  const passengersTableData = getPassengers(investigation)

  const travelData = investigation.products
    .flatMap((product) => product.travel?.airlineTripDetail ?? [])
    .filter(Boolean)

  const columns = getTravelColumns()

  return (
    <Card
      title={<T4>{t('airline.travelDetails')}</T4>}
      data-test-id="travelDetails"
      data-analytics-id="travel-details"
      size="small"
    >
      <ErrorBoundary message={t('errorBoundaries.travelDetails')}>
        <Text size="md" weight="semibold">
          {t('airline.passengers')}
        </Text>
        <div>
          <Table
            rowKey="passengerId"
            dataSource={passengersTableData}
            columns={columns}
            scroll={{ x: true }}
            pagination={false}
          />
        </div>
      </ErrorBoundary>
      <ErrorBoundary message={t('errorBoundaries.airlineTripDetails')}>
        <AirlineTripDetails travelData={travelData} />
      </ErrorBoundary>
    </Card>
  )
}

export default TravelDetails
