import { get } from 'lodash'
import { Trans } from 'react-i18next'
import {
  colorGlitter,
  colorGold20,
  colorJade20,
  colorRed20,
} from '@signifyd/colors'
import { Space, i18nInstance } from '@signifyd/components'
import {
  CHARGEBACK_STAGE,
  CHARGEBACK_THIRD_PARTY_KIND,
  Claim,
  CLAIM_DISPOSITION,
  CLAIM_DISPOSITION_REASON,
  CLAIM_STATUS,
  InvChargeback,
  InvestigationInfo,
} from '@signifyd/http'
import { toTitleCase } from '@signifyd/utils'
import { ChargebackSettingsPayload } from 'core/queries/useChargebackSettings'
import ApprovedClaimContent from './ApprovedClaimContent'
import CaseClaimsBannerContent from './CaseClaimsBannerContent'
import ReimbursementPolicy from './ReimbursementPolicy'

interface Conditions {
  chargebackStage?: keyof typeof CHARGEBACK_STAGE | string
  disposition?: keyof typeof CLAIM_DISPOSITION
  dispositionReason?: keyof typeof CLAIM_DISPOSITION_REASON | null
  status?: keyof typeof CLAIM_STATUS
  chargebackThirdPartyKind?: CHARGEBACK_THIRD_PARTY_KIND | null
}

interface Data {
  color: string
  content: JSX.Element
  number?: number
  title: string
}

interface BannerConfiguration {
  conditions: Conditions
  data: Data
}

type ClaimDisplayDataType = {
  claim: Claim
  investigation: InvestigationInfo
  chargeback?: InvChargeback
  settings?: ChargebackSettingsPayload
}

export const getClaimDisplayData = ({
  claim,
  investigation,
  chargeback,
  settings,
}: ClaimDisplayDataType): Data => {
  const { t } = i18nInstance

  const defaultData: Data = {
    title: t('claimsBanner.pending.title', {
      id: claim.claimId,
    }),
    color: colorGlitter,
    content: (
      <CaseClaimsBannerContent hasFileUpload>
        <>
          {t('claimsBanner.pending.content')} <ReimbursementPolicy />
        </>
      </CaseClaimsBannerContent>
    ),
  }

  const bannerConfigurations: Array<BannerConfiguration> = [
    {
      conditions: {
        disposition: CLAIM_DISPOSITION.DECLINED,
        chargebackThirdPartyKind: CHARGEBACK_THIRD_PARTY_KIND.PAYPAL_CLAIM,
      },
      data: {
        title: t('claimsBanner.paypalClaim.title', {
          id: claim.claimId,
        }),
        color: colorRed20,
        content: (
          <CaseClaimsBannerContent
            subtitle={t('claimsBanner.paypalClaim.subtitle')}
          >
            <>
              <Trans i18nKey="claimsBanner.paypalClaim.content" />{' '}
              <ReimbursementPolicy />
            </>
          </CaseClaimsBannerContent>
        ),
      },
    },
    {
      conditions: {
        chargebackThirdPartyKind: CHARGEBACK_THIRD_PARTY_KIND.PAYPAL_DISPUTE,
      },
      data: {
        title: t('claimsBanner.paypalDispute.title', {
          id: claim.claimId,
        }),
        color: colorRed20,
        content: (
          <CaseClaimsBannerContent
            subtitle={t('claimsBanner.paypalDispute.subtitle')}
          >
            <>
              <Trans i18nKey="claimsBanner.paypalDispute.content" />{' '}
              <ReimbursementPolicy />
            </>
          </CaseClaimsBannerContent>
        ),
      },
    },
    {
      conditions: {
        status: CLAIM_STATUS.CANCELLED,
        dispositionReason: CLAIM_DISPOSITION_REASON.REVERSAL,
      },
      data: {
        title: t('claimsBanner.canceledReversal.title', {
          id: claim.claimId,
        }),
        color: colorRed20,
        content: (
          <CaseClaimsBannerContent
            subtitle={t('claimsBanner.canceledReversal.subtitle')}
          >
            <Trans i18nKey="claimsBanner.canceledReversal.content" />
          </CaseClaimsBannerContent>
        ),
      },
    },
    {
      conditions: {
        status: CLAIM_STATUS.CANCELLED,
        chargebackStage: CHARGEBACK_STAGE.RECALLED,
      },
      data: {
        title: t('claimsBanner.canceledRecalled.title', {
          id: claim.claimId,
        }),
        color: colorRed20,
        content: (
          <CaseClaimsBannerContent>
            <Trans i18nKey="claimsBanner.canceledRecalled.content" />
          </CaseClaimsBannerContent>
        ),
      },
    },
    {
      conditions: {
        status: CLAIM_STATUS.CANCELLED,
      },
      data: {
        title: t('claimsBanner.canceled.title', {
          id: claim.claimId,
        }),
        color: colorRed20,
        content: (
          <CaseClaimsBannerContent>
            <Trans i18nKey="claimsBanner.canceled.content" />
          </CaseClaimsBannerContent>
        ),
      },
    },
    { conditions: { status: CLAIM_STATUS.PENDING }, data: defaultData },
    {
      conditions: {
        status: CLAIM_STATUS.NEED_MORE_INFO,
        dispositionReason:
          CLAIM_DISPOSITION_REASON.UNABLE_TO_VERIFY_PROOF_OF_SHIPMENT,
      },
      data: {
        title: t('claimsBanner.unableToVerifyPOS.title', {
          id: claim.claimId,
        }),
        color: colorGold20,
        content: (
          <CaseClaimsBannerContent hasFulfillment>
            <>
              {t('claimsBanner.unableToVerifyPOS.content')}{' '}
              <ReimbursementPolicy />
              <Space size="sm" />
              <strong>{t('claimsBanner.unableToVerifyPOS.listTile')}</strong>
              <ul>
                <li>{t('claimsBanner.unableToVerifyPOS.trackingNumber')}</li>
                <li>{t('claimsBanner.unableToVerifyPOS.shipperOrCarrier')}</li>
                <li>{t('claimsBanner.unableToVerifyPOS.fulfillmentStatus')}</li>
                <li>{t('claimsBanner.unableToVerifyPOS.dateShipped')}</li>
              </ul>
            </>
          </CaseClaimsBannerContent>
        ),
      },
    },
    {
      conditions: { status: CLAIM_STATUS.NEED_MORE_INFO },
      data: {
        title: t('claimsBanner.needMoreInfo.title', {
          id: claim.claimId,
        }),
        color: colorGold20,
        content: (
          <CaseClaimsBannerContent hasFileUpload>
            <Trans i18nKey="claimsBanner.needMoreInfo.content" />
          </CaseClaimsBannerContent>
        ),
      },
    },
    {
      conditions: { disposition: CLAIM_DISPOSITION.APPROVED },
      data: {
        title: t(
          `claimsBanner.${
            settings?.isPostRepresentment ? 'postRepresentment' : 'approved'
          }.title`,
          {
            id: claim.claimId,
          }
        ),
        color: settings?.isPostRepresentment ? colorGlitter : colorJade20,
        content: (
          <ApprovedClaimContent
            settings={settings}
            teamId={investigation.teamId}
          />
        ),
      },
    },
    {
      conditions: {
        disposition: CLAIM_DISPOSITION.DECLINED,
        dispositionReason: CLAIM_DISPOSITION_REASON.INELIGIBLE_CHARGEBACK,
      },
      data: {
        title: t('claimsBanner.ineligibleChargeback.title', {
          id: claim.claimId,
        }),
        color: colorRed20,
        number: 8,
        content: (
          <CaseClaimsBannerContent
            subtitle={t('claimsBanner.ineligibleChargeback.subtitle')}
          >
            <>
              {t('claimsBanner.ineligibleChargeback.content', {
                reason: toTitleCase(
                  get(investigation, 'chargebacks[0].reasonProcessorDesc')
                ),
              })}{' '}
              <ReimbursementPolicy />
            </>
          </CaseClaimsBannerContent>
        ),
      },
    },
    {
      conditions: {
        disposition: CLAIM_DISPOSITION.DECLINED,
        dispositionReason: CLAIM_DISPOSITION_REASON.NO_FINANCIAL_LOSS,
      },
      data: {
        title: t('claimsBanner.noFinancialLoss.title', {
          id: claim.claimId,
        }),
        color: colorRed20,
        content: (
          <CaseClaimsBannerContent
            subtitle={t('claimsBanner.noFinancialLoss.subtitle')}
          >
            <Trans i18nKey="claimsBanner.noFinancialLoss.content" />
          </CaseClaimsBannerContent>
        ),
      },
    },
    {
      conditions: {
        disposition: CLAIM_DISPOSITION.DECLINED,
        dispositionReason: CLAIM_DISPOSITION_REASON.NOT_A_CHARGEBACK,
      },
      data: {
        title: t('claimsBanner.notAChargeback.title', {
          id: claim.claimId,
        }),
        color: colorRed20,
        content: (
          <CaseClaimsBannerContent
            subtitle={t('claimsBanner.notAChargeback.subtitle')}
          >
            <Trans i18nKey="claimsBanner.notAChargeback.content" />
          </CaseClaimsBannerContent>
        ),
      },
    },
    {
      conditions: {
        disposition: CLAIM_DISPOSITION.DECLINED,
        dispositionReason: CLAIM_DISPOSITION_REASON.PRODUCT_REROUTED,
      },
      data: {
        title: t('claimsBanner.productRerouted.title', {
          id: claim.claimId,
        }),
        color: colorRed20,
        content: (
          <CaseClaimsBannerContent
            subtitle={t('claimsBanner.productRerouted.subtitle')}
          >
            <>
              {t('claimsBanner.productRerouted.content')}{' '}
              <ReimbursementPolicy />
            </>
          </CaseClaimsBannerContent>
        ),
      },
    },
    {
      conditions: {
        disposition: CLAIM_DISPOSITION.DECLINED,
        dispositionReason: CLAIM_DISPOSITION_REASON.PRODUCT_RETURNED,
      },
      data: {
        title: t('claimsBanner.productReturned.title', {
          id: claim.claimId,
        }),
        color: colorRed20,
        content: (
          <CaseClaimsBannerContent
            subtitle={t('claimsBanner.productReturned.subtitle')}
          >
            <Trans i18nKey="claimsBanner.productReturned.content" />
          </CaseClaimsBannerContent>
        ),
      },
    },
    {
      conditions: {
        disposition: CLAIM_DISPOSITION.DECLINED,
        dispositionReason: CLAIM_DISPOSITION_REASON.ORDER_NOT_GUARANTEED,
      },
      data: {
        title: t('claimsBanner.orderNotGuaranteed.title', {
          id: claim.claimId,
        }),
        color: colorRed20,
        content: (
          <CaseClaimsBannerContent
            subtitle={t('claimsBanner.orderNotGuaranteed.subtitle')}
          >
            <>
              {t('claimsBanner.orderNotGuaranteed.content')}{' '}
              <ReimbursementPolicy />
            </>
          </CaseClaimsBannerContent>
        ),
      },
    },
    {
      conditions: {
        disposition: CLAIM_DISPOSITION.DECLINED,
        dispositionReason: CLAIM_DISPOSITION_REASON.MISSING_INFORMATION,
      },
      data: {
        title: t('claimsBanner.missingInformation.title', {
          id: claim.claimId,
        }),
        color: colorRed20,
        content: (
          <CaseClaimsBannerContent
            subtitle={t('claimsBanner.missingInformation.subtitle')}
          >
            <Trans i18nKey="claimsBanner.missingInformation.content" />
          </CaseClaimsBannerContent>
        ),
      },
    },
    {
      conditions: {
        disposition: CLAIM_DISPOSITION.DECLINED,
        dispositionReason:
          CLAIM_DISPOSITION_REASON.PAST_ELIGIBLE_SUBMISSION_DATE,
      },
      data: {
        title: t('claimsBanner.pastEligibleSubmissionDate.title', {
          id: claim.claimId,
        }),
        color: colorRed20,
        content: (
          <CaseClaimsBannerContent
            subtitle={t('claimsBanner.pastEligibleSubmissionDate.subtitle')}
          >
            <>
              {t('claimsBanner.pastEligibleSubmissionDate.content')}{' '}
              <ReimbursementPolicy />
            </>
          </CaseClaimsBannerContent>
        ),
      },
    },
    {
      conditions: {
        disposition: CLAIM_DISPOSITION.DECLINED,
        dispositionReason:
          CLAIM_DISPOSITION_REASON.INELIGIBLE_PRE_AUTH_GATEWAY_STATUS_CODE,
      },
      data: {
        title: t('claimsBanner.ineligiblePreAuthGatewayStatusCode.title', {
          id: claim.claimId,
        }),
        color: colorRed20,
        content: (
          <CaseClaimsBannerContent
            subtitle={t(
              'claimsBanner.ineligiblePreAuthGatewayStatusCode.subtitle'
            )}
          >
            <>
              <Trans i18nKey="claimsBanner.ineligiblePreAuthGatewayStatusCode.content" />{' '}
              <ReimbursementPolicy />
            </>
          </CaseClaimsBannerContent>
        ),
      },
    },
    {
      conditions: { disposition: CLAIM_DISPOSITION.DECLINED },
      data: {
        title: t('claimsBanner.claimDeclined.title', {
          id: claim.claimId,
        }),
        color: colorRed20,
        content: (
          <CaseClaimsBannerContent
            subtitle={t('claimsBanner.claimDeclined.subtitle')}
          >
            <Trans i18nKey="claimsBanner.claimDeclined.content" />
          </CaseClaimsBannerContent>
        ),
      },
    },
    {
      conditions: {
        status: CLAIM_STATUS.ON_HOLD,
        dispositionReason: CLAIM_DISPOSITION_REASON.PENDING_REPRESENTMENT,
      },
      data: {
        title: t('claimsBanner.pendingRepresentment.title', {
          id: claim.claimId,
        }),
        color: colorGlitter,
        content: (
          <CaseClaimsBannerContent
            subtitle={t('claimsBanner.pendingRepresentment.subtitle')}
          >
            <Trans i18nKey="claimsBanner.pendingRepresentment.content" />
          </CaseClaimsBannerContent>
        ),
      },
    },
    {
      conditions: {
        status: CLAIM_STATUS.ON_HOLD,
        dispositionReason: CLAIM_DISPOSITION_REASON.INVOICE_PAST_DUE,
      },
      data: {
        title: t('claimsBanner.invoicePasteDue.title', {
          id: claim.claimId,
        }),
        color: colorGold20,
        content: (
          <CaseClaimsBannerContent
            subtitle={t('claimsBanner.invoicePasteDue.subtitle')}
          >
            <Trans i18nKey="claimsBanner.invoicePasteDue.content" />
          </CaseClaimsBannerContent>
        ),
      },
    },
    {
      conditions: { status: CLAIM_STATUS.ON_HOLD },
      data: {
        title: t('claimsBanner.onHold.title', {
          id: claim.claimId,
        }),
        color: colorGold20,
        content: (
          <CaseClaimsBannerContent subtitle={t('claimsBanner.onHold.subtitle')}>
            <Trans i18nKey="claimsBanner.onHold.content" />
          </CaseClaimsBannerContent>
        ),
      },
    },
  ]

  const conditions: Conditions = {
    status: claim.status,
    disposition: claim.disposition,
    dispositionReason: claim.dispositionReason,
    chargebackStage: chargeback?.stage,
    chargebackThirdPartyKind: chargeback?.chargebackThirdPartyKind,
  }

  const matches = (source: Conditions, match: Conditions): boolean => {
    const propertiesToMatch = Object.keys(match)

    return propertiesToMatch.every(
      (property) => (source as any)[property] === (match as any)[property]
    )
  }

  return (
    bannerConfigurations.find((config) =>
      matches(conditions, config.conditions)
    )?.data ?? defaultData
  )
}

export default getClaimDisplayData
