import { get } from 'lodash'
import { FieldErrorsImpl } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { COUNTRY_ISO, NewCaseFormSchemaType } from '@signifyd/http'
import { ErrorOptions, FieldNameType } from './Fields.types'

export const NEW_CASE_DATE_FORMAT = 'YYYY-M-DT00:00:00-05:00'

export const CountryOptions = (
  Object.keys(COUNTRY_ISO) as Array<keyof typeof COUNTRY_ISO>
).map((iso) => ({ key: iso, label: iso, value: iso }))

export const useGetLabelText = (fieldName: FieldNameType): string => {
  const { t } = useTranslation()

  return t(`pages.newCase.fields.${fieldName}`) as string
}

export const useGetErrorText = (
  fieldName: FieldNameType,
  errors: Partial<FieldErrorsImpl<NewCaseFormSchemaType>>
): string | undefined => {
  const { t } = useTranslation()
  const labelText = useGetLabelText(fieldName)
  const error: ErrorOptions | undefined = get(errors, fieldName)?.message

  if (error === 'required') {
    return t('pages.newCase.errors.requiredText', { labelText })
  }

  if (error) {
    return t(`pages.newCase.errors.${error}`)
  }

  return undefined
}

export const useGetRequiredError = (
  fieldName: FieldNameType,
  errors: Partial<FieldErrorsImpl<NewCaseFormSchemaType>>
): string | undefined => {
  const { t } = useTranslation()
  const labelText = useGetLabelText(fieldName)
  const error: ErrorOptions | undefined = get(errors, fieldName)?.message

  return error && t('pages.newCase.errors.requiredSelect', { labelText })
}
