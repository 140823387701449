import { message } from 'antd'
import { computed, thunk } from 'easy-peasy'
import { i18nInstance } from '@signifyd/components'
import {
  INV_GUARANTEE_DISPOSITION,
  CLAIM_STATUS,
  SEARCH_FIELD,
} from '@signifyd/http'
import {
  getEndOfDay,
  getPastStartDate,
  getStartOfDay,
} from 'core/utils/date.utils'
import { encodeUrlHash } from 'core/utils/urlEncoder'
import { savedFilterKey } from 'store/constants'
import { defaultSearchState } from './search.store'
import { SavedFilterModel, SearchFilterState, QUICKVIEW_KEYS } from './types'

const savedFilterModel: SavedFilterModel = {
  // State
  quickviews: computed(() => ({
    [QUICKVIEW_KEYS.TODAYS_ORDERS]: {
      title: QUICKVIEW_KEYS.TODAYS_ORDERS,
      searchHash: encodeUrlHash<SearchFilterState>({
        ...defaultSearchState,
        filters: {
          ...defaultSearchState.filters,
          [SEARCH_FIELD.normalizedPurchaseCreatedAt]: {
            min: getStartOfDay(),
            max: getEndOfDay(),
          },
        },
      }),
    },
    [QUICKVIEW_KEYS.ALL_ORDERS_LAST_FOURTEEN_DAYS]: {
      title: QUICKVIEW_KEYS.ALL_ORDERS_LAST_FOURTEEN_DAYS,
      searchHash: encodeUrlHash<SearchFilterState>({
        ...defaultSearchState,
        filters: {
          ...defaultSearchState.filters,
          [SEARCH_FIELD.normalizedPurchaseCreatedAt]: {
            min: getPastStartDate({
              subtractInterval: 'weeks',
              subtractValue: 2,
            }),
            max: getEndOfDay(),
          },
        },
      }),
    },
    [QUICKVIEW_KEYS.DECLINED_LAST_7_DAYS]: {
      title: QUICKVIEW_KEYS.DECLINED_LAST_7_DAYS,
      searchHash: encodeUrlHash<SearchFilterState>({
        ...defaultSearchState,
        filters: {
          ...defaultSearchState.filters,
          [SEARCH_FIELD.normalizedPurchaseCreatedAt]: {
            min: getPastStartDate({
              subtractInterval: 'weeks',
              subtractValue: 1,
            }),
            max: getEndOfDay(),
          },
          [SEARCH_FIELD.guaranteeDisposition]: [
            INV_GUARANTEE_DISPOSITION.DECLINED,
          ],
        },
      }),
    },
    [QUICKVIEW_KEYS.CLAIMS_NEED_INFO]: {
      title: QUICKVIEW_KEYS.CLAIMS_NEED_INFO,
      searchHash: encodeUrlHash<SearchFilterState>({
        ...defaultSearchState,
        filters: {
          ...defaultSearchState.filters,
          [SEARCH_FIELD.normalizedPurchaseCreatedAt]: {
            min: getPastStartDate({
              subtractInterval: 'months',
              subtractValue: 6,
            }),
            max: getEndOfDay(),
          },
          [SEARCH_FIELD.claimStatus]: [CLAIM_STATUS.NEED_MORE_INFO],
        },
      }),
    },
  })),

  savedFilters: computed(
    [(_state, storeState) => storeState.user.currentUser],
    (currentUser) => {
      const savedFilters = currentUser?.uiState?.[savedFilterKey]

      return savedFilters || {}
    }
  ),

  selectedQuickView: undefined,
  selectedSavedFilter: undefined,

  getSavedFilterMatch: computed((state) => (key) => {
    const selectedQuickView = state.quickviews[key as QUICKVIEW_KEYS]
    const selectedSavedFilter = state.savedFilters[key]

    return {
      selectedSavedFilter,
      selectedQuickView: selectedSavedFilter ? undefined : selectedQuickView,
    }
  }),

  createSavedFilter: thunk(
    (_actions, { name, searchHash }, { getState, getStoreActions }) => {
      const userSavedFilters = getState().savedFilters

      return getStoreActions()
        .user.updateUserUIState({
          [savedFilterKey]: {
            ...userSavedFilters,
            [name]: { title: name, searchHash },
          },
        })
        .catch(() => {
          message.error(i18nInstance.t('store.savedFilter.createFailure'))
        })
    }
  ),

  deleteSavedFilter: thunk((_actions, name, { getState, getStoreActions }) => {
    const userSavedFilters = { ...getState().savedFilters }

    delete userSavedFilters[name]

    return getStoreActions()
      .user.updateUserUIState({ [savedFilterKey]: userSavedFilters })
      .catch(() => {
        message.error(i18nInstance.t('store.savedFilter.deleteFailure'))
      })
  }),
}

export default savedFilterModel
