import { FC } from 'react'
import { useStoreState } from 'store'
import { ExternalLink, getDecisionCenterUrl } from '@signifyd/components'

export interface DecisionCenterLinkProps {
  policyId: string | number
  policyName: string | null
  noPolicyText: string
}

const DecisionCenterLink: FC<DecisionCenterLinkProps> = ({
  policyName,
  policyId,
  noPolicyText,
}) => {
  const user = useStoreState((state) => state.user)

  const isRulesBuilderUser = user.currentUser?.features.RULES_BUILDER

  if (!policyName) {
    return <p>{noPolicyText}</p>
  }

  if (!isRulesBuilderUser) {
    return <p>{policyName}</p>
  }

  return (
    <ExternalLink
      target="_blank"
      onClick={(e) => e.stopPropagation()}
      url={`${getDecisionCenterUrl()}/policies/summary/${policyId}`}
    >
      {policyName}
    </ExternalLink>
  )
}

export default DecisionCenterLink
