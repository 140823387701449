import { Row } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, SelectField } from '../Fields'
import { ReactHookFormTypes } from './Card.types'
import FormCard from './FormCard'
import { CountryOptions } from '../Fields/utils'

export const BillingCard: FC<ReactHookFormTypes> = ({ control, errors }) => {
  const { t } = useTranslation()

  return (
    <FormCard title={t('pages.newCase.cards.billing')}>
      <Row>
        <TextField
          fieldName="card.cardHolderName"
          span={12}
          required
          control={control}
          errors={errors}
        />

        <TextField
          fieldName="userAccount.phone"
          span={12}
          control={control}
          errors={errors}
        />
      </Row>

      <Row>
        <TextField
          fieldName="card.billingAddress.streetAddress"
          span={20}
          required
          control={control}
          errors={errors}
        />

        <TextField
          fieldName="card.billingAddress.unit"
          span={4}
          control={control}
          errors={errors}
        />
      </Row>

      <Row>
        <TextField
          fieldName="card.billingAddress.city"
          span={10}
          required
          control={control}
          errors={errors}
        />

        <TextField
          fieldName="card.billingAddress.provinceCode"
          span={4}
          control={control}
          errors={errors}
        />

        <TextField
          fieldName="card.billingAddress.postalCode"
          span={4}
          control={control}
          errors={errors}
        />

        <SelectField
          fieldName="card.billingAddress.countryCode"
          span={6}
          required
          control={control}
          errors={errors}
          options={CountryOptions}
        />
      </Row>
    </FormCard>
  )
}

export default BillingCard
