import { CaretRightOutlined } from '@ant-design/icons'
import { Card, Col, Collapse, Descriptions, Divider, Row } from 'antd'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'store'
import { colorSnow } from '@signifyd/colors'
import {
  Space,
  Text,
  ReturnCheckpointMiniIcon,
  CheckpointActionTag,
} from '@signifyd/components'
import FormattedDate from 'core/components/FormattedDate'
import NotFoundPagePlaceholder from 'pages/CaseReviewPage/components/NotFoundPagePlaceholder'
import styles from './ReturnDetails.less'
import { formatPrice } from './ReturnDetails.utils'
import ReturnedItem from './ReturnedItem'

const { Panel } = Collapse

const { Item: DescriptionItem } = Descriptions

const customPanelStyle = {
  background: colorSnow,
  border: 0,
  borderRadius: '4px',
}

const ReturnDetails: FC = () => {
  const { t } = useTranslation()
  const caseDetails = useStoreState((state) => state.currentCase.details)

  const returns = caseDetails?.returns
  if (!returns?.length) {
    return (
      <NotFoundPagePlaceholder
        title={t('pages.caseReview.returnDetails.notFoundTitle')}
      />
    )
  }

  return (
    <div data-test-id="returnDetailsDisplay">
      <Card className={styles.returnCard}>
        {returns.map((returnData) => {
          const returnedItems = returnData.returnedItems ?? []

          const checkpointAction = returnData.decision?.checkpointAction

          const refundCurrency =
            returnData.refund?.currency ?? caseDetails?.currency

          return (
            <Fragment key={returnData.returnId}>
              <Collapse
                key={returnData.returnId}
                bordered={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
              >
                <Panel
                  header={
                    <Text weight="semibold">
                      {t(`pages.caseReview.returnDetails.panelHeader`, {
                        id: returnData.returnId,
                      })}
                    </Text>
                  }
                  key={returnData.returnId}
                  extra={
                    checkpointAction && (
                      <CheckpointActionTag
                        action={checkpointAction}
                        icon={ReturnCheckpointMiniIcon}
                      />
                    )
                  }
                  style={customPanelStyle}
                >
                  <Text>
                    {t(`pages.caseReview.returnDetails.returnDescription`, {
                      count: returnedItems.length,
                    })}
                  </Text>
                  <Space size="md" />
                  <Row gutter={32}>
                    <Col md={12}>
                      <Descriptions colon={false} size="small">
                        <DescriptionItem
                          label={t(
                            'pages.caseReview.returnDetails.descriptions.requestDate'
                          )}
                        >
                          <FormattedDate date={returnData.createdAt} />
                        </DescriptionItem>
                        {checkpointAction && (
                          <DescriptionItem
                            label={t(
                              'pages.caseReview.returnDetails.descriptions.returnAction'
                            )}
                          >
                            {t(`checkpointAction.${checkpointAction}`)}
                          </DescriptionItem>
                        )}
                      </Descriptions>
                    </Col>
                    <Col md={12}>
                      <Descriptions colon={false} size="small">
                        <DescriptionItem
                          label={t(
                            'pages.caseReview.returnDetails.descriptions.refundMethod'
                          )}
                        >
                          {returnData.refund?.method &&
                            t(`refundMethod.${returnData.refund.method}`)}
                        </DescriptionItem>
                        <DescriptionItem
                          label={t(
                            'pages.caseReview.returnDetails.descriptions.totalRefundAmount'
                          )}
                        >
                          {returnData.refund?.amount &&
                            formatPrice(
                              returnData.refund?.amount,
                              refundCurrency
                            )}
                        </DescriptionItem>
                      </Descriptions>
                    </Col>
                  </Row>
                  {returnedItems.map((returnedItem, i) => (
                    <Fragment key={i}>
                      <Divider className={styles.returnProductDivider} />
                      <ReturnedItem
                        returnedItem={returnedItem}
                        refundCurrency={refundCurrency}
                      />
                    </Fragment>
                  ))}
                </Panel>
              </Collapse>
              <Space size="md" />
            </Fragment>
          )
        })}
      </Card>
      <Space size="md" />
    </div>
  )
}

export default ReturnDetails
