import { Tooltip } from 'antd'
import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { BA, BA_DA, BA_IP, BA_DA_IP, DA, DA_IP, IP, FO } from './MapMarkerSVGs'
import { COMBINED_MARKER_KEYS } from './types'

interface Props {
  lat: number
  lng: number
  type: COMBINED_MARKER_KEYS
}

const MapMarker: FC<Props> = ({ type }) => {
  const { t } = useTranslation()

  const MARKER_BY_TYPE = {
    BA: { type: BA, tooltip: t('googleMaps.markerType.billingAddress') },
    DA: { type: DA, tooltip: t('googleMaps.markerType.deliveryAddress') },
    IP: { type: IP, tooltip: t('googleMaps.markerType.IPGeolocation') },
    FO: { type: FO, tooltip: t('googleMaps.markerType.flightOrigin') },
    BA_IP: { type: BA_IP, tooltip: t('googleMaps.markerType.billingAndIPGeo') },
    BA_DA: {
      type: BA_DA,
      tooltip: t('googleMaps.markerType.billingAndDelivery'),
    },
    DA_IP: {
      type: DA_IP,
      tooltip: t('googleMaps.markerType.IPGeoAndDelivery'),
    },
    BA_DA_IP: {
      type: BA_DA_IP,
      tooltip: t('googleMaps.markerType.billingDeliveryAndIPGeo'),
    },
  }

  // Signifyd Map Markers are 33x48 so we need to position
  // them to the bottom center/"pinpoint" of the image
  return (
    <div style={{ position: 'absolute', top: '-48px', left: '-17px' }}>
      <Tooltip title={MARKER_BY_TYPE[type].tooltip}>
        {MARKER_BY_TYPE[type].type}
      </Tooltip>
    </div>
  )
}
export default memo(MapMarker)
