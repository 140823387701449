import { CaretRightOutlined } from '@ant-design/icons'
import { Card, Collapse, Skeleton, Tabs } from 'antd'
import moment from 'moment-timezone'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useStoreActions } from 'store'
import { Fetch, Text } from '@signifyd/components'
import { DATE_FORMAT } from 'core/constants'
import { getMinMaxDate } from 'core/utils/date.utils'
import { getSelectedQuickDateFilter } from 'core/utils/quickDateFilter/quickDateFilter'
import { FilterState } from 'store/search'
import useSearchAggregates from '../../queries/useSearchAggregates'
import { getClaimsSubTotals, getOrderSubTotals } from './aggregate.data'
import AggregateContainer from './AggregateContainer'
import styles from './AggregatePanel.less'
import { dateOptions } from '../HeadlineDatePicker/HeadlineDatePicker'

const { TabPane } = Tabs
const { Panel } = Collapse

interface Props {
  filters: FilterState
  updateFilters: (filters: FilterState) => void
  activeKey: string | Array<string>
  setActiveKey: (activeKey: string | Array<string>) => void
  teamId: Array<number>
}

export const aggregatesPanelKey = 'aggregates'

const AggregatePanel: FC<Props> = ({
  filters,
  updateFilters,
  activeKey,
  setActiveKey,
  teamId,
}) => {
  const { t } = useTranslation()
  const { normalizedPurchaseCreatedAt: filterDates } = filters
  const { searchCases } = useStoreActions((actions) => actions.search)
  const navigate = useNavigate()

  const { minDate, maxDate } = getMinMaxDate(filterDates)

  const difference = dateOptions.find((date) =>
    getSelectedQuickDateFilter(filterDates, date)
  )

  const dateLocaleConfig = difference
    ? { count: difference }
    : {
        range: `${moment(minDate).format(DATE_FORMAT.date)} - ${moment(
          maxDate
        ).format(DATE_FORMAT.date)}`,
        interpolation: { escapeValue: false },
      }

  return (
    <Card size="small">
      <Collapse
        bordered={false}
        className={styles.collapse}
        activeKey={activeKey}
        onChange={setActiveKey}
        accordion={false}
        destroyInactivePanel={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            className={styles.expandIcon}
            data-test-id="toggleShowHideAggregates"
            data-analytics-id={`${
              isActive ? 'expand' : 'close'
            }-aggregates-panel`}
            rotate={isActive ? 90 : 0}
          />
        )}
      >
        <Panel
          style={{
            border: 0,
          }}
          key={aggregatesPanelKey}
          header={
            <Text weight="semibold" data-test-id="aggregatesSummary">
              {t('aggregates.summary', dateLocaleConfig)}
            </Text>
          }
          className={styles.aggregatePanel}
        >
          <Tabs>
            <TabPane
              tab={t('aggregates.title.orders')}
              key="1"
              data-analytics-id="aggregate-panel-orders"
            >
              <Fetch
                useFetchFunction={useSearchAggregates}
                fetchFunctionArgs={[filterDates, teamId]}
                renderComponent={(data) => {
                  const ordersSubTotals = getOrderSubTotals(
                    data,
                    filters,
                    updateFilters,
                    searchCases,
                    navigate
                  )

                  return <AggregateContainer subTotals={ordersSubTotals} />
                }}
                LoadingComponent={<Skeleton />}
              />
            </TabPane>
            <TabPane
              tab={t('aggregates.title.claims')}
              key="2"
              data-analytics-id="aggregate-panel-claims"
            >
              <Fetch
                useFetchFunction={useSearchAggregates}
                fetchFunctionArgs={[filterDates, teamId]}
                renderComponent={(data) => {
                  const claimsSubTotals = getClaimsSubTotals(
                    data,
                    filters,
                    updateFilters,
                    searchCases,
                    navigate
                  )

                  return <AggregateContainer subTotals={claimsSubTotals} />
                }}
                LoadingComponent={<Skeleton />}
              />
            </TabPane>
          </Tabs>
        </Panel>
      </Collapse>
    </Card>
  )
}

export default AggregatePanel
