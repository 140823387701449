import moment from 'moment-timezone'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'store'
import { GuaranteeIcon, ShieldCheck, useIsThirdGen } from '@signifyd/components'
import { InvestigationInfo } from '@signifyd/http'
import useSubmitGuarantee, {
  canSubmitGuarantee,
} from 'core/queries/useSubmitGuarantee'
import styles from './GuaranteeButton.less'
import ActionButton from '../ActionButton'
import ActionIconButton from '../ActionIconButton/ActionIconButton'

interface Props {
  caseDetails: InvestigationInfo
}

export const GuaranteeButton: FC<Props> = ({ caseDetails }) => {
  const { t } = useTranslation()

  const { isLoading, mutate: submitGuarantee } = useSubmitGuarantee()

  const { investigationId, guaranteeSubmittedAt } = caseDetails

  const isUserAdmin = useStoreState((state) => state.user.currentUser?.isAdmin)

  const isThirdGen = useIsThirdGen()

  const submittedOn = guaranteeSubmittedAt
    ? `${t('caseActions.submittedOn')} ${moment(guaranteeSubmittedAt).format(
        t('caseActions.dateTimeFormat')
      )}`
    : undefined

  const canSubmitForGuarantee = canSubmitGuarantee(
    caseDetails,
    Boolean(isUserAdmin)
  )

  return isThirdGen && !canSubmitForGuarantee.hidden ? (
    <ActionIconButton
      onClick={() => submitGuarantee({ investigationId })}
      actionState={canSubmitGuarantee(caseDetails, Boolean(isUserAdmin))}
      isLoading={isLoading}
      Icon={ShieldCheck}
      className={styles.submitGuaranteeIcon}
      tooltip={t('caseActions.submitGuarantee.tooltip')}
      subText={submittedOn}
      testId="guaranteeButton"
    />
  ) : (
    <ActionButton
      onClick={() => submitGuarantee({ investigationId })}
      actionState={canSubmitGuarantee(caseDetails, Boolean(isUserAdmin))}
      isLoading={isLoading}
      icon={<GuaranteeIcon />}
      className={styles.submitGuaranteeIcon}
      text={t('caseActions.submitGuarantee.text')}
      tooltip={t('caseActions.submitGuarantee.tooltip')}
      subText={submittedOn}
      testId="guaranteeButton"
    />
  )
}

export default GuaranteeButton
