import { Button, Row } from 'antd'
import GoogleMapReact from 'google-map-react'
import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { colorYonder } from '@signifyd/colors'
import { PaymentAndDepatureIcon } from '@signifyd/components'
import { CaseEntryLocationDetails, CaseEntriesItem } from '@signifyd/http'
import NoInformation from 'core/components/NoInformation'
import { throttle } from 'lodash/fp'
import styles from './GoogleMap.less'
import MapMarker from './MapMarker'
import { mapPhysicalLocationsToMarkers } from './MapUtils'
import { LocationToMarker, GMapMap, GMapMaps, Location } from './types'

type Props = {
  physicalLocations: Array<CaseEntriesItem<CaseEntryLocationDetails>>
  showResetButton?: boolean
}

const GoogleMap: FC<Props> = ({ physicalLocations, showResetButton }) => {
  const [map, setMap] = useState<GMapMap>()
  const [bounds, setBounds] = useState<GMapMaps['LatLngBounds']>()
  const [resetButtonDisabled, setResetButtonDisabled] = useState<boolean>(true)
  const [initMapInfo, setInitMapInfo] = useState<{
    zoom: number
    lat: number
    lng: number
  }>()

  const { t } = useTranslation()
  const locations = physicalLocations as Array<Location>

  // process physicalLocations to map marker objects
  const mapMarkerCoordinates = useMemo(
    () => mapPhysicalLocationsToMarkers(locations),
    [locations]
  )

  // Google Map Methods
  // Return map bounds based on list of places
  const getMapBounds = (
    _map: GMapMap,
    maps: GMapMaps,
    places: Array<LocationToMarker>
  ): any => {
    const bounds = new maps.LatLngBounds()

    places.forEach((place) => {
      bounds.extend(new maps.LatLng(place.lat, place.lng))
    })

    return bounds
  }

  // Re-center map when resizing the window
  const bindResizeListener = (
    map: GMapMap,
    maps: GMapMaps,
    bounds: any,
    places: Array<LocationToMarker>
  ): void => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(
        window,
        'resize',
        throttle(250, () => {
          // Fit markers in map view - Only run if more than one marker to prevent being super zoomed in
          if (places.length > 1) {
            map.fitBounds(bounds)
          }
        })
      )
    })
  }

  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = (
    map: GMapMap,
    maps: GMapMaps,
    places: Array<LocationToMarker>
  ): void => {
    const bounds = getMapBounds(map, maps, places)

    setMap(map)
    setBounds(bounds)

    // Fit markers in map view - Only run if more than one marker to prevent being super zoomed in
    if (places.length > 1) {
      map.fitBounds(bounds)
    }

    setInitMapInfo({
      zoom: map.zoom,
      lat: map.center.lat(),
      lng: map.center.lng(),
    })

    // Bind the resize listener
    bindResizeListener(map, maps, bounds, places)
  }

  const { lat, lng } = mapMarkerCoordinates[0] || { lat: 0, lng: 0 }

  const fitBounds = useCallback(() => {
    setResetButtonDisabled(true)

    if (!map) {
      return
    }

    if (mapMarkerCoordinates.length === 1) {
      map.panTo({ lat, lng })

      return
    }

    if (mapMarkerCoordinates.length > 1) {
      map.fitBounds(bounds)
    }
  }, [mapMarkerCoordinates.length, map, lat, lng, bounds])

  return (
    <>
      {mapMarkerCoordinates.length > 0 ? (
        <div
          style={{
            height: '100%',
            width: '100%',
          }}
          data-analytics-id="google-map-container"
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyCYr8elU2guM28G2e3D0mYogeCNQFwmBck',
            }}
            defaultCenter={{ lat, lng }}
            defaultZoom={10}
            yesIWantToUseGoogleMapApiInternals
            onChange={({ zoom, center }) => {
              if (!resetButtonDisabled || !initMapInfo) {
                return
              }

              const mapIsDirty =
                zoom !== initMapInfo?.zoom ||
                center.lat.toFixed(8) !== initMapInfo.lat.toFixed(8) ||
                center.lng.toFixed(8) !== initMapInfo.lng.toFixed(8)

              if (mapIsDirty) {
                setResetButtonDisabled(false)
              }
            }}
            onGoogleApiLoaded={({
              map,
              maps,
            }: {
              map: GMapMap
              maps: GMapMaps
            }) => apiIsLoaded(map, maps, mapMarkerCoordinates)}
          >
            {mapMarkerCoordinates.map(
              (mark: LocationToMarker, index: number) => (
                <MapMarker
                  key={index}
                  lat={mark.lat}
                  lng={mark.lng}
                  type={mark.type}
                />
              )
            )}
          </GoogleMapReact>
        </div>
      ) : (
        <NoInformation />
      )}
      {showResetButton && (
        <Row className={styles.resetButton}>
          <Button
            data-test-id="googleMapResetBtn"
            data-analytics-id="google-map-reset-button"
            className={styles.googleMapResetBtn}
            onClick={fitBounds}
            disabled={resetButtonDisabled}
          >
            <PaymentAndDepatureIcon
              fill={resetButtonDisabled ? colorYonder : undefined}
            />
            {t('caseSummary.order.paymentAndDepature')}
          </Button>
        </Row>
      )}
    </>
  )
}

export default GoogleMap
