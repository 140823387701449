import {
  ArrowLeftOutlined,
  DownOutlined,
  SearchOutlined,
  UpOutlined,
} from '@ant-design/icons'
import { Button, Divider } from 'antd'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useStoreActions, useStoreState } from 'store'
import { Text } from '@signifyd/components'
import { joinClassNames } from '@signifyd/utils'
import SearchAndFilter from 'core/components/SearchAndFilter'
import { useSearchFilters } from 'core/hooks'
import getPlaceholderText from 'core/utils/getPlaceholderText'
import isEventTicketing from 'core/utils/isEventTicketing'
import { isTransportAirline } from 'core/utils/isTransportAirline/isTransportAirline'
import styles from './CaseHeaderNavigation.less'

const resetSearchFilters = true

export const CaseHeaderNavigation: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { filters, updateFilters, searchTerm, updateSearchTerm } =
    useSearchFilters({ resetFiltersOnMount: resetSearchFilters })

  // Store State
  const { searchCasesWithSavedFilters } = useStoreActions(
    (actions) => actions.search
  )
  const searchResults = useStoreState((state) => state.search.results)
  const caseDetails = useStoreState((state) => state.currentCase.details)
  const [showSearchComponent, setShowSearchComponent] = useState(false)

  const isAirlineOrder = caseDetails && isTransportAirline(caseDetails.products)
  const isEventTicketingOrder =
    caseDetails && isEventTicketing(caseDetails.products)

  // Component State
  const hasResults = !!searchResults.length
  const currentIndex = useMemo(() => {
    return searchResults.findIndex(
      ({ investigationId }) => investigationId === caseDetails?.investigationId
    )
  }, [caseDetails, searchResults])
  const isFirstCase = currentIndex === 0
  const isLastCase = currentIndex === searchResults.length - 1

  const placeholderText = getPlaceholderText(
    isAirlineOrder,
    isEventTicketingOrder
  )

  // Handlers
  const onHandleNextPrevCase = (index: number): void => {
    const { investigationId } = searchResults[index]

    navigate(`/orders/${investigationId}`)
  }

  const onHandleBack = (): void => {
    if (hasResults) {
      searchCasesWithSavedFilters({ navigate })
    } else {
      navigate('/orders/search')
    }
  }

  const handleClose = (): void => {
    setShowSearchComponent(false)
  }

  return (
    <>
      {!showSearchComponent && (
        <nav className={styles.navigation}>
          <Button
            type="link"
            onClick={onHandleBack}
            data-test-id="back-to-search"
            className={styles.backToSearchButton}
          >
            <Text className={styles.backText} size="md">
              <ArrowLeftOutlined className={styles.backIcon} />
              {t(
                `pages.caseReview.header.${
                  hasResults ? 'searchResults' : 'search'
                }`
              )}
            </Text>
          </Button>
          <Divider
            className={styles.divider}
            type="vertical"
            orientation="center"
          />
          <div className={styles.searchNavigation}>
            <Button
              type="link"
              onClick={() => setShowSearchComponent(!showSearchComponent)}
              data-test-id="toggle-search-component"
              className={styles.searchFilterLink}
            >
              <Text className={styles.backText} size="md">
                <SearchOutlined className={styles.searchIcon} />
                {t(`search.searchBar.${placeholderText}`)}
              </Text>
            </Button>
          </div>
          <>
            {hasResults && currentIndex >= 0 && (
              <div data-test-id="pagination" className={styles.pagination}>
                <Text size="md" className={styles.results}>
                  {t('pages.caseReview.header.count', {
                    current: currentIndex + 1,
                    total: searchResults.length,
                  })}
                </Text>
                <UpOutlined
                  data-test-id="paginationUpArrow"
                  className={joinClassNames([
                    styles.button,
                    isFirstCase && styles.disabled,
                  ])}
                  onClick={() =>
                    !isFirstCase && onHandleNextPrevCase(currentIndex - 1)
                  }
                />
                <DownOutlined
                  data-test-id="paginationDownArrow"
                  className={joinClassNames([
                    styles.button,
                    isLastCase && styles.disabled,
                  ])}
                  onClick={() =>
                    !isLastCase && onHandleNextPrevCase(currentIndex + 1)
                  }
                />
              </div>
            )}
          </>
        </nav>
      )}
      {showSearchComponent && (
        <div className={styles.searchFilterNavigation}>
          <SearchAndFilter
            defaultOpen
            handleClose={handleClose}
            filters={filters}
            updateFilters={updateFilters}
            searchTerm={searchTerm}
            updateSearchTerm={updateSearchTerm}
            hasAirlineOrders={!!isAirlineOrder}
          />
        </div>
      )}
    </>
  )
}

export default CaseHeaderNavigation
