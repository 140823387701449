import { Descriptions } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { InvestigationInfo, PREDICTION_LIST_TYPE } from '@signifyd/http'
import CopyWrapper from 'core/components/CopyWrapper'
import NoInformation from 'core/components/NoInformation'
import OrderAttributeLists from 'core/components/OrderAttributeLists'
import { defaultDescriptionProps } from 'core/constants'
import SeederLabel from 'pages/CaseReviewPage/components/SeederLabel'
import { DescriptionsItemProps } from 'antd/es/descriptions/Item'
import { CaseEntriesResolvedState } from 'store/currentCase/types/case.entries.types'
import DeepLinkDropdown from '../components/DeepLinkDropdown'
import {
  MENU_SECTION,
  MENU_SECTION_TYPE,
} from '../components/DeepLinkDropdown/DeepLinkDropdown.types'

export interface EmailAccountsProps {
  caseEntries: CaseEntriesResolvedState
  caseDetails: InvestigationInfo
}

const EmailAccounts: FC<EmailAccountsProps> = ({
  caseEntries,
  caseDetails,
}) => {
  const { t } = useTranslation()

  const { confirmationEmail, accountEmail } = caseEntries

  const { recipients, userAccountEmail } = caseDetails

  const rawConfirmationEmail = recipients?.[0]?.confirmationEmail

  const noInfo = !confirmationEmail && !accountEmail
  const sectionName = t('pages.caseReview.details.emailAccounts')

  const items = [
    confirmationEmail?.entityName && {
      label: (
        <SeederLabel label={t('pages.caseReview.details.confirmationEmail')} />
      ),
      children: (
        <>
          <CopyWrapper text={confirmationEmail.entityName}>
            <DeepLinkDropdown
              section={MENU_SECTION.accounts}
              type={MENU_SECTION_TYPE.confirmationEmail}
            >
              {confirmationEmail.entityName}
            </DeepLinkDropdown>
          </CopyWrapper>
          {rawConfirmationEmail && (
            <OrderAttributeLists
              type={PREDICTION_LIST_TYPE.EMAIL}
              value={rawConfirmationEmail}
              analyticsId="confirmation-email"
            />
          )}
        </>
      ),
    },
    accountEmail?.entityName && {
      label: <SeederLabel label={t('pages.caseReview.details.accountEmail')} />,
      children: (
        <>
          <CopyWrapper text={accountEmail.entityName}>
            <DeepLinkDropdown
              section={MENU_SECTION.accounts}
              type={MENU_SECTION_TYPE.accountEmail}
            >
              {accountEmail.entityName}
            </DeepLinkDropdown>
          </CopyWrapper>
          <OrderAttributeLists
            type={PREDICTION_LIST_TYPE.EMAIL}
            value={userAccountEmail}
            analyticsId="account-email"
          />
        </>
      ),
    },
    noInfo && {
      label: '',
      labelStyle: { display: 'none' },
      children: <NoInformation />,
    },
  ].filter((item) => !!item) as Array<DescriptionsItemProps>

  return (
    <Descriptions
      title={sectionName}
      items={items}
      {...defaultDescriptionProps}
    />
  )
}

export default EmailAccounts
