import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Spin } from 'antd'
import { FC, useState, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreActions } from 'store'
import { useClickOutside, Text, useFileAttacher } from '@signifyd/components'
import { joinClassNames } from '@signifyd/utils'
import { maxNoteLength } from 'core/constants'
import { UploadFile } from 'antd/lib/upload/interface'
import styles from './ExpandableNoteInput.less'
import NoteInput from './NoteInput'
import NoteModal from './NoteModal'

interface Props {
  caseId: number
}

export const ExpandableNoteInput: FC<Props> = ({ caseId }) => {
  const { t } = useTranslation()
  // Local State
  const [note, setNote] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  const noteTooLong = note.length > maxNoteLength
  const isSubmitDisabled = !note.trim() || noteTooLong

  const fileAttacher = useFileAttacher()
  const { files, clearFiles } = fileAttacher

  const hasPendingFile = files?.some(
    ({ status }: UploadFile) => status === 'uploading'
  )

  // Store State / Actions
  const { addNoteWithFiles } = useStoreActions((actions) => actions.currentCase)

  // Handlers
  const expandNoteInput = useCallback(() => setExpanded(true), [setExpanded])
  const collapseNoteInput = useCallback(() => {
    if (!showModal) {
      setExpanded(false)
    }
  }, [setExpanded, showModal])

  const onSubmitNote = (): void => {
    if (!note) {
      return
    }

    setIsProcessing(true)
    setShowModal(false)

    addNoteWithFiles({ caseId, text: note, files }).finally(() => {
      clearFiles()
      setIsProcessing(false)
      setNote('')
      setExpanded(false)
    })
  }

  // Stuff to track external clicks
  const inputRef = useRef(null)
  const modalRef = useRef(null)

  useClickOutside([inputRef, modalRef], collapseNoteInput)

  return (
    <Spin spinning={isProcessing} delay={250}>
      <NoteInput
        className={!expanded && styles.hidden}
        ref={inputRef}
        value={note}
        isSubmitDisabled={isSubmitDisabled}
        onChange={setNote}
        onSubmitNote={onSubmitNote}
        maxNoteLength={maxNoteLength}
        onShowModal={() => setShowModal(true)}
        fileAttacher={fileAttacher}
      />
      <button
        className={joinClassNames([
          styles.expandTextBtn,
          expanded && styles.hidden,
        ])}
        type="button"
        onClick={expandNoteInput}
        data-test-id="addNotesButton"
      >
        <Text
          type="secondary"
          data-analytics-id="add-notes-container"
          data-test-id="addNotesContainer"
          ellipsis
          className={styles.buttonText}
        >
          {note.trimStart() || t('caseActivity.addNotesPlaceholder')}
        </Text>
        <Text>
          <LegacyIcon
            className={styles.icon}
            type={hasPendingFile ? 'loading' : 'paper-clip'}
          />
        </Text>
      </button>

      <NoteModal
        ref={modalRef}
        maxLength={maxNoteLength}
        visible={showModal}
        note={note}
        onChange={setNote}
        isSubmitDisabled={isSubmitDisabled}
        onSubmit={onSubmitNote}
        onCancel={() => setShowModal(false)}
        attachedFilesData={fileAttacher}
      />
    </Spin>
  )
}

export default ExpandableNoteInput
