import { ExclamationCircleFilled, InfoCircleFilled } from '@ant-design/icons'
import { Row } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TextThirdGen } from '@signifyd/components'
import { ANALYSIS_STATE } from 'store/currentCase/types/case.analysis.types'
import styles from './WarningPanel.less'

interface Props {
  analysisState: ANALYSIS_STATE
}

export const WarningPanel: FC<Props> = ({ analysisState }) => {
  const { t } = useTranslation()

  const getWarningPanelText = (): string => {
    if (analysisState === ANALYSIS_STATE.PROCESSING) {
      return t('caseIntelligence.warning.processing')
    }

    if (analysisState === ANALYSIS_STATE.POLICY_ONLY) {
      return t('caseIntelligence.warning.policyOnly')
    }

    return t('caseIntelligence.warning.error')
  }

  const Icon =
    analysisState === ANALYSIS_STATE.POLICY_ONLY ? (
      <InfoCircleFilled className={styles.warningIcon} />
    ) : (
      <ExclamationCircleFilled className={styles.warningIcon} />
    )

  return (
    <>
      <Row justify="center">{Icon}</Row>
      <Row justify="center">
        <TextThirdGen className={styles.warningText}>
          {getWarningPanelText()}
        </TextThirdGen>
      </Row>
    </>
  )
}

export default WarningPanel
