import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { message } from 'antd'
import { AxiosError } from 'axios'
import { i18nInstance } from '@signifyd/components'
import { getCaseEntries } from '@signifyd/http'
import { CaseEntriesResolvedState } from 'store/currentCase/types/case.entries.types'
import {
  findRoleInSection,
  findRolesInSection,
} from 'store/currentCase/utils/case.entries.utils'

export const GET_CASE_ENTRIES_QUERY_KEY = 'GET_CASE_ENTRIES'

export const useCaseEntries = (
  investigationId?: number
): UseQueryResult<CaseEntriesResolvedState, AxiosError> => {
  return useQuery({
    queryKey: [GET_CASE_ENTRIES_QUERY_KEY, investigationId],
    queryFn: async () => {
      const { data } = await getCaseEntries(investigationId!)
      const {
        people,
        accounts,
        phones,
        networkLocations,
        physicalLocations,
        organizations,
      } = data

      return {
        ...data,
        cardHolder: findRoleInSection(people, 'cardHolder'),
        confirmationEmail: findRoleInSection(accounts, 'confirmationEmail'),
        accountEmail: findRoleInSection(accounts, 'accountEmail'),
        confirmationPhone: findRoleInSection(phones, 'confirmationPhone'),
        purchaseIP: findRoleInSection(networkLocations, 'purchaseIP'),
        ipDomain: findRoleInSection(networkLocations, 'ipDomain'),
        emailDomains: findRolesInSection(networkLocations, 'emailDomain'),
        accountHolderPhone: findRoleInSection(phones, 'accountPhone'),
        cardHolderAddress: findRoleInSection(
          physicalLocations,
          'billingAddress'
        ),
        deliveryRecipientAddress: findRoleInSection(
          physicalLocations,
          'deliveryAddress'
        ),
        deliveryRecipient: findRoleInSection(people, 'deliveryRecipient'),
        creditCardIssuer: findRoleInSection(organizations, 'creditCardIssuer'),
        asnCarrier: findRoleInSection(organizations, 'asnCarrier'),
        ipRegistree: findRoleInSection(organizations, 'ipRegistree'),
      }
    },
    onError: (error) => {
      if (error.response?.status !== 404) {
        message.error(i18nInstance.t('store.case.getCaseEntriesFailure'))
      }
    },
    enabled: !!investigationId,
  })
}
