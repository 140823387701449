import { zodResolver } from '@hookform/resolvers/zod'
import { Modal, Alert } from 'antd'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import { ExternalLink, Space, useIsThirdGen } from '@signifyd/components'
import { CreateClaimError } from '@signifyd/http'
import { COMMON_MODAL_PROPS } from 'core/constants'
import {
  ClaimReimbursementRequiredFields,
  ReimbursementUpdate,
} from 'core/hooks/caseActions/useClaimReimbursement/types'
import ClaimReimbursementForm from './ClaimReimbursementForm'
import {
  ClaimReimbursementFormSchemaType,
  ClaimReimbursementFormDefaultValues,
  ClaimReimbursementFormValidateSchema,
} from './ClaimReimbursementFormValidation'
import styles from './ClaimReimbursementModal.less'

interface Props {
  apiErrors: CreateClaimError | null
  caseDetails: ClaimReimbursementRequiredFields
  closeModal: () => void
  isLoading: boolean
  performAction: (
    caseDetails: ClaimReimbursementRequiredFields,
    payload: ReimbursementUpdate
  ) => void
  visible: boolean
  setModalVisible: (visible: boolean) => void
}

const ReimbursementModal: FC<Props> = ({
  apiErrors,
  caseDetails,
  closeModal,
  isLoading,
  performAction,
  visible,
  setModalVisible,
}) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<ClaimReimbursementFormSchemaType>({
    mode: 'onChange',
    resolver: zodResolver(
      ClaimReimbursementFormValidateSchema,
      {},
      { rawValues: true }
    ),
    values: ClaimReimbursementFormDefaultValues,
  })

  const resetModal = (): void => {
    reset()
    closeModal()
  }

  const onSubmit: SubmitHandler<ClaimReimbursementFormSchemaType> = (data) => {
    performAction(caseDetails, data)
    setModalVisible(false)
  }

  return (
    <Modal
      {...COMMON_MODAL_PROPS}
      title={t('caseActions.reimbursement.modalTitle')}
      okText={t('caseActions.reimbursement.okText')}
      open={visible}
      confirmLoading={isLoading}
      okType={isThirdGen ? 'primary' : 'link'}
      onOk={handleSubmit(onSubmit)}
      okButtonProps={{ disabled: !!Object.keys(errors).length || isSubmitting }}
      onCancel={resetModal}
      styles={{
        body: { paddingBottom: '0px' },
      }}
    >
      <Alert
        message={
          <span className={styles.infoBox}>
            <Trans
              i18nKey="caseActions.reimbursement.helpText"
              components={{
                helpArticleLink: (
                  <ExternalLink
                    target="_blank"
                    url="https://www.signifyd.com/resources/manual/signifyd-guarantee/reimbursement/"
                  >
                    here.
                  </ExternalLink>
                ),
              }}
            />
          </span>
        }
        type="info"
        showIcon
      />
      <Space size={24} />
      <ClaimReimbursementForm
        investigationId={caseDetails.investigationId}
        control={control}
        errors={errors}
        apiErrors={apiErrors}
        data-analytics-id="request-reimbursement"
      />
    </Modal>
  )
}

export default ReimbursementModal
